import React from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSection from "../Shared/CollapsibleSection";
import CollapsibleSectionTable from "../Shared/CollapsibleSectionTable";

interface Props {
  user: User;
}

const UserReferralInfo = ({ user }: Props) => {
  const referralData = React.useMemo(() => {
    return user.referrals.map((referral) => {
      return {
        // null check since the referrer might have removed name.
        referralName: referral.referred ? referral.referred.name : "No Name",
        referralId: referral.id,
        referralStatus: referral.status,
      };
    });
  }, [user.referrals]);

  const referralColumns = React.useMemo(
    () => [
      {
        Header: "Refferal Name",
        accessor: "referralName",
      },
      {
        Header: "Referral ID",
        accessor: "referralId",
      },
      {
        Header: "Referral Status",
        accessor: "referralStatus",
      },
    ],
    []
  );

  const refereeData = React.useMemo(() => {
    return user.reference
      ? [
          {
            refereeName: user.reference.referrer?.name,
            refereeId: user.reference.referrer?.id,
          },
        ]
      : [];
  }, [user.reference]);

  const refereeColumns = React.useMemo(
    () => [
      {
        Header: "Referee Name",
        accessor: "refereeName",
      },
      {
        Header: "Referee ID",
        accessor: "refereeId",
      },
    ],
    []
  );

  return (
    <CollapsibleSection title="Worker Referral Info">
      {user.referrals && user.referrals.length > 0 ? (
        <CollapsibleSectionTable
          data={referralData}
          columns={referralColumns}
        />
      ) : (
        <p>User has no referrals</p>
      )}
      {user.reference ? (
        <CollapsibleSectionTable data={refereeData} columns={refereeColumns} />
      ) : (
        <p>User was not referred by anyone.</p>
      )}
    </CollapsibleSection>
  );
};

export default UserReferralInfo;
