import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

export const config = {
  apiKey: "AIzaSyDkAPtzfcldOGgX8eB0oMKBhsAJ_KKoTAA",
  authDomain: "workwhile-247820.firebaseapp.com",
  databaseURL: "https://workwhile-247820.firebaseio.com",
  projectId: "workwhile-247820",
  storageBucket: "gs://workwhile-247820.appspot.com/",
  messagingSenderId: "280730972481",
  appId: "1:280730972481:web:b9ce8b41de42cb579d71c0"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Storage API ***

  getStorage = () => this.storage;
}

export default Firebase;
