import React, { useState } from "react";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { withFirebase } from "../Firebase";
import api from "../../api";

function TipFileUpload({ firebase }) {
  const [file, setFile] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [uploading, setUploading] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const [errors, setErrors] = useState([]);
  const [paymentItems, setPaymentItems] = useState([]);

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    // Validate file type
    if (file.type !== "text/csv") {
      alert("Invalid file type. Only CSV files are allowed.");
      setFile(null);
      return;
    }

    setFile(file);
  };

  const handleCompanyIdChange = (e) => {
    setCompanyId(e.target.value);
  };

  const handleFileUploadAndProcess = async () => {
    // Validate the file is selected
    if (!file || !companyId) {
      return;
    }
    setUploading(true);
    let localErrors = [];
    let localPaymentIds = [];

    // Parse and validate the CSV
    const parseResult = await new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          resolve(results);
        },
      });
    });

    const rows = parseResult.data;

    // Validate column names
    const columns = Object.keys(rows[0]);
    if (!columns.includes("phone_number") || !columns.includes("amount")) {
      localErrors.push(
        "Invalid column structure. The CSV must have the columns: 'phone_number' and 'amount'"
      );
      return;
    }

    // CSV parsed, and validated.
    for (const row of rows) {
      const phone_number = row.phone_number;
      const amount = row.amount;
      try {
        const res = await api.post(
          `/admin/company/${companyId}/tips`,
          {
            phone_number: phone_number,
            amount: amount,
          },
          {
            validateStatus: false,
          }
        );
        if (res.status === 201) {
          localPaymentIds.push(res.data);
        } else {
          localErrors.push(
            `Failed to post tip for phone number ${phone_number}: ${res.data.message.general}`
          );
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    }

    setPaymentItems(localPaymentIds.length > 0 ? localPaymentIds : []);
    setErrors(localErrors.length > 0 ? localErrors : []);

    // Upload the file to Firebase storage for record.
    const storage = firebase.getStorage();
    let filename = new Date()
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
      .replace(/\//g, "-");
    const uniqueFilename = uuidv4() + "-" + filename;
    const fileRef = storage.ref(`public/${companyId}/${uniqueFilename}`);

    setUploading(true);
    await fileRef.put(file);

    // Make the file publicly readable and set metadata
    const metadata = {
      cacheControl: "public, max-age=31536000",
      contentDisposition: `inline; filename="${filename}"`,
      customMetadata: {
        public: "true",
      },
    };
    await fileRef.updateMetadata(metadata);
    await fileRef.getDownloadURL().then((url) => setDownloadURL(url));
    setUploading(false);
  };

  return (
    <div>
      <h3>1. Enter Company ID</h3>
      <input
        type="text"
        placeholder="Company ID"
        value={companyId}
        onChange={handleCompanyIdChange}
      />
      <br />
      <br />
      <br />
      <h3>2. Upload Tip CSV</h3>
      <input type="file" onChange={handleFileChange} />
      <br />
      <br />
      <br />
      <h3>3. Upload and Process</h3>
      <button onClick={handleFileUploadAndProcess} disabled={uploading}>
        {uploading ? "Uploading and Processing..." : "Upload and Process"}
      </button>
      {downloadURL && (
        <h5>
          File has been saved to cloud storage.{" "}
          <a href={downloadURL} target="_blank" rel="noreferrer">
            Click here to view the file or save the link.
          </a>
        </h5>
      )}
      <br />
      <br />
      {errors.length > 0 && (
        <div>
          <h3>There were the following errors:</h3>
          {errors.map((error, index) => (
            <h5 key={index}>{error}</h5>
          ))}
        </div>
      )}
      <br />
      <br />
      {paymentItems.length > 0 && (
        <div>
          <h3>The following UserPayments were created:</h3>
          {paymentItems.map((id) => (
            <h5>{id}</h5>
          ))}
        </div>
      )}
    </div>
  );
}

export default withFirebase(TipFileUpload);
