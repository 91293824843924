import React, { useEffect } from 'react';
import Filter from '../Shared/Filter';
import PositionOverview from './PositionOverview';
import { approvePosition, fetchPositionCompanyData } from '../../../src/actions/company_actions';
import CompanyOverview from '../features/Company/CompanyOverview';
import { withAuthorization } from '../Session';
import ClockInOutCodes from './ClockInOut';
import MinPayPolicy, {hasCustomPolicy} from './MinPayPolicy';
import BundlingEditor from './BundlingEditor';
import RequirementsForPosition from './RequirementsForPosition';
import CollapsibleSection from '../Shared/CollapsibleSection';
import UpcomingShiftsOverview from './UpcomingShiftsOverview';
import CustomCancellationPolicy, { hasCustomPolicies } from './CustomCancellationPolicy';
import LocationEditor from './LocationEditor';
const PositionEditorContainer = () => {
    const [positionId, setPositionId] = React.useState<number | null>(null);
    const [positionData, setPositionData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        clearAndFetchPositionData()
    }, [positionId]);

    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramValue = urlParams.get('positionId');
        if (paramValue !== null) {
            setPositionId(parseInt(paramValue))
        }
    }, [])

    function clearAndFetchPositionData() {
        setPositionData(null);
        setIsLoading(true);
        if (positionId === null || positionId === undefined) {
            setIsLoading(false)
            return;
        }
        const responseCallback = (response) => {
            let data = response.data.data.admin.positions
            if (data !== null && data.length === 1) {
                setPositionData(data[0])
            }
            setIsLoading(false)
        }

        const errorCallback = (error) => {
            console.log(error)
            setIsLoading(false)
        }
        fetchPositionCompanyData(positionId, responseCallback, errorCallback)
    }

    const positionIdInput = 
        <input
                className="input"
                name="positionId"
                value={positionId}
                onChange={(e) => setPositionId(parseInt(e.target.value))}
                type="number"
                placeholder="Position ID"
            />
    
    function onApprovePosition() {
        const responseCallback = (response) => {
            alert('Success')
            setIsLoading(false)
            clearAndFetchPositionData()
        }
        const errorCallback = (error) => {
            alert('Error: could not approve')
            console.log(error)
            setIsLoading(false)
        }

        setIsLoading(true);
        approvePosition(positionId, responseCallback, errorCallback);
    }
    
    return ( 
        <div>
            <br/>
            <br/>
            <h2>✏️ Position {positionData && positionData.isApproved ? 'Editor' : 'Approval'} Checklist</h2>
            <Filter selects={[positionIdInput]} instruction={'Enter Position Id to View & Edit'}/>
            <br/>
            {isLoading ? <>Loading...</> : <></>}
            {positionData === null ? null : <>
                    <CollapsibleSection title="Position Overview" defaultOpen={true} titleStyles={headerStyle}>
                        <h5> Position is currently {positionData.isApproved ? 'APPROVED ✅' : 'NOT YET APPROVED 🚨'}. Click text to edit</h5>
                        <PositionOverview positionData={positionData} refreshCallback={clearAndFetchPositionData}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title="Company Info" defaultOpen={true} titleStyles={headerStyle}>
                        <CompanyOverview company={positionData ? positionData.company : null} refreshCallback={clearAndFetchPositionData}/>
                    </CollapsibleSection>

                    <CollapsibleSection title="Upcoming Shift(s)" defaultOpen={false} titleStyles={headerStyle}>
                        <UpcomingShiftsOverview positionId={positionId} companyId={positionData.company.id}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title="Requirements" defaultOpen={false} titleStyles={headerStyle}>
                        <RequirementsForPosition positionId={positionId}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title="Location & Clock-In Radius " defaultOpen={false} titleStyles={headerStyle}>
                        <LocationEditor positionData={positionData}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title={`Change bundling: Bundling is currently ${positionData.bundling === 'none' ? 'Off' : 'On'}`} defaultOpen={false} titleStyles={headerStyle}>
                        <BundlingEditor currentlyBundled={positionData ? (positionData.bundling !== 'none') : false} positionId={positionId}/>
                    </CollapsibleSection>

                    <CollapsibleSection title="Configure Clock In/Out" defaultOpen={false} titleStyles={headerStyle}>
                        <ClockInOutCodes positionId={positionId} clockInActivated={positionData.clockInCodeEnabled} clockOutActivated={positionData.clockOutCodeEnabled}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title={`Min Pay Policy: ${hasCustomPolicy(positionData.company) ? 'Custom policy configured' : 'No custom policy. Default pay for full shift.'}`} defaultOpen={hasCustomPolicy(positionData.company)} titleStyles={headerStyle}>
                        <MinPayPolicy companyData={positionData.company}/>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title={`Auto Cushion is currently: ${positionData.allowAutocushion ? 'On' : 'Off'}`} defaultOpen={false} titleStyles={headerStyle}>
                        <>To turn {positionData.allowAutocushion ? 'Off' : 'On'} 
                        <br/>
                        <code>@wobot company toggle-autocushion {positionId} {positionData.allowAutocushion ? "--off" : '--on'}</code></>
                    </CollapsibleSection>

                    <CollapsibleSection title={`Configure Tip: Currently ${positionData.isTipEligible ? 'On':'Off'}`} defaultOpen={false} titleStyles={headerStyle}>
                        <>To change this run</>
                        <br/>
                        <code>@wobot company toggle-tip-enabled {positionId} {positionData.company.id} {positionData.isTipEligible ? '--off':'--on'}</code>
                    </CollapsibleSection>
                    
                    <CollapsibleSection title={`Delay Pay. Currently set to: ${positionData.company.companyShiftEndPaymentDelayHours} hours`} defaultOpen={false} titleStyles={headerStyle}>
                        <>Our default is 24 hours. To provide anything outside of that run the following with a whole number hours</>
                        <br/>
                        <code>@wobot company update-payment-delay-hours {positionData.company.id} num_hours</code>
                    </CollapsibleSection>

                    <CollapsibleSection title={`Custom Cancellation Policy: ${hasCustomPolicies(positionData.company.cancellationPolicies) ? '✅ Has Custom' : '❌ None Configured'}`} defaultOpen={false} titleStyles={headerStyle}>
                        <CustomCancellationPolicy companyId={positionData.company.id} cancellationPolicies={positionData.company.cancellationPolicies}/>
                    </CollapsibleSection>


                    {positionData.isApproved ? 
                        <h3>👍🏾 Position already approved. You are done. </h3> :
                        <button type="submit" className="submit-button" style={{minWidth:'150px'}} onClick={onApprovePosition}>
                            Approve
                        </button>
                    }
            </>}
        </div>
    );
};
const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(PositionEditorContainer);


const headerStyle = {
    alignItems: 'left',
    fontSize: 30,
}