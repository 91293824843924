import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment-timezone";
import Loading from "../Shared/loading";
import graphql from "../../graphql";
import { getEventLogsColumns, eventLogQuery } from "../Shared/eventLogs";
import { User } from "../Shared/common_defs";

const _dayFormat = "YYYY-MM-DD";

interface Props {
  user: User;
}

const UserEventLog = ({ user }: Props) => {
  const timezone =
    user && user.address ? user.address.timezone : moment.tz.guess(true);
  const eventLogsColumns = getEventLogsColumns(timezone);
  const [submittingEventLogRequest, setSubmittingEventLogRequest] =
    useState(false);
  const [eventLogs, setEventLogs] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract("days", 7).format(_dayFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(_dayFormat));

  useEffect(() => {
    requestEventLog();
  }, []);

  const requestEventLog = (e?) => {
    if (e) {
      e.preventDefault();
    }
    if (!startDate || !endDate) {
      window.alert("Please enter both a start date and end date");
    }
    setEventLogs(null);
    setSubmittingEventLogRequest(true);
    graphql(eventLogQuery(user.id, startDate, endDate))
      .then((response) => {
        if (response.data.data.admin.eventLogs) {
          console.log(
            "response.data.data.admin.eventLogs: ",
            response.data.data.admin.eventLogs
          );
          setEventLogs(response.data.data.admin.eventLogs);
        }
        setSubmittingEventLogRequest(false);
      })
      .catch((error) => {
        setSubmittingEventLogRequest(false);
      });
  };

  return (
    <div>
      <span style={{ marginLeft: 5, marginRight: 5, color: "rgba(0,0,0,0.5)" }}>
        See events between
      </span>
      <input
        type="date"
        name="startDate"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <span style={{ marginLeft: 5, marginRight: 5, color: "rgba(0,0,0,0.5)" }}>
        and
      </span>
      <input
        type="date"
        name="endDate"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
      <span style={{ marginLeft: 5, marginRight: 5, color: "rgba(0,0,0,0.5)" }}>
        {!submittingEventLogRequest && (
          <button onClick={(e) => requestEventLog(e)}>Submit</button>
        )}
        {submittingEventLogRequest && <Loading type="button" />}
      </span>
      {eventLogs && eventLogs.length > 0 ? (
        <BootstrapTable
          striped
          keyField="timestamp"
          data={eventLogs}
          columns={eventLogsColumns}
          rowStyle={{ fontSize: 10 }}
        />
      ) : (
        !submittingEventLogRequest && <p>No events found for this user.</p>
      )}
      <hr />
    </div>
  );
};

export default UserEventLog;
