import React from 'react';
import '../Home/home.css';
import {withAuthorization} from "../Session";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import graphql from "../../graphql";
import LazyLoadingMsg from "../Shared/LazyLoadingMsg";
import { getShiftColumns, shiftsQuery } from './shifts';

import {filterShifts, FILTERS, getRowStyles} from "../utils/util";

import FilterBar from "../Shared/ShiftsFilterBar";

const INITIAL_STATE = {
    shifts: [],
    fetching: false,
};

class OngoingShifts extends React.Component {

    constructor(props) {
        super(props);
        this.fetchShifts = this.fetchShifts.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.state = { ...INITIAL_STATE, filters:FILTERS };
        this.columns = getShiftColumns('ongoing', this.fetchShifts);
    }

    componentDidMount() {
        this.fetchShifts()
    }

    fetchShifts() {
        this.setState({fetching: true});
        graphql(shiftsQuery({type: 'ongoing'})).then(response => {
            this.setState({
                shifts: response.data.data.admin.shifts,
                fetching: false
            })
        })
    }

    render() {
        const {fetching, shifts, filters} = this.state;

        const filteredShifts = filterShifts(shifts, filters);

        return (
            <div className="users-table mt-5">
                <LazyLoadingMsg in={fetching}>
                    We're loading your latest information...
                </LazyLoadingMsg>
                <h3>Ongoing shifts ({"start - 30mins < current time < end + 30mins"})</h3>
                <FilterBar
                    showBorderTop
                    showBorderBottom
                    showCompanyFilter
                    showLocationFilter
                    showPositionFilter
                    showWorkerFilter
                    showWorkerIdFilter
                    showShiftFilter
                    showListingFilter
                    shifts={shifts}
                    handleFilterChange={this.handleFilterChange}
                    handleDateChange={this.handleDateChange}
                    fromDate={filters.fromDate}
                    toDate={filters.toDate}
                    showCancelledShiftsFilter={true}
                    showCancelledShifts={filters.showCancelledShifts}
                    showUnfilledShiftsFilter={true}
                    showUnfilledShifts={filters.showUnfilledShifts}
                />
                <BootstrapTable striped hover
                                keyField='id'
                                data={ filteredShifts }
                                columns={this.columns}
                                rowStyle={getRowStyles} />
            </div>
        );
    }

    handleFilterChange(selectedOption, actionMeta) {
        const { filters } = this.state;
        filters[actionMeta.name] = selectedOption;
        this.setState({ filters });
    }

    handleDateChange(name, value){
        this.setState((prevState)=> {
            const newFilters = prevState.filters;
            newFilters[name] = value;
            return {filters: newFilters}
        });
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(OngoingShifts);
