import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import graphql from "../../graphql";
import { withAuthorization } from "../Session";
import PositionsFilterBar from "../Shared/PositionsFilterBar";
import LazyLoadingMsg from "../Shared/LazyLoadingMsg";
import PositionDetail from "./PositionDetail";
import { filtersAreEmpty } from "../utils/util";

const Positions = () => {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredPositions, setFilteredPositions] = useState([]);

  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = () => {
    setLoading(true);
    graphql(positionsQuery())
      .then((response) => {
        if (response) {
          setPositions(response.data.data.admin.positions);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setFilteredPositions(filterPositions(positions, filters));
  }, [positions]);

  const handleFilterChange = (selectedOption, actionMeta) => {
    const newFilters = filters;
    newFilters[actionMeta.name] = selectedOption;
    setFilters(newFilters);
    setFilteredPositions(filterPositions(positions, newFilters));
  };

  const columns = getPositionColumns(fetchPositions);

  return (
    <div className="users-table mt-5">
      <LazyLoadingMsg in={loading}>
        We're loading your latest information...
      </LazyLoadingMsg>
      <h3>Positions</h3>
      <PositionsFilterBar
        showBorderTop
        showBorderBottom
        showCompanyFilter
        showPositionFilter
        showPositionIdFilter
        positions={positions}
        handleFilterChange={handleFilterChange}
      />
      <BootstrapTable
        striped
        hover
        keyField="id"
        data={filteredPositions}
        columns={columns}
        rowStyle={{ fontSize: 10 }}
      />
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Positions);

export const positionsQuery = () => {
  return `
        admin { 
            positions {
                id, name, about, requirements, clockInRequirements
                requirements2 {
                  id,
                  category,
                  credential,
                  description,
                  name,  
                  isWorkerVisible,
                },
                company {name, id},
                positionTemplateId,
                needsCar, needsLicense, needsFullTime, 
                prescreened, bundling, experienceRequirement,
                allowAutocushion, bailCoefficient, autoShiftBonusPct, 
            }
        }`;
};

// Take in positions and a map of filters, return positions which meet those filters.
const filterPositions = (positions, filters) => {
  if (filtersAreEmpty(filters)) return positions;
  let filteredPositions = [];
  for (const position of positions) {
    if (matchesPositionFilters(filters, position))
      filteredPositions.push(position);
  }
  return filteredPositions;
};

export const getPositionColumns = (fetchPositions) => {
  return [
    {
      dataField: "id",
      text: "Id",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "about",
      text: "Details",
      headerStyle: { width: "2em", fontSize: 10 },
      formatter: (cell, row) => {
        return (
          <>
            <p>
              {row["company"]
                ? `${row["company"]["name"]} (${row["company"]["id"]})`
                : "No Associated Company"}
            </p>
            <PositionDetail
              about={cell}
              requirements={row["requirements2"]}
              clockInRequirements={row["clockInRequirements"]}
            />
          </>
        );
      },
    },
    {
      dataField: "needsCar",
      text: "needsCar",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "needsLicense",
      text: "needsLicense",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "needsFullTime",
      text: "needsFullTime",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "prescreened",
      text: "prescreened",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "bundling",
      text: "bundling",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "experienceRequirement",
      text: "experienceRequirement",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "allowAutocushion",
      text: "allowAutocushion",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "bailCoefficient",
      text: "bailCoefficient",
      headerStyle: { width: "1em", fontSize: 10 },
    },
    {
      dataField: "autoShiftBonusPct",
      text: "autoShiftBonusPct",
      headerStyle: { width: "1em", fontSize: 10 },
    },
  ];
};

export const matchesPositionFilters = (filters, position) => {
  let [matchCompany, matchPosition, matchPositionId] = [false, false, false];
  const { company, id, name } = position;

  const companyFilter = filters.company;
  if (companyFilter && companyFilter.length > 0 && company) {
    const companyForComparison = {
      value: parseInt(company.id),
      label: company.name,
    };
    for (let i = 0; i < companyFilter.length; i += 1) {
      if (companyFilter[i].value === companyForComparison.value)
        matchCompany = true;
    }
  } else {
    matchCompany = true;
  }

  // if position id is in the position filter return true;
  const positionFilter = filters.position;
  if (positionFilter && positionFilter.length > 0 && id) {
    for (let j = 0; j < positionFilter.length; j += 1) {
      if (positionFilter[j].value === name) matchPosition = true;
    }
  } else {
    matchPosition = true;
  }

  // if position id is in the position filter return true;
  const positionIdFilter = filters.positionId;
  if (positionIdFilter && positionIdFilter.length > 0 && id) {
    for (let j = 0; j < positionIdFilter.length; j += 1) {
      if (positionIdFilter[j].value === parseInt(id)) matchPositionId = true;
    }
  } else {
    matchPositionId = true;
  }

  if (matchCompany && matchPosition && matchPositionId) return true;

  return false;
};
