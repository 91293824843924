import React, { useState, useEffect } from "react";
import "./Commands.css";
import api from "../../../api";
import Command from "./Command";
import { withAuthorization } from "../../Session";

function Commands() {
  const [commands, setCommands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCommands, setFilteredCommands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCommands();
  }, []);

  const fetchCommands = async () => {
    api
      .get("/admin/commands")
      .then((response) => {
        setCommands(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("Error fetching commands.");
      });
  };

  useEffect(() => {
    const results = commands.filter((command) =>
      command.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCommands(results);
  }, [searchTerm, commands]);

  const groupedCommands = filteredCommands.reduce((groups, command) => {
    const groupName = command.app_group;
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push(command);
    return groups;
  }, {});

  return (
    <div>
      <input
        className="search-box"
        type="text"
        placeholder="Search commands..."
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        Object.entries(groupedCommands).map(
          ([appGroupName, appGroupCommands]) => (
            <div key={appGroupName}>
              <h2>{appGroupName}</h2>
              {appGroupCommands.map((command) => (
                <Command key={command.name} command={command} />
              ))}
              <hr />
            </div>
          )
        )
      )}
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Commands);
