import React, { useState, useRef } from "react";
import { defs } from "../Shared/defs";

function TutorialBuilder() {
    const [sections, setSections] = useState([]);
    const preRef = useRef(null);

    const handleAddSection = () => {
        const newSection = { title: "", content: [{ data: "", type: "" }] };
        setSections([...sections, newSection]);
    };

    const handleSectionTitleChange = (event, index) => {
        const updatedSection = { ...sections[index], title: event.target.value };
        const updatedSections = [...sections];
        updatedSections[index] = updatedSection;
        setSections(updatedSections);
    };

    const handleAddContent = (index) => {
        console.log("adding content::::: ", sections[index]);
        const newContent = { data: "", type: "" };
        if (sections[index].content[0].type === "bullet_list") {
            newContent.data = [];
        }
        const updatedSection = { ...sections[index], content: [...sections[index].content, newContent] };
        const updatedSections = [...sections];
        updatedSections[index] = updatedSection;
        setSections(updatedSections);
    };

    const handleContentDataChange = (event, sectionIndex, contentIndex) => {
        const updatedContent = { ...sections[sectionIndex].content[contentIndex], data: event.target.value };
        const updatedSection = { ...sections[sectionIndex], content: [...sections[sectionIndex].content] };
        updatedSection.content[contentIndex] = updatedContent;
        const updatedSections = [...sections];
        updatedSections[sectionIndex] = updatedSection;
        setSections(updatedSections);
    };

    const handleContentTypeChange = (event, sectionIndex, contentIndex) => {
        const updatedContent = { ...sections[sectionIndex].content[contentIndex], type: event.target.value };
        const updatedSection = { ...sections[sectionIndex], content: [...sections[sectionIndex].content] };
        updatedSection.content[contentIndex] = updatedContent;
        const updatedSections = [...sections];
        updatedSections[sectionIndex] = updatedSection;
        setSections(updatedSections);
    };

    const handleBulletListDataChange = (event, sectionIndex, contentIndex, bulletIndex) => {
        const updatedData = [...sections[sectionIndex].content[contentIndex].data];
        updatedData[bulletIndex] = event.target.value;
        const updatedContent = { ...sections[sectionIndex].content[contentIndex], data: updatedData };
        const updatedSection = { ...sections[sectionIndex], content: [...sections[sectionIndex].content] };
        updatedSection.content[contentIndex] = updatedContent;
        const updatedSections = [...sections];
        updatedSections[sectionIndex] = updatedSection;
        setSections(updatedSections);
    };

    const handleAddBullet = (sectionIndex, contentIndex) => {
        const updatedData = [...sections[sectionIndex].content[contentIndex].data, ""];
        const updatedContent = { ...sections[sectionIndex].content[contentIndex], data: updatedData };
        const updatedSection = { ...sections[sectionIndex], content: [...sections[sectionIndex].content] };
        updatedSection.content[contentIndex] = updatedContent;
        const updatedSections = [...sections];
        updatedSections[sectionIndex] = updatedSection;
        setSections(updatedSections);
    };
    const handleRemoveBullet = (sectionIndex, contentIndex, bulletIndex) => {
        const updatedData = [...sections[sectionIndex].content[contentIndex].data];
        updatedData.splice(bulletIndex, 1);
        const updatedContent = { ...sections[sectionIndex].content[contentIndex], data: updatedData };
        const updatedSection = { ...sections[sectionIndex], content: [...sections[sectionIndex].content] };
        updatedSection.content[contentIndex] = updatedContent;
        const updatedSections = [...sections];
        updatedSections[sectionIndex] = updatedSection;
        setSections(updatedSections);
    };

    const copyToClipboard = () => {
        const pre = preRef.current;
        const range = document.createRange();
        range.selectNode(pre);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
      };

    return (
        <div>
            {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    <input
                        type="text"
                        value={section.title}
                        placeholder="Page title"
                        onChange={(event) => handleSectionTitleChange(event, sectionIndex)}
                    />
                    <p>Page Content: (select a type and add information as needed)</p>
                    {section.content.map((content, contentIndex) => (
                        <div key={contentIndex}>
                            <select value={content.type} onChange={(event) => handleContentTypeChange(event, sectionIndex, contentIndex)}>
                                <option value="">--Select content type--</option>
                                <option value="paragraph">Paragraph</option>
                                <option value="subtitle">Subtitle</option>
                                <option value="pdf_url">PDF URL</option>
                                <option value="image_url">Image URL</option>
                                <option value="video_youtube">YouTube Video URL</option>
                                <option value="bullet_list">Bullet List</option>
                            </select>
                            {content.type === "paragraph" && (
                                <textarea value={content.data} onChange={(event) => handleContentDataChange(event, sectionIndex, contentIndex)}
                                />
                            )}
                            {content.type === "subtitle" && (
                                <input
                                    type="text"
                                    value={content.data}
                                    placeholder="Subtitle text"
                                    onChange={(event) => handleContentDataChange(event, sectionIndex, contentIndex)}
                                />
                            )}
                            {content.type === "pdf_url" && (
                                <input
                                    type="url"
                                    value={content.data}
                                    placeholder="PDF URL"
                                    onChange={(event) => handleContentDataChange(event, sectionIndex, contentIndex)}
                                />
                            )}
                            {content.type === "image_url" && (
                                <input
                                    type="url"
                                    value={content.data}
                                    placeholder="Image URL"
                                    onChange={(event) => handleContentDataChange(event, sectionIndex, contentIndex)}
                                />
                            )}
                            {content.type === "video_youtube" && (
                                <input
                                    type="url"
                                    value={content.data}
                                    placeholder="YouTube Video URL"
                                    onChange={(event) => handleContentDataChange(event, sectionIndex, contentIndex)}
                                />
                            )}
                            {content.type === "bullet_list" && (
                                <div>
                                <ul>
                                    {content.data && content.data.map((bullet, bulletIndex) => (
                                        <li key={bulletIndex}>
                                            <input
                                                type="text"
                                                value={bullet}
                                                placeholder="Bullet text"
                                                onChange={(event) => handleBulletListDataChange(event, sectionIndex, contentIndex, bulletIndex)}
                                            />
                                            <button onClick={() => handleRemoveBullet(sectionIndex, contentIndex, bulletIndex)}>Remove Bullet</button>
                                        </li>
                                    ))}
                                    {!content.data && <input
                                                type="text"
                                                placeholder="Bullet text"
                                                onChange={(event) => handleBulletListDataChange(event, sectionIndex, contentIndex, 0)}
                                            />}
                                </ul>
                                
                                <button onClick={() => handleAddBullet(sectionIndex, contentIndex)}>Add Bullet</button>
                                </div>

                            )}
                        </div>
                    ))}
                    <button onClick={() => handleAddContent(sectionIndex)} style={{marginTop: 10}}>Add content</button>
                </div>
            ))}
            <button onClick={handleAddSection} style={{marginTop: 20}}>Add Page</button>
            <br />
            <br />
            <div style={{display: 'flex'}}><h3>Generated Json:</h3>
            <button onClick={copyToClipboard}>Copy to Clipboard</button></div>
                <pre style={{height: 300, width: 400, overflow:'auto', borderWidth: 1, borderColor: defs.mediumBlack, borderStyle: 'solid'}} ref={preRef}>{JSON.stringify(sections, null, 2)}</pre>
        </div>
    );
}

export default TutorialBuilder;