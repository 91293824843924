import React from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSection from "../Shared/CollapsibleSection";
import CollapsibleSectionTable from "../Shared/CollapsibleSectionTable";

interface Props {
  user: User;
}

const UserWorkProfile = ({ user }: Props) => {
  const data = React.useMemo(
    () => [
      {
        key: "has_ssn",
        value: user.hasSsn ? "true" : "false",
      },
      {
        key: "Experienced",
        value: user.tierExperienced ? "true" : "false",
      },
      {
        key: "Rating",
        value: user.rating ? user.rating.toFixed(2) : 0,
      },
      {
        key: "# active strikes",
        value: user.strikes ? user.strikes?.length.toString() : 0,
      },
      {
        key: "num_finished_shifts",
        value: user.userStats?.shiftCount,
      },
      {
        key: "has_license",
        value: user.workProfile?.hasLicense ? "true" : "false",
      },
      {
        key: "has_car",
        value: user.workProfile?.hasCar ? "true" : "false",
      },
      {
        key: "currently_employed",
        value: user.workProfile?.currentlyEmployed ? "true" : "false",
      },
      {
        key: "num_hours_desired",
        value: user.workProfile?.numHoursDesired,
      },
      {
        key: "wants_full_time",
        value: user.workProfile?.wantsFullTime ? "true" : "false",
      },
      {
        key: "telehealth_benefit_status",
        value: user.workProfile?.telehealthBenefitStatus,
      },
      {
        key: "current elite_status",
        value: user.workProfile?.isElite ? "true" : "false",
      },
      {
        key: "w2 status",
        value: user.w2Status,
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: "key",
      },
      {
        accessor: "value",
      },
    ],
    []
  );

  const credentialsData = React.useMemo(() => {
    // check if credentials is not null or undefined before calling map
    return user.credentials
      ? user.credentials
          .filter((credential) => credential && credential.workerRequirement) // skip elements where workerRequirement is null
          .map((credential) => ({
            key: credential.workerRequirement.name,
            value: credential.workerRequirement.documentNeeded
              ? "Click here"
              : "Experience",
            ...(credential.workerRequirement.documentNeeded && {
              link: `https://console.cloud.google.com/storage/browser/ww-credentials/${credential.id}`,
            }),
          }))
      : []; // return an empty array if user.credentials is null
  }, [user]);

  return (
    <CollapsibleSection title="Work Profile" defaultOpen>
      <CollapsibleSectionTable data={data} columns={columns} />
      <CollapsibleSection title="Credentials">
        <CollapsibleSectionTable data={credentialsData} columns={columns} />
      </CollapsibleSection>
    </CollapsibleSection>
  );
};

export default UserWorkProfile;
