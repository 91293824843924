import React, { useState } from 'react';

const TutorialDisplay = () => {
  const [data, setData] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const sections = data ? JSON.parse(data) : [];

  const renderSection = (section) => {
    if (!section) {
        return null;
    }
    return (
      <div>
        <h2>{section.title}</h2>
        {section.content.map((content, contentIndex) => {
          switch (content.type) {
            case 'subtitle':
              return <h3 key={contentIndex}>{content.data}</h3>;
            case 'paragraph':
              return <p key={contentIndex}>{content.data}</p>;
            case 'bullet_list':
              return (
                <ul key={contentIndex}>
                  {content.data.map((bullet, bulletIndex) => (
                    <li key={bulletIndex}>{bullet}</li>
                  ))}
                </ul>
              );
            case 'pdf_url':
              return (
                <a href={content.data} target="_blank" rel="noreferrer" key={contentIndex}>
                  View PDF
                </a>
              );
            case 'image_url':
                return (
                  <img src={content.data}  key={contentIndex} style={{width: 360, height: 300}}/>
                );
            default:
              return null;
          }
        })}
      </div>
    );
  };

  const renderNavigationButtons = () => {
    return (
      <div>
        {pageIndex > 0 && (
          <button onClick={() => setPageIndex(pageIndex - 1)}>Previous</button>
        )}
        {pageIndex < sections.length - 1 && (
          <button onClick={() => setPageIndex(pageIndex + 1)}>Next</button>
        )}
      </div>
    );
  };

  return (
    <div style={{display: 'flex', marginTop: 20}}>
      <textarea rows={10} value={data} onChange={(e) => setData(e.target.value)} style={{marginRight: 50, width: 400}}/>
      <div style={{height: 850, width: 400, borderStyle: 'solid', padding: 20}}>
        {sections.length > 0 && (
          <div>
            {renderSection(sections[pageIndex])}
            {renderNavigationButtons()}
          </div>
        )}
      </div>
    </div>
  );
};


export default TutorialDisplay;