import React, { useState } from "react";
import ListingNextTier from "./ListingNextTier";
import ListingNotify from "./ListingNotify";
import ListingTiers from "./ListingTiers";
import { Listing, WorkShift } from "../../Shared/common_defs";
import RefreshButton from "../../Shared/RefreshButton";
import moment from "moment";

interface Props {
  shift: WorkShift;
  listing: Listing;
  shiftFull: boolean;
  shiftInProgress: boolean;
  fetchListings: () => void;
}

const ListingInfo = ({
  listing,
  shift,
  shiftFull,
  shiftInProgress,
  fetchListings,
}: Props) => {
  const [showActions, setShowActions] = useState(false);

  const isListingInTempRosterHold =
    listing.tier === "roster" && shift.rostersHoldExpiresAt;

  const durationTillRosterHoldReleased = shift.rostersHoldExpiresAt
    ? moment.duration(moment(shift.rostersHoldExpiresAt).diff(moment()))
    : null;

  const totalMinutes = durationTillRosterHoldReleased
    ? Math.floor(durationTillRosterHoldReleased.asMinutes())
    : null;

  const hours =
    totalMinutes && totalMinutes > 0 ? Math.floor(totalMinutes / 60) : null;
  const minutes = totalMinutes && totalMinutes > 0 ? totalMinutes % 60 : null;

  return (
    <div key={listing.id}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ flex: 1 }}>
          <a
            href={`https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%0Alog_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%0AjsonPayload.message.type%3D%22listing%22%0AjsonPayload.message.listing_id%3D%22${listing.id}%22;timeRange=P7D;summaryFields=jsonPayload%252Fmessage%252Flisting_id,jsonPayload%252Fmessage%252Fsub_type,jsonPayload%252Fmessage%252Fuser_id,jsonPayload%252Fmessage%252Ffrom_tier,jsonPayload%252Fmessage%252Fto_tier:false:32:beginning?project=workwhile-247820`}
            target="_blank"
          >
            {listing.id}
          </a>
          <ListingTiers listing={listing} location={shift.location} />
        </div>
        <RefreshButton refreshFn={() => fetchListings()} />
      </div>
      {!shiftFull && !shiftInProgress && (
        <>
          <button
            className="standard-button"
            type='button'
            onClick={() => setShowActions(!showActions)}
          >
            {showActions ? "Hide" : "Show"} Actions
          </button>
          {showActions && (
            <>
              <ListingNotify listing={listing} fetchListings={fetchListings} />
              <ListingNextTier
                listing={listing}
                fetchListings={fetchListings}
              />
            </>
          )}
        </>
      )}
      {isListingInTempRosterHold && hours && minutes ? (
        <p>
          <b>
            Listing will exit roster tier in {hours}h {minutes}m
          </b>
        </p>
      ) : null}
    </div>
  );
};

export default ListingInfo;
