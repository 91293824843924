import React, { useState } from "react";
import { Roster } from "../../Shared/common_defs";
import moment from "moment";

/**
 * Callout the rosters (i.e. Lists) which the shift is restricted to (if any)
 */

interface Props {
  rosters: Roster[];
  rostersHoldExpiresAt: string | null;
}

const ShiftRosters = ({ rosters, rostersHoldExpiresAt }: Props) => {
  // Flatten the array of workers from all rosters into a single array
  const allWorkers = rosters.reduce(
    (acc, roster) => acc.concat(roster.workers),
    []
  );
  // Calculate the number of unique workers
  const uniqueWorkerCount = new Set(allWorkers.map((worker) => worker.id)).size;
  return (
    <>
      <b>
        Restricted to {uniqueWorkerCount} workers{" "}
        {rostersHoldExpiresAt
          ? `until ${moment
              .tz(rostersHoldExpiresAt, moment.tz.guess(true))
              .format("ddd MMM Do, h:mma z")}`
          : `indefinitely`}
        :{" "}
      </b>
      <br />
      {rosters.map((roster) => (
        <RosterItem key={roster.id} roster={roster} />
      ))}
      <br />
    </>
  );
};

export default ShiftRosters;

const RosterItem = ({ roster }: { roster: Roster }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <a onClick={handleToggle} style={{ color: "blue", cursor: "pointer" }}>
        - {roster.name}(id:{roster.id})({roster.workers.length} workers)
      </a>
      {isOpen && (
        <div style={{ marginLeft: 15 }}>
          {roster.workers.map((worker) => (
            <span key={worker.id}>
              {worker.name} (id: {worker.id})
              <br />
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
