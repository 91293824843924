import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {PasswordForgetLink} from "../PasswordForget";
import './landing.css'
import * as ROUTES from "../../constants/routes";
import {withFirebase} from "../Firebase";
import {compose} from "recompose";
import '../Home/home.css'

const Landing = () => (
  <div className="landing">
      <h1 className="title">WorkWhile Admin Page - SignIn</h1>
      <SignInForm/>
      <PasswordForgetLink />
  </div>
);


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.LANDING);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <div className="center">
            <form onSubmit={this.onSubmit} className="center">
                <input
                    className="input"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                />
                <input
                    className="input"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                />
                <button disabled={isInvalid} type="submit" className="submit-button">
                    Sign In
                </button>

                {error && <p>{error.message}</p>}
            </form>
            </div>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export { SignInForm };

export default Landing;
