// activate-clock-in-codes


import React, { useState } from 'react';
import { withAuthorization } from '../Session';
interface Props {
    positionId: number;
    clockInActivated: boolean;
    clockOutActivated: boolean;
}

const ClockInOutCodes = ({positionId, clockInActivated, clockOutActivated}:Props) => {
    return (<>

        <h5>Clock In Code is currently {clockInActivated ? 'Activated' : 'Off'}</h5>
        {clockInActivated ? 
            <>To disable clock IN codes immediately: <br/>
            <code>@wobot company disable-clock-in-codes {positionId}</code>
            <br/></>
        : <>To activate clock IN codes. The clock in codes will go into effect at midnight unless otherwise specified. <br/>
            <code>@wobot company activate-clock-in-codes {positionId} YYYY-MM-DD</code>
            <br/></>
        }


    <h5>Clock Out Code is currently {clockOutActivated ? 'Activated' : 'Off'}</h5>
        {clockOutActivated ? 
            <>To disable clock OUT codes immediately: <br/>
            <code>@wobot company disable-clock-out-codes {positionId}</code>
            <br/></>
        : <>To activate clock OUT codes. The clock out codes will go into effect at midnight unless otherwise specified.<br/>
            <code>@wobot company activate-clock-out-codes {positionId}  YYYY-MM-DD</code>
            <br/></>
        }
    </>)
}
export default ClockInOutCodes