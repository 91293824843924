import React from "react";
import Listings from "./Listings/Listings";
import Workers from "./Workers/Workers";
import Pay from "./Pay/Pay";
import * as moment from "moment-timezone";

type ShiftsQueryParams = {
  type?: string;
  startDate?: string;
  endDate?: string;
  companyIds?: number[];
  shiftId?: number;
  market?: string;
};

export const shiftsQuery = ({
  type,
  startDate,
  endDate,
  companyIds,
  shiftId,
  market,
}: ShiftsQueryParams) => {
  let companyIdsInt = companyIds ? companyIds.map(Number) : null;
  return `
        admin { 
            shifts (
                grouping:"${type}"
                ${startDate ? `, startDate: "${startDate}"` : ""}
                ${endDate ? `, endDate: "${endDate}"` : ""}
                ${
                  companyIds
                    ? `, companyIds: ${JSON.stringify(companyIdsInt)}`
                    : ""
                }
                ${shiftId ? `, shiftId: "${shiftId}"` : ""}
                ${market ? `, market: "${market}"` : ""}
            ) {
                isTryout,
                id, startsAt, endsAt, cancelledAt, lunchLength, numWorkersFilled, pay, payRate, payLumpSum,
                workersNeeded, numCushionWorkersNeeded, numWorkersScheduled,
                numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true), numWorkersRejected, numWorkersRemoved,
                shiftBonuses {amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus},
                rosters {
                  id, isSpecificWorkerRequest, name,
                  workers {
                      id, name, profilePicUrl
                  }
                },
                rostersHoldExpiresAt,
                listings {id, tier, tierTransitions, numWaitlist },
                company {name, id},
                position {
                  name, id,
                  requirements2 {
                    id, name, credential
                  }
                },
                location {name, id, address {city, state, street, zip, lat, long, timezone}},
                market,
                work (status:"all") {
                    id, status, confirmedAt,
                    worker {id, name}
                }
                supervisor, supervisorPhone, supervisors { id, name, phoneNumber, userId, status}
                leadShifts {
                  id,
                  startsAt,
                  endsAt,
                  shiftBonuses { amount },
                  position { name, id},
                  location { name, id, address {city, state, street, zip, lat, long, timezone}}
                  locationless
                  shiftType, supervisor, supervisorPhone, supervisors { id, name, phoneNumber, userId, status}, workersNeeded, 
                  chargeLumpSum, chargeRate, payRate, payLumpSum, lunchLength
                  work {
                      id, status, confirmedAt
                      startedAt, completedAt
                      pendingAdminReview
                      status
                      trips {status, shareUrl, eta, etaSeconds, locationStatus, serviceDeviceId}
                      worker {
                          id, name, phoneNumber, profilePicUrl
                      }
                  }
              },
            }
        }`;
};

export const companiesForFiltersQuery = (grouping) => {
  return `
        admin { 
            companiesForFilters (grouping:"${grouping}") {
              id,
              name
            }
        }`;
}

export const getWaitlistCount = (data) => {
  if (!data.listings) {
    return 0;
  }
  let count = 0;
  for (const listing of data.listings) {
    count += listing.numWaitlist;
  }
  return count;
};

export const getShiftColumns = (type, fetchShifts) => {
  return [
    {
      dataField: "id",
      text: "ShiftID",
      headerStyle: { width: "2em", fontSize: 12 },
    },
    {
      dataField: "listing",
      text: "Listing",
      headerStyle: { width: "6em", fontSize: 12 },
      formatter: (cell, row) => <><Listings shift={row} /></>,
    },
    {
      dataField: "company",
      text: "Company",
      headerStyle: { width: "5em", fontSize: 12 },
      formatter: (cell, row) => {
        const credentialsNeededList =
          row.position.requirements2 && row.position.requirements2.length > 0
            ? row.position.requirements2
              .map((requirement) => requirement.credential)
              .filter((cred) => cred !== "none")
            : null;
        return (
          <>
            <p>
              {cell.name}({cell.id}) | {row.position.name}({row.position.id}) |{" "}
              {row.location.name}({row.location.id}):{" "}
              <span style={{ color: "rgba(0,0,0,0.5)" }}>
                {row.location.address.street} {row.location.address.city}{" "}
                {row.location.address.state} {row.location.address.zip}
              </span>
            </p>
            {credentialsNeededList &&
              credentialsNeededList.map((credential) => (
                <p style={{ color: "red" }}>
                  <b>({credential.toUpperCase()} CREDENTIAL NEEDED)</b>
                </p>
              ))}
            {row.supervisors.map((supervisor) => (
              <p>
                Supervisor: {supervisor.name}. Phone:{" "}
                {supervisor.phoneNumber || "N/A"}
              </p>
            ))}
          </>
        );
      },
    },
    {
      dataField: "startsAt",
      text: "Times",
      sort: true,
      headerStyle: { width: "5em", fontSize: 12 },
      formatter: (cell, row) => {
        const timezone =
          row.location && row.location.address
            ? row.location.address.timezone
            : moment.tz.guess(true);
        return (
          <p>
            {moment.tz(cell, timezone).format("ddd MMM Do, h:mma zz")} -{" "}
            {moment.tz(row.endsAt, timezone).format("ddd MMM Do, h:mma zz")}
            <br />
            {"Lunchbreak Length: " + row.lunchLength}
          </p>
        );
      },
    },
    {
      dataField: "payRate",
      text: "Pay",
      headerStyle: { width: "5em", fontSize: 12 },
      formatter: (cell, row) => <Pay shift={row} />,
    },
    {
      dataField: "work",
      text: "Workers",
      headerStyle: { width: "10em", fontSize: 12 },
      formatter: (cell, row) => <Workers shift={row} />,
    },
  ];
};
