import React from 'react';
import '../Home/home.css';
import {withAuthorization} from "../Session";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import graphql from "../../graphql";
import api from "../../api";
import AddListingBonus from "./add_listing_bonus";


const INITIAL_STATE = {
  listingsPending: []
};


class ListingsPending extends React.Component {

  constructor(props) {
    super(props);
    this.approveItem = this.approveItem.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchListingsPending()
  }

  approveItem = (listingId) => {
    api.post(`/admin/listing/approve`, {pending_id: listingId})
      .then((response) => {
        console.log(response);
        alert("approved");
        this.fetchListingsPending()
      })
      .catch((error) =>    {
        console.log("ERROR OCCURED: ", error);
      });
  };

  fetchListingsPending() {
    graphql(`
      admin { 
        listingsPending {
          position {name, id},
          locationId,
          companyId,
          id,
          shiftIds
        }
      }`).then(response => {
      console.log("response: ", response)
      this.setState({
        listingsPending: response.data.data.admin.listingsPending,
        fetching: false
      })
    })
  }

  getColumns() {
    return [{
      dataField: 'id',
      text: 'Id',
    }, {
      dataField: 'companyId',
      text: 'Company Id',
    }, {
      dataField: 'position',
      text: 'Position',
      sort: true,
      formatter: (cell, row) => (
        <p>{cell?.name}, {cell?.id}</p>
      )
    }, {
      dataField: 'locationId',
      text: 'location id',
    }, {
      dataField: 'shiftIds',
      text: 'Shift IDs',
    },{
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => {
        return <button key={'approve_' + row.id}
                       onClick={() => this.approveItem(row.id)}>Approve</button>
      }
    }]
  }

  render() {
    const {listingsPending} = this.state;

    return (
      <div className="users-table">
        <h1>Listings Pending</h1>

        <BootstrapTable striped hover
                        keyField='name' data={ listingsPending } columns={ this.getColumns() }  />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ListingsPending);
