import React from 'react';
import '../../Home/home.css';
import {withAuthorization} from "../../Session";
import api from "../../../api";
import graphql from "../../../graphql";
import Loading from '../../Shared/loading';
import BootstrapTable from 'react-bootstrap-table-next';

const INITIAL_STATE = {
  company_name: '',
  company_id: '',
  contact_email:'',
  companies:[],
  invite_link:'',
  already_sent: null,
  invoice: false,
  w2: false,
  logo: null,
  loading: false,
};

class Company extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchCompanies()
  }

  fetchCompanies() {
    this.setState({
      loading: true
    })
    graphql(
      `admin {
            companies {
              id,
              name,
              takePercentageEstimate,
              chargeUpfront,
              invoiceDueDays,
              pendingInvites {
                email
                hasAccepted
              },
            }
          }`
    ).then((res) => {
      this.setState({
        companies: res.data.data.admin.companies,
        loading: false,
      });
    });
  }

  onChange = event => {
    console.log("got shit: ", event.target.name, event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleCheckbox = (key) => {
    this.setState(prevState => ({
      [key]: !prevState[key]
    }));
  }


  onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0]),
        selectedFile: event.target.files[0]
      });
    }
  }

  uploadLogo = async (companyId) => {

    if (!this.state.logo) {
      return;
    }

    const data = {
      "url": this.state.logo
    }

    api.put(`/admin/company/${companyId}/logo`, data).then((result) => {
      console.log('Success:', result);
      this.setState({
        selectedFile: null,
        image: null,
      })
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  onCreateCompany = event => {
    event.preventDefault();
    const { company_name, logo, invoice, w2 } = this.state;
    if (!company_name || !logo) {
      alert("Please add a company name and LOGO URL")
      return
    }

    const data = {}
    if (invoice) {
      data['set_to_invoice'] = true
    }
    if (w2) {
      data['set_to_w2'] = true
    }
    api.post(
      `/admin/company/create/${company_name}`,
      data
    ).then((res) => {
      this.fetchCompanies()
      alert(`Company created! ID: ${res.data.company_id}. See table below`);
      this.uploadLogo(res.data.company_id);
    }).catch(error => {
      alert(error.message)
    })
  }

  onInviteUserToCompany = event => {
    event.preventDefault();
    const { company_id, contact_email } = this.state;
    const formattedContactEmail = contact_email.replace(/\s/g, '')
    api.post(
      `/admin/company/invite/${company_id}/${formattedContactEmail}`
    ).then((res) => {
      const link = res.data.link;
      const already_sent = res.data.already_sent;
      this.setState({invite_link: link, already_sent});
    }).catch(error => {
      alert(error.message)
    })
  }

  render() {
    const { loading, company_name, company_id, contact_email, companies, invite_link, already_sent, invoice, w2 } = this.state;

    console.log("loading is:", loading)
    return (
      <div className="mt-5 home">
        <h1>Create a Company</h1>
        <form onSubmit={this.onCreateCompany} className="flex-form">
          <input
            className="input"
            name="company_name"
            value={company_name}
            onChange={this.onChange}
            type="text"
            placeholder="Company Name"
          />
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20}}>
            <input
              name="invoice"
              type="checkbox"
              checked={invoice}
              id="invoice"
              onChange={() => this.toggleCheckbox('invoice')}
              style={{marginRight: 10, marginTop: 5}}
            />
            <label htmlFor="set-invoice">Check if company will be invoiced</label>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20}}>
            <input
              name="w2"
              type="checkbox"
              checked={w2}
              id="w2"
              onChange={() => this.toggleCheckbox('w2')}
              style={{marginRight: 10, marginTop: 5}}
            />
            <label htmlFor="set-w2">Check if company is W-2</label>
          </div>
          <h5>Add a logo URL</h5>
          <p>On google images search for "company name" logo png. For the one you like right click and select "Copy image address"</p>
            <input
            name="logo"
            type="text"
            onChange={this.onChange}
            placeholder={"Logo URL"}
            style={{marginBottom: 20}}/>
          {this.state.logo && <img id="target" src={this.state.logo} style={{marginBottom: 20, marginTop: 5}}/>}
          <button type="submit" className="submit-button">
            Create Company
          </button>
        </form>
        <br />
        <br />
        <h1>Invite to company</h1>
        <form onSubmit={this.onInviteUserToCompany} className="inline-form">
          <div style={{display:'flex', flexDirection:'column', flex: '0 1 100%'}}>
          Invite
          <input
            className="input"
            name="contact_email"
            value={contact_email}
            onChange={this.onChange}
            type="text"
            placeholder="Contact Email"
          />
          to
          <input
            className="input"
            name="company_id"
            value={company_id}
            onChange={this.onChange}
            type="text"
            placeholder="Company ID"
          />
          <button type="submit" className="submit-button">
            Invite
          </button>
          <br/>
          {invite_link !=='' &&
          <div style={{display:'flex', flexDirection:'column'}}>
            {already_sent ? <div style={{display:'inline-block', backgroundColor:'#fafafa', padding: 20, margin:20}}>THERE IS ALREADY AN EXISTING INVITE ASSOCIATED WITH THIS EMAIL IN THE COMPANY_INVITE TABLE. We did NOT send another email to the email address you provided. The link will ONLY WORK if the company_invite record lists the intended email and intended company in the same row. If you previously invited this email to a different company the link will NOT allow the user to complete the sign up process. Here's the link: </div>
              : <div style={{display:'inline-block', backgroundColor:'#fafafa', padding: 20, margin:20}}>Invite generated. Link to share with employer:</div>}
              <div style={{display:'inline-block', backgroundColor:'#fafafa', padding: 20, margin:20}}>{invite_link}</div>
          </div>}
          </div>
        </form>
        <br />
        <h1>TODO: Edit Company Info</h1>
        <br />

        <div className="users-table mt-5">
          {loading ? <Loading/> : <></>}
          <h1>Companies</h1>          
          <BootstrapTable striped hover
                          keyField='name' data={ companies } columns={ columns }  />
        </div>
      </div>
    )
  }
}


const columns = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "takePercentageEstimate",
    text: "Take Percentage",
    formatter: (cell, row) => {
      return cell + "%";
    },
  },
  {
    dataField: "chargeUpfront",
    text: "On Invoice?",
    formatter: (cell, row) => {
      return <div>{cell ? "No" : "Yes"}</div>;
    },
  },
  {
    dataField: "invoiceDueDays",
    text: "Invoice Due Days",
    formatter: (cell, row) => {
      return <div>{cell ? cell : "N/A"}</div>;
    },
  },
  {
    dataField: "pendingInvites",
    text: "Pending Invites",
    formatter: (cell, row) => {
      const allInvites = cell.map((invite) => <p>{invite.email}</p>);
      return <div>{allInvites}</div>;
    },
  },
];

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Company);
