import React from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSection from "../Shared/CollapsibleSection";
import CollapsibleSectionTable from "../Shared/CollapsibleSectionTable";

interface Props {
  user: User;
}

const UserCompanyWorkerProfile = ({ user }: Props) => {
  const data = React.useMemo(() => {
    return user.companyWorkerProfiles.map((companyWorkerProfile) => {
      return {
        companyId: companyWorkerProfile.companyId,
        workerId: companyWorkerProfile.workerId,
        favorite: companyWorkerProfile.favorite.toString(),
        hired: companyWorkerProfile.hired.toString(),
        blocked: companyWorkerProfile.blocked.toString(),
        blockedReason: companyWorkerProfile.blockedReason?.toString(),
      };
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Company ID",
        accessor: "companyId",
      },
      {
        Header: "Worker ID",
        accessor: "workerId",
      },
      {
        Header: "Favorite",
        accessor: "favorite",
      },
      {
        Header: "Hired",
        accessor: "hired",
      },
      {
        Header: "Blocked",
        accessor: "blocked",
      },
      {
        Header: "Blocked Reason",
        accessor: "blockedReason",
      },
    ],
    []
  );

  return (
    <CollapsibleSection title="Company Worker Profile">
      {user.companyWorkerProfiles && user.companyWorkerProfiles.length > 0 ? (
        <CollapsibleSectionTable data={data} columns={columns} />
      ) : (
        <p>User has no company worker profiles</p>
      )}
    </CollapsibleSection>
  );
};

export default UserCompanyWorkerProfile;
