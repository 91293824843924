import React, { useEffect, useState } from 'react';
import graphql from '../../../graphql';
import { WorkShift } from '../../Shared/common_defs';
import Loading from '../../Shared/loading';
import { isShiftFull, isShiftInProgress } from '../../utils/general_util';
import ListingInfo from './ListingInfo';

interface Props {
    shift: WorkShift
}

const Listings = ({shift}:Props) => {
    const [fetchingListingsDetail, setFetchingListingsDetail] = useState(false);
    const [listingsDetail, setListingsDetail] = useState({});
    const shiftFull = isShiftFull(listingsDetail);
    const inProgress = isShiftInProgress(listingsDetail);

    useEffect(()=>{
        // componentDidMount - use the info we already asked for in shift query
        // we want to avoid multiple queries on mount
        setListingsDetail(shift);
    },[])

    const listingsQuery = (shiftId) => {
        return `
            admin { 
                shift (shiftId:${shiftId}) {
                    id, startsAt, numWorkersFilled
                    workersNeeded, numCushionWorkersNeeded,
                    listings {id, tier, tierTransitions, numWaitlist },
                    location {address {timezone}},
                }
            }`
    }

    // fetchPay used in actions in child components that require a refresh of the top level pay data
    const fetchListings = () => {
        setFetchingListingsDetail(true)
        graphql(listingsQuery(shift.id)).then(response => {
            const shiftData = response.data.data.admin.shift
            if (shiftData) {
                setListingsDetail(shiftData);
            }
            setFetchingListingsDetail(false);
        }).catch(()=>{
            setFetchingListingsDetail(false);
        })
    }

    if (fetchingListingsDetail) {
        return <Loading type="button" />
    }

    if (!listingsDetail) {
        return <p>Could not find listings info for this shift.</p>
    }

    const toReturn = [];
    for (const listing of shift.listings) {
        toReturn.push(<ListingInfo listing={listing} shift={shift} shiftFull={shiftFull} shiftInProgress={inProgress} fetchListings={fetchListings} />)
    }
    // Returning a single element helps with Typescript.
    return <div>{toReturn}</div>;
}

export default Listings;
