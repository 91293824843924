import React, { useState } from 'react';
import * as moment from "moment-timezone";
import { Listing, Location } from '../../Shared/common_defs';

interface Props {
    listing: Listing,
    location: Location,
}

const ListingTiers = ({ listing, location }: Props) => {
    const [showAll, setShowAll] = useState(false);

    if (!listing || !listing.tierTransitions) return null;

    var jsonReadyString = listing.tierTransitions.replace(/'/g, "\"");
    let obj = JSON.parse(jsonReadyString);
    let returnArr = [];

    for (const key in obj) {
        let displayedKey = <>{key}</>;
        if (key === listing.tier) displayedKey = <b>{key}</b>;
        if (key !== listing.tier && !showAll) continue;
        const timezone = (location && location.address) ? location.address.timezone : moment.tz.guess(true);
        returnArr.push(<p>{displayedKey}: {<p>{moment.tz(obj[key], timezone).format('ddd MMM Do, h:mma zz')}</p>}</p>);
    }

    if (Object.keys(obj).length > 1) returnArr.splice(1, 0, <button className="standard-button" onClick={() => setShowAll(!showAll)} type='button'>{showAll ? 'Hide' : 'Show'} All</button>);

    return (
        <>
            {returnArr.map(elem => <div>{elem}</div>)}
        </>
    );
}

export default ListingTiers;
