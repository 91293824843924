import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationList,
  Conversation,
  Avatar,
  ConversationHeader,
  MessageSeparator,
  Sidebar,
  ExpansionPanel,

  TypingIndicator,
  Search,
} from "@chatscope/chat-ui-kit-react";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import './styles/main.scss';
import {
  getCallList, markChatAsRead,
  MessageThreads,
  sendMessage,
  workerChatMessagesRequest
} from "../../actions/messaging_actions";
import {formatShiftsForSelect, showErrorToast, stripHtml} from "../utils/util";
import {defs} from "../Shared/defs";
import styled from "styled-components";
import {Modal} from "react-bootstrap";
import api from "../../api";
import {useQueryClient} from "react-query";
import Loading from "../Shared/loading";
import {withAuthorization} from "../Session";

const placeholder = require('../../assests/gray_square.png')

const animatedComponents = makeAnimated();

const Messaging = () => {

  const [selectedWorker, setSelectedWorker] = useState(null);
  const [currMessages, setCurrMessages] = useState(null);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [messageInputValue, setMessageInputValue] = useState("");
  const [shiftToMessageMap, setShiftToMessageMap] = useState(null);
  const [showComposeModal, setShowComposeModal] = useState(null);
  const [messageToSend, setMessageToSend] = useState(null);
  const [composeShiftId, setComposeShiftId] = useState(null);
  const [callList, setCallList] = useState(null);
  const [loadingCallList, setLoadingCallList] = useState(null);
  const [locallyMarkedReadList, setLocallyMarkedReadList] = useState([]);
  const [userIds, setUserIds] = useState(null);
  const [loadingNewMessages, setLoadingNewMessages] = useState(false);
  const [sendingThreadMessage, setSendingThreadMessage] = useState(false);
  const [workwhileExperienced, setNewWorker] = useState(false);
  const [companyExperience, setCompanyExperience] = useState(false);
  const [orientationApproved, setOrientationApproved] = useState(null);
  const [listingNotViewed, setListingNotViewed] = useState(false);
  const [distance, setDistance] = useState(false);
  const [checkrCleared, setCheckrCleared] = useState(false);
  const [excludeMessaged, setExcludeMessaged] = useState(false);
  const [onlyListingViewed, setOnlyListingViewed] = useState(false);
  const [searchParam, setSearchParam] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [unreadInput, setUnreadInput] = useState(null)
  const [unread, setUnread] = useState(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    // componentDidMount
  }, []);

  useEffect(() => {
    console.log("useEffet")
    if (callList) {
      setUserIds(filterCallList())
    }
  }, [callList, distance, orientationApproved, companyExperience, workwhileExperienced, 
    listingNotViewed, checkrCleared, excludeMessaged, onlyListingViewed]);

  const onSendMessage = () => {
    // console.log("send message");
    if (!messageInputValue) {
      return;
    }
    const sendable = stripHtml(messageInputValue);
    const lastMessage = currMessages[0];
    setMessageInputValue('');
    setSendingThreadMessage(true);
    sendMessage([selectedWorker.id], sendable).then(() => {
      workerChatMessagesRequest(selectedWorker.id).then(res => {
        setCurrMessages(res.data.data.admin.workerChatMessages.items.reverse());
        setSendingThreadMessage(false);
        // generateShiftMap(res.data.data.admin.workerChatMessages.items);
      });
    });
  };

  /*
  const generateShiftMap = (messages) => {
    let fakeIdCounter = 1;
    if (messages) {
      console.log("curr messages: ", messages);
        const newMap = {};
      messages.forEach((message) => {
          if (!message.shift) {
            console.log("ok so no shift: ", message.shift);
            // this is janky but create "fake" shifts with date of message as start date and then add it with a fake ID.
            let mutatedMessage = {...message};
            mutatedMessage.shift = {id: fakeIdCounter, createdAt: message.createdAt, isFake: true};
            newMap[fakeIdCounter] = [mutatedMessage];
            console.log("and new map now: ", newMap);
            fakeIdCounter++;
          } else {
            if (message.shift.id in newMap) {
              const newList = [...newMap[message.shift.id]];
              newList.push(message);
              newMap[message.shift.id] = newList;
            } else {
              newMap[message.shift.id] = [message];
            }
          }
        });
        // sort by created at
        Object.keys(newMap).forEach((key) => {
          newMap[key].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
        console.log("shift to mesage map:::::::: ", newMap)
        setShiftToMessageMap(newMap);
    }
  };
  */

  const handleFilterChange = (selectedOption, actionMeta) => {
    setSelectedShiftId(selectedOption ? selectedOption.value : null);
  };

  const getConversationWorker = (conversation) => {
    // console.log("return worker: ", conversation);
    if (conversation.direction === 'a2w') {
      return conversation.toUser;
    }
    return conversation.fromUser;
  };

  const onWorkerSelected = (item) => {
    const worker = getConversationWorker(item)
    setSelectedWorker(worker);
    setLoadingNewMessages(true);
    workerChatMessagesRequest(worker.id).then(res => {
      setCurrMessages(res.data.data.admin.workerChatMessages.items.reverse());
      // generateShiftMap(res.data.data.admin.workerChatMessages.items);
      markChatAsRead([worker.id]);
      markReadLocally(worker.id);
      setLoadingNewMessages(false);
    });
  };

  const markReadLocally = (workerId) => {
    let newList = [...locallyMarkedReadList];
    newList.push(workerId);
    setLocallyMarkedReadList(newList);
    console.log("adding local read for: ", workerId, newList);
  };

  const getThreadsToUse = (items) => {
    if (selectedShiftId) {
      const toReturn = [];
      items.forEach((item) => {
        if (item.shift) {
          console.log("comparing vcalues: ", item, selectedShiftId)
          if (String(item.shift.id) === String(selectedShiftId)) {
            toReturn.push(item);
          }
        }
      });
      console.log("ok about to return: ", toReturn);
      return toReturn;
    }
    return items;
  };

  const canMessageOnCurrentlySelected = () => {
    return true;
  }

  const onLoadCallList = (event) => {
    if (!composeShiftId) {
      alert("need a shift id")
      return
    }

    // get call list
    setLoadingCallList(true);
    getCallList(composeShiftId).then(res => {
      setCallList(res.data)
      setLoadingCallList(false)
    });

    return
  }

  const filterCallList = () => {
    return callList.filter(item => {
      if (companyExperience && item.shifts_at_company == 0)
        return false

      if (workwhileExperienced && item.tier_experienced == false)
        return false
      
      if (listingNotViewed && item.listing_viewed == 'Y') 
        return false

      if (excludeMessaged && item.messaged == 'Y')
        return false

      if (checkrCleared && !item.checkr_status)
        return false

      if (onlyListingViewed && item.listing_viewed == 'N') 
        return false
      
      if (orientationApproved && item.orientation_status != 'approved') 
        return false

      // if they're farter away than what we want, pass
      if (distance && item.distance > distance)
        return false
      else
        return true
    }).map(item => item.id)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    // console.log("we in here?????? ")
    if (!messageToSend) {
      showErrorToast("Add a message");
      return;
    }

    let userIdsArray = typeof userIds == 'string' ? userIds.split(',') : userIds
    sendMessage(userIdsArray, messageToSend, composeShiftId).then(() => {
      console.log("successfully sent");
      setShowComposeModal(false);
      queryClient.invalidateQueries('threads');
      setLocallyMarkedReadList([]);
      setSelectedWorker(null);
    })
    /*
    api.post(`/admin/user/${currentUserId}/note`, {
      note: currentNote
    }).then(() => {
      this.setState({showNoteModal: false, currentNote: null, currentUserId: null})
    })
    */
  };

  return (
    <Container>
      <MessageThreads searchParam={searchParam} unread={unread}>
        {({ isLoading, isFetching, data }) => {
          console.log("data is: ", data);
          console.log('isFetching: ', isFetching)
          const { admin } = data;
          if (!admin) {
            return null;
          }
          if (!admin.latestChatMessages) {
            return null;
          }
          if (!admin.latestChatMessages.items || admin.latestChatMessages.items.length === 0) {
            console.log("gotta return this");
            return (
              <div>
                <h3>No messages. If you were searching, please refresh.</h3>
              </div>
            );
          }
          // only add filters for shifts > now or just ended.
          const filterBound = moment().subtract(2, 'hour');
          const filterShifts = [];
          let shiftToThreadMap = {};

          admin.latestChatMessages.items.forEach((item) => {
            if (!item.shift) {
              // for null shift case just create a fake shift id, say 1 and then use it.
              // console.log("shift to thread map: ", shiftToThreadMap);
              const key = '1'
              if (key in shiftToThreadMap) {
                const newList = [...shiftToThreadMap[key].threads];
                newList.push(item);
                shiftToThreadMap[key] = { shift: null, threads: newList };
              } else {
                shiftToThreadMap[key] = { shift: null, threads: [item] };
              }
            } else {
              // console.log("shfit to thread map: ", shiftToThreadMap);
              if (item.shift.id in shiftToThreadMap) {
                const newList = [...shiftToThreadMap[item.shift.id].threads];
                newList.push(item);
                shiftToThreadMap[item.shift.id] = {shift: item.shift, threads: newList};
              } else {
                shiftToThreadMap[item.shift.id] = {shift: item.shift, threads: [item]};
                if (moment(item.shift.startsAt) > filterBound) {
                  filterShifts.push(item.shift);
                }
              }
            }
          });
          const shiftOptions = formatShiftsForSelect(filterShifts);
          /* Disabling default select for now
          if (!selectedWorker && admin.latestChatMessages.items && admin.latestChatMessages.items.length > 0) {
            onWorkerSelected(admin.latestChatMessages.items[0]);
          }
          */
          // console.log("is it in? ", 12483 in locallyMarkedReadList)
          return (
            <MainContainer responsive>
              <Sidebar position="left" scrollable={false}>
                <div style={{paddingBottom: 20}}>
                  <a style={{color: 'blue'}} onClick={()=>alert('search query in the format ${search_type}: ${query_param} e.g. worker_id: 3948, worker_name: Michael Scott, sender_id: 964')}>help</a>
                  <div style={{flexDirection: 'row', flex: 1}}>
                    <input style={{flex: 1, width: '100%'}} name="search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder={'e.g. "worker_id:964, sender_id:4"'}/>
                  </div>
                  <div style={{flexDirection: 'row', flex: 1}}>
                    <span>Show unread only</span>
                    <input
                      className="input"
                      type="checkbox"
                      checked={unreadInput}
                      id="unread"
                      onChange={() => setUnreadInput(!unreadInput)}
                    />
                  </div>
                  <ActionButton style={{width: '90%'}} onClick={()=>{
                    setSearchParam(searchInput);
                    setUnread(unreadInput);
                  }}>Search</ActionButton>
                  {isFetching && <div><Loading /></div>}
                </div>
                {shiftToThreadMap && (
                <Select
                  isSearchable
                  isClearable
                  options={shiftOptions}
                  components={animatedComponents}
                  styles={customStyles}
                  name="shift"
                  placeholder="Filter By Shift"
                  onChange={handleFilterChange}
                />
                )}
                <ActionButton onClick={() => setShowComposeModal(true)}>Compose message</ActionButton>
                {admin.latestChatMessages.items && (
                <ConversationList>
                  {getThreadsToUse(admin.latestChatMessages.items).map((item) => (
                    <Conversation name={getConversationWorker(item).name} lastSenderName={item.fromUser.name} info={item.message} unreadCnt={String(getConversationWorker(item).id) in locallyMarkedReadList ? 0 : item.unreadCount} onClick={() => { onWorkerSelected(item); }} active={selectedWorker && getConversationWorker(item).id === selectedWorker.id}>
                      <Avatar src={getConversationWorker(item).profilePicUrl ? getConversationWorker(item).profilePicUrl : placeholder} name={item.fromUser.name} />
                    </Conversation>
                  ))}
                </ConversationList>
                )}

              </Sidebar>

              {currMessages && !loadingNewMessages && (
              <ChatContainer>
                <ConversationHeader>
                  <ConversationHeader.Back />
                  <Avatar src={selectedWorker.profilePicUrl ? selectedWorker.profilePicUrl : placeholder} name={selectedWorker.name} />
                  <ConversationHeader.Content >
                    <Name>{selectedWorker.name}</Name>
                    <Context>ID: {selectedWorker.id} Phone: {selectedWorker.phoneNumber} Orientation status: {selectedWorker.onboardingStatus.orientationStatus} . Checkr Status: {selectedWorker.onboardingStatus.checkrStatus}</Context>
                  </ConversationHeader.Content>
                </ConversationHeader>
                {/*
                <MessageList>

                  {Object.keys(shiftToMessageMap).map((key) => {
                    console.log("shift to mesage map ij jere: ", key)
                    const messages = shiftToMessageMap[key];
                    const toReturn = [];
                    if (!messages[0].shift.isFake) {
                      toReturn.push(<MessageSeparator content={`${moment(messages[0].shift.startsAt).format('MMMM Do')} · ${messages[0].shift.position.name} · ${messages[0].shift.location.name}`} />);
                    } else {
                      toReturn.push(<MessageSeparator content={`Shift less message sent on ${moment(messages[0].createdAt).format('MMMM Do')}`}/>)
                    }

                    console.log("for this list: ", key, messages)
                    toReturn.push(messages.map((message) => (
                      <Message model={{
                        message: message.message,
                        sentTime: moment(message.createdAt).fromNow(),
                        sender: message.fromUser.name,
                        direction: message.direction === 'w2a' ? "incoming" : "outgoing",
                        position: "single",
                      }}
                      >
                        <Avatar src={message.fromUser.profilePicUrl} name={message.fromUser.name} />
                      </Message>
                    )));
                    return toReturn;
                  })}

                </MessageList>*/}
                <MessageList>

                {currMessages.map((message) => (
                  <Message model={{
                    message: message.message,
                    sentTime: moment(message.createdAt).fromNow(),
                    sender: message.fromUser.name,
                    direction: message.direction === 'w2a' ? "incoming" : "outgoing",
                    position: "single",
                  }}
                  >
                    <Message.Header sentTime={moment(message.createdAt).fromNow()} />
                    <Avatar src={message.fromUser.profilePicUrl ? message.fromUser.profilePicUrl : placeholder} name={message.fromUser.name} />
                  </Message>
                ))}
                  {sendingThreadMessage && <div className="space-below"><Loading type="button"/></div>}

                {!canMessageOnCurrentlySelected() && <MessageSeparator content={"Can only message workers when there is an active shift"} />}
                </MessageList>
                {canMessageOnCurrentlySelected() && <MessageInput placeholder="Type message here" value={messageInputValue} onChange={(val) => setMessageInputValue(val)} onSend={() => onSendMessage()} />}

              </ChatContainer>
              )}
              {loadingNewMessages && <div className="centered-loading"><Loading /></div>}
            </MainContainer>
          );
        }}
      </MessageThreads>
      <Modal
        show={showComposeModal} onHide={() => setShowComposeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send message for a shift</Modal.Title>
        </Modal.Header>
        <div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{margin:'5px 0 0 0'}}>
              <label>Shift ID&nbsp;</label>
              <input
                className="input"
                name="shiftId"
                value={composeShiftId}
                onChange={(e) => setComposeShiftId(e.target.value)}
                type="text"
                placeholder="Shift Id"
              />
              <button type="submit" className="ml-2" style={{width:'150px', margin:'0 0 10px 0'}} onClick={onLoadCallList} disabled={loadingCallList}>
                Load call list
              </button>
            </div>
            <label>
              <input
                type="checkbox"
                checked={workwhileExperienced}
                onChange={() => setNewWorker(!workwhileExperienced)}
              />&nbsp;
              Workwhile Experienced&nbsp;&nbsp;
              <input
                type="checkbox"
                checked={companyExperience}
                onChange={() => setCompanyExperience(!companyExperience)}
              />&nbsp;
              Company experience&nbsp;&nbsp;
            </label>
            <label>
              <input
                type="checkbox"
                checked={listingNotViewed}
                onChange={() => setListingNotViewed(!listingNotViewed)}
              />&nbsp;
                Listing not viewed&nbsp;&nbsp;
              <input
                type="checkbox"
                checked={checkrCleared}
                onChange={() => setCheckrCleared(!checkrCleared)}
              />&nbsp;
              Checkr cleared&nbsp;&nbsp;
              <input
                type="checkbox"
                checked={excludeMessaged}
                onChange={() => setExcludeMessaged(!excludeMessaged)}
              />&nbsp;
              Exclude messaged&nbsp;&nbsp;
            </label>
            <label>
              <input
                type="checkbox"
                checked={orientationApproved}
                onChange={() => setOrientationApproved(!orientationApproved)}
              />&nbsp;
              Orientation approved&nbsp;&nbsp;
              <input
                type="checkbox"
                checked={onlyListingViewed}
                onChange={() => setOnlyListingViewed(!onlyListingViewed)}
              />&nbsp;
              Listing Viewed&nbsp;&nbsp;
            </label>
            <label>
              Distance&nbsp;
              <input type="number" step="0.01" value={distance} onChange={(e) => setDistance(e.target.value)}/>
            </label>
            <label>User IDs</label>
            <input
              className="input"
              name="userIds"
              value={userIds}
              onChange={(e) => setUserIds(e.target.value)}
              type="text"
              placeholder="User Ids Comma Separated"
            />
          </div>
          {loadingCallList && <div><Loading /><p>Loading call list. Hang on it takes a few mins.</p></div>}
          {callList && <CallListText>{userIds ? userIds.length : 0} workers on the current Call List</CallListText>}
          <label>Message</label>
          <textarea
            className="phone-interview-input"
            name="messageToSend"
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
            type="text"
            placeholder="Use $first_name to add name as variable"
          />
          <button type="submit" className="ml-2" onClick={onSubmit}>
            {/*callList ? "Send Message" : "Get Call list"*/ "Send message"}
          </button>
        </div>
      </Modal>
    </Container>
  );
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginLeft: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
  }),
};

const Container = styled.div`
height: ${window.innerHeight - 50}px;
padding-top: 50px;
`;

const CallListView = styled.div`
height: 300px; 
overflow-y: 'scroll'
`;

const CallListRow = styled.div`
display: flex;
flex-direction: row;
width: 200px;
justify-content: space-between;
`;

const CallListText = styled.p`
margin-top: 20px;
margin-bottom: 20px;
font-size: 20px;
`;

const ActionButton = styled.button`
margin-left: 20px;
margin-right: 20px;
margin-bottom: 20px;
margin-top: 10px;
`

const Name = styled.p`
margin-bottom: 8px;
font-size: 20px;
font-weight: bold;
`;

const Context = styled.p`
margin-bottom: 0px;
font-size: 12px;
`;

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Messaging);
