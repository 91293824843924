import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationList,
    Avatar,
    ConversationHeader,
    MessageSeparator,
    Sidebar,
  } from "@chatscope/chat-ui-kit-react";
import { markChatAsRead, sendMessage, threadsRequest, workerChatMessagesRequest } from '../../actions/messaging_actions';
import {withAuthorization} from "../Session";
import { defs } from '../Shared/defs';
import moment from 'moment';
import Loading from '../Shared/loading';
import { stripHtml } from '../utils/util';
import ComposeMessageModal from './ComposeMessageModal';
import FilterMessagesModal from './FilterMessagesModal';
import { RiFilter2Fill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import ConversationAvatar from './ConversationAvatar';
import ConversationItem from './ConversationItem';
import ConversationThumbnail from './ConversationThumbnail';
import { device } from '../Shared/device';
import './messaging_custom_style.scss';

const placeholder = require('../../assests/gray_square.png')

interface RequestProps {
    refreshData?: boolean,
}

const Messaging = () => {
    const [cursor, setCursor] = useState(null);
    const [requestingThreads, setRequestingThreads] = useState(false);
    const [threads, setThreads] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [sidebarStyle, setSidebarStyle] = useState<object>({flexBasis: '1%'});
    const [chatContainerStyle, setChatContainerStyle] = useState({});
    const [conversationContentStyle, setConversationContentStyle] = useState({});
    const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
    const [locallyMarkedReadList, setLocallyMarkedReadList] = useState([]);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [currMessages, setCurrMessages] = useState(null);
    const [loadingNewMessages, setLoadingNewMessages] = useState(false);
    const [sendingThreadMessage, setSendingThreadMessage] = useState(false);
    const [messageInputValue, setMessageInputValue] = useState("");
    const [showComposeModal, setShowComposeModal] = useState(false);
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [unread, setUnread] = useState(null)
    const [automated, setAutomated] = useState(null)
    const [manual, setManual] = useState(null)
    const [selectedShift, setSelectedShift] = useState(null);

    useEffect(() => {
        // componentDidMount
        requestThreads({refreshData: true});
    }, []);

    const requestThreads = ({
        refreshData
    }:RequestProps) => {
        setRequestingThreads(true);
        let formattedCursor = cursor;
        if (refreshData) {
            setCursor(null);
            formattedCursor = null;
            setThreads([]);
        }
        let shiftId = null;
        if (selectedShift) {
            shiftId = selectedShift.value;
        }
        threadsRequest({searchParam, unread, cursor: formattedCursor, shiftId, automated, manual}).then(response => {
            console.log('response: ', response )
            if (response.data.data.admin.latestChatMessages) {
                if (response.data.data.admin.latestChatMessages.items && response.data.data.admin.latestChatMessages.items.length > 0) {
                    if (!formattedCursor) { // initial page, replace existing messages list
                        setThreads(response.data.data.admin.latestChatMessages.items);
                    } else { // subsequent page, so append
                        setThreads(threads.concat(response.data.data.admin.latestChatMessages.items));
                    }
                }
                setCursor(response.data.data.admin.latestChatMessages.cursor);
            }
            setRequestingThreads(false);
        }).catch((error)=>{
            setRequestingThreads(false);
        })
    }
  
    const handleBackClick = () => setSidebarVisible(!sidebarVisible);

    const canMessageOnCurrentlySelected = () => {
        return true;
    }
  
    const handleConversationClick = useCallback(() => {
      if (sidebarVisible) {
        setSidebarVisible(false);
      }
    }, [sidebarVisible, setSidebarVisible]);

    const getConversationWorker = (conversation) => {
        // console.log("return worker: ", conversation);
        if (conversation.direction === 'a2w') {
          return conversation.toUser;
        }
        return conversation.fromUser;
    };

    const markReadLocally = (workerId) => {
        let newList = [...locallyMarkedReadList];
        newList.push(workerId);
        setLocallyMarkedReadList(newList);
        console.log("adding local read for: ", workerId, newList);
    };

    const onWorkerSelected = (item) => {
        const worker = getConversationWorker(item)
        setSelectedWorker(worker);
        setLoadingNewMessages(true);
        workerChatMessagesRequest(worker.id).then(res => {
          setCurrMessages(res.data.data.admin.workerChatMessages.items.reverse());
          // generateShiftMap(res.data.data.admin.workerChatMessages.items);
          markChatAsRead([worker.id]);
          markReadLocally(worker.id);
          setLoadingNewMessages(false);
        });
    };

    const onSendMessage = () => {
        // console.log("send message");
        if (!messageInputValue) {
          return;
        }
        const sendable = stripHtml(messageInputValue);
        const lastMessage = currMessages[0];
        setMessageInputValue('');
        setSendingThreadMessage(true);
        sendMessage([selectedWorker.id], sendable).then(() => {
          workerChatMessagesRequest(selectedWorker.id).then(res => {
            setCurrMessages(res.data.data.admin.workerChatMessages.items.reverse());
            setSendingThreadMessage(false);
            // generateShiftMap(res.data.data.admin.workerChatMessages.items);
          });
        });
    };

    const handleFilterChange = (selectedOption) => {
        setSelectedShift(selectedOption);
    };

    // -- manage layout --
    useEffect(() => {
      if (sidebarVisible) {
        setSidebarStyle({
          display: "flex",
          width: "100%",
          maxWidth: "100%"
        });
        setConversationContentStyle({
          display: "flex"
        });
        setConversationAvatarStyle({
          marginRight: "1em"
        });
        setChatContainerStyle({
          display: "none"
        });
      } else {
        setSidebarStyle({});
        setConversationContentStyle({});
        setConversationAvatarStyle({});
        setChatContainerStyle({});
      }
    }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);

    // RENDER
    return (
        <Container>
            <MainContainer>
                <Sidebar position="left" scrollable={false} className="custom-sidebar" style={sidebarStyle}>
                    <SidebarHeader>
                        <SidebarHeaderActions>
                            <SidebarActionsButton onClick={() => setShowComposeModal(true)}>
                                <MdEdit />
                            </SidebarActionsButton>
                            <SidebarActionsButton onClick={() => setShowFiltersModal(true)}>
                                {((searchParam && searchParam !== '') || unread || selectedShift || automated || manual) && (
                                    <div style={{position:'absolute', backgroundColor: 'red', width: 15, height: 15, borderRadius: 15, right: -5, top: 0}}></div>
                                )}
                                <RiFilter2Fill />
                            </SidebarActionsButton>
                        </SidebarHeaderActions>
                    </SidebarHeader>
                    <ConversationList
                        loading={requestingThreads && threads.length === 0}
                        loadingMore={requestingThreads && threads.length > 0}
                    >
                        {!requestingThreads && (!threads || threads.length === 0) && (
                            <div>
                                <h3>No messages found.</h3>
                            </div>
                        )}
                        {threads.map((item) => {
                            return <ConversationItem key={getConversationWorker(item).id} onClick={() => { onWorkerSelected(item); }} active={selectedWorker && getConversationWorker(item).id === selectedWorker.id}>
                                <ConversationAvatar 
                                    src={getConversationWorker(item).profilePicUrl ? getConversationWorker(item).profilePicUrl : placeholder} 
                                    name={item.fromUser.name} 
                                    unreadCount={String(getConversationWorker(item).id) in locallyMarkedReadList ? 0 : item.unreadCount}
                                    companyLogoUrl={item?.shift?.company?.logoUrl}
                                />
                                <ConversationThumbnail sentAt={item?.createdAt} shift={item?.shift} name={getConversationWorker(item).name} lastSenderName={item.fromUser.name} message={item.message} />
                            </ConversationItem>
                        })}
                        {!requestingThreads && cursor && <button onClick={() => requestThreads({refreshData: false})}>Load More</button>}
                    </ConversationList>
                </Sidebar>
                {!selectedWorker && (
                    <div style={{display:'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', padding: defs.marginS}}>
                        <h6 style={{textAlign: 'center'}}>
                            “Kind words can be short and easy to speak, but their echoes are truly endless.”
                        </h6>
                        <div>
                            <p>Mother Teresa</p>
                        </div>

                    </div>
                )}
                {selectedWorker && <ChatContainer style={chatContainerStyle}>
                   <ConversationHeader>
                        <Avatar src={selectedWorker.profilePicUrl ? selectedWorker.profilePicUrl : placeholder} name={selectedWorker.name} />
                        <ConversationHeader.Content >
                            <Name>{selectedWorker.name}</Name>
                            <Context>ID: {selectedWorker.id} Phone: {selectedWorker.phoneNumber} Orientation status: {selectedWorker.onboardingStatus.orientationStatus} . Checkr Status: {selectedWorker.onboardingStatus.checkrStatus}</Context>
                        </ConversationHeader.Content>
                    </ConversationHeader>
                    <MessageList>
                        {currMessages && currMessages.map((message) => (
                            <Message model={{
                                message: message.message,
                                sentTime: moment(message.createdAt).fromNow(),
                                sender: message.fromUser.name,
                                direction: message.direction === 'w2a' ? "incoming" : "outgoing",
                                position: "single",
                            }}
                            >
                                <Message.Header sentTime={moment(message.createdAt).fromNow()} />
                                <Avatar src={message.fromUser.profilePicUrl ? message.fromUser.profilePicUrl : placeholder} name={message.fromUser.name} />
                            </Message>
                        ))}
                        {sendingThreadMessage && <div className="space-below"><Loading type="button"/></div>}
                        {!canMessageOnCurrentlySelected() && <MessageSeparator content={"Can only message workers when there is an active shift"} />}
                    </MessageList>
                    {canMessageOnCurrentlySelected() && <MessageInput placeholder="Type message here" value={messageInputValue} onChange={(val) => setMessageInputValue(val)} onSend={() => onSendMessage()} />}
                </ChatContainer>}
            </MainContainer>

            <ComposeMessageModal 
                showModal={showComposeModal}
                hideModal={() => setShowComposeModal(false)}
                submitMessageCallback={() => {
                    setShowComposeModal(false);
                    setLocallyMarkedReadList([]);
                    setSelectedWorker(null);
                    requestThreads({refreshData: true});
                }}
            />

            <FilterMessagesModal 
                showModal={showFiltersModal}
                hideModal={() => setShowFiltersModal(false)}
                submitFiltersCallback={() => requestThreads({refreshData:true})}
                searchParam={searchParam}
                unread={unread}
                automated={automated}
                manual={manual}
                setSearchInput={(value) => setSearchParam(value)}
                setUnreadInput={(value) => setUnread(value)}
                setAutomatedInput={(value) => setAutomated(value)}
                setManualInput={(value) => setManual(value)}
                selectedShift={selectedShift}
                handleFilterChange={handleFilterChange}
            />
        </Container>
    );
};

const Container = styled.div`
    height: ${window.innerHeight - 50}px;
    padding-top: 18px;
`;

const SidebarHeader = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const SidebarHeaderActions = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @media ${device.smallerThanMobileL}{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const SidebarActionsButton = styled.button`
    margin: ${defs.marginXS};
    height: 40px;
    width: 40px;
    border-radius: 40px;
    position: relative;
`;

const Name = styled.p`
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
`;

const Context = styled.p`
    margin-bottom: 0px;
    font-size: 12px;
`;

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Messaging);
