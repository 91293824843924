import React, { useState } from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSectionTable from "../Shared/CollapsibleSectionTable";
import * as moment from "moment-timezone";
import StatusSetter from "../Shared/StatusSetter";
import api from "../../api";
import CollapsibleSection from "../Shared/CollapsibleSection";

interface Props {
  user: User;
  refreshData: (e?: any) => void;
  defaultOpen: boolean;
}

const checkrOptions = [
  { label: "manually_clear", value: "manually_clear" },
  { label: "pending", value: "pending" },
  { label: "consider", value: "consider" },
  { label: "clear", value: "clear" },
  { label: "suspended", value: "suspended" },
  { label: "failed", value: "failed" },
];

const UserCheckrVerification = ({ user, refreshData, defaultOpen }: Props) => {
  const _timezone = user.address?.timezone || moment.tz.guess(true);
  const _dateTimeSubmittedFormat = "YYYY-MM-DD h:mma z";

  const [newCheckrStatus, setNewCheckrStatus] = useState("manually_clear"); // input defaults to manually_clear
  const [submittingCheckrStatus, setSubmittingCheckrStatus] = useState(false);
  const [newCheckrMvrStatus, setNewCheckrMvrStatus] =
    useState("manually_clear"); // input defaults to manually_clear
  const [submittingCheckrMvrStatus, setSubmittingCheckrMvrStatus] =
    useState(false);

  const data = React.useMemo(
    () => [
      {
        key: "checkr_status",
        value: user.checkrStatus || "N/A",
        link: user.checkrStatus
          ? `https://dashboard.checkr.com/candidates/${user.checkrId}`
          : null,
      },
      {
        key: "checkr_submitted_at",
        value: user.onboardingStatus?.checkrSubmitted
          ? moment
              .tz(user.onboardingStatus?.checkrMvrSubmitted, _timezone)
              .format(_dateTimeSubmittedFormat)
          : "N/A",
      },
      {
        key: "checkr_mvr_status",
        value: user.checkrMvrStatus || "N/A",
        link: user.checkrMvrStatus
          ? `https://dashboard.checkr.com/candidates/${user.checkrId}`
          : null,
      },
      {
        key: "checkr_mvr_submitted_at",
        value: user.onboardingStatus?.checkrMvrSubmitted
          ? moment
              .tz(user.onboardingStatus?.checkrMvrSubmitted, _timezone)
              .format(_dateTimeSubmittedFormat)
          : "N/A",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: "key",
      },
      {
        accessor: "value",
      },
    ],
    []
  );

  const submitCheckrStatusChange = () => {
    setSubmittingCheckrStatus(true);
    const r = window.prompt(
      `You are about to set USER(${user.id}) checkr status to: ${newCheckrStatus}. To execute this action, please press Ok.`
    );
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/checkr`, {
          type: "checkr",
          status: newCheckrStatus,
          reason: r,
        })
        .then((response) => {
          alert(`Checkr Status is now ${newCheckrStatus}`);
          setSubmittingCheckrStatus(false);
          refreshData();
        })
        .catch((error) => {
          console.log("ERROR OCCURED: ", error);
          setSubmittingCheckrStatus(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingCheckrStatus(false);
    }
    return;
  };

  const submitCheckrMvrStatusChange = () => {
    setSubmittingCheckrMvrStatus(true);
    const r = window.prompt(
      `You are about to set USER(${user.id}) checkr mvr status to: ${newCheckrMvrStatus}. To execute this action, please press Ok.`
    );
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/checkr`, {
          type: "checkr_mvr",
          status: newCheckrMvrStatus,
          reason: r,
        })
        .then((response) => {
          alert(`CheckrMvrStatus is now ${newCheckrMvrStatus}`);
          setSubmittingCheckrMvrStatus(false);
          refreshData();
        })
        .catch((error) => {
          console.log("ERROR OCCURED: ", error);
          setSubmittingCheckrMvrStatus(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingCheckrMvrStatus(false);
    }
    return;
  };

  const handleChange = (event) => {
    console.log("name: ", event.target.name);
    console.log("value: ", event.target.value);
    if (event.target.name === "newCheckrStatus") {
      setNewCheckrStatus(event.target.value);
    }
    if (event.target.name === "newCheckrMvrStatus") {
      setNewCheckrMvrStatus(event.target.value);
    }
  };

  return (
    <>
      <CollapsibleSection title="Checkr Details" defaultOpen={defaultOpen}>
        <CollapsibleSectionTable data={data} columns={columns} />
      </CollapsibleSection>
      <StatusSetter
        label="Set Checkr Status"
        name="newCheckrStatus"
        value={newCheckrStatus}
        handleChange={(e) => handleChange(e)}
        isLoading={submittingCheckrStatus}
        submitStatusChange={submitCheckrStatusChange}
        options={checkrOptions}
        buttonType="confirm"
      />
      <StatusSetter
        label="Set Checkr MVR Status"
        name="newCheckrMvrStatus"
        value={newCheckrMvrStatus}
        handleChange={(e) => handleChange(e)}
        isLoading={submittingCheckrMvrStatus}
        submitStatusChange={submitCheckrMvrStatusChange}
        options={checkrOptions}
        buttonType="confirm"
      />
    </>
  );
};

export default UserCheckrVerification;
