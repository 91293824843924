import React, { useEffect, useState } from 'react';
import {sharedStyles} from '../Shared/sharedStyles';
import { MdExpandMore, MdExpandLess} from "react-icons/md";


interface Props {
    title: string,
    children: React.ReactNode,
    containerStyles?: object,
    defaultOpen?: boolean,
    titleStyles?: object,
}

const CollapsibleSection = ({title, children, containerStyles, defaultOpen, titleStyles}:Props) => {
    const [showAll, setShowAll] = useState(false);

    useEffect(()=>{
        if (defaultOpen) {
            setShowAll(true);
        }
    }, [])

    return (
        <div style={{...sharedStyles.collapsibleSectionContainer, ...containerStyles}}>
            <div style={sharedStyles.collapsibleSection}>
                <div style={{...sharedStyles.collapsibleSectionTitleRow, ...titleStyles}} onClick={()=>setShowAll(!showAll)}>
                    <div><b>{title}</b></div>
                    <div style={sharedStyles.collapsibleSectionTitleRowIcon}>{showAll ? <MdExpandLess /> : <MdExpandMore />}</div>
                </div>
                {showAll && children}
            </div>
        </div>
    );
}

export default CollapsibleSection;