import React from "react";

import { withAuthorization } from "../Session";
import TipFileUpload from "./TipFileUpload";

const TipsContainer = () => (
  <div className="home">
    <div>
      <h1>Upload Tip Data</h1>
      <li>
        Your CSV must include 3 columns: with the exact names amount and
        phone_number.{" "}
      </li>
      <li>
        Additional columns will be disregarded. The phone_number can be in any
        format.
      </li>
      <li>The amount must be in the form 1.00 for a $1 tip.</li>
      <li>Please remove any currency symbols.</li>
      <br />
      <br />
      <TipFileUpload />
    </div>
  </div>
);

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(TipsContainer);
