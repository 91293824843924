import React, { useEffect, useState } from 'react';
import '../Home/home.css';
import { withAuthorization } from "../Session";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import graphql from "../../graphql";
import {  getRowStyles } from "../utils/util";
import {  getShiftColumns, shiftsQuery } from './shifts';
import Loading from '../Shared/loading';
import {useHistory, useLocation } from "react-router";


const SingleShift = () => {
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const [fetching, setFetching] = useState(false);
    const [shift, setShift] = useState(null);
    const [columns, setColumns] = useState(getShiftColumns('upcoming', null));
    const [inputShiftId, setInputShiftId] = useState('');

    useEffect(() => {
        const shiftIdFromUrl = searchParams.get('shiftId');
        if (shiftIdFromUrl) {
            fetchShift(Number(shiftIdFromUrl));
        }
    }, [inputShiftId]);

    const fetchShift = (shiftId: number) => {
        console.log('fetching shifts')
        setFetching(true);
        graphql(shiftsQuery({ shiftId })).then(response => {
            setShift(response.data.data.admin.shifts[0]);
            setFetching(false);
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputShiftId(e.target.value);
    };

    const handleSubmit = () => {
        const shiftId = Number(inputShiftId);
        fetchShift(shiftId);
        history.push({ search: `shiftId=${shiftId}` });
    };

    return (
        <>
            <h3>Shift Information</h3>
            <input type="text" placeholder="Shift ID" value={inputShiftId} onChange={handleInputChange} />
            <button type="submit" className='submit-button-smaller' onClick={handleSubmit}>
                Load Shifts
            </button>
            {fetching && <Loading />}
            {shift && <BootstrapTable striped hover keyField='id' data={[shift]} columns={columns} rowStyle={getRowStyles} />}
        </>
    );
};


const condition = authUser => !!authUser;
export default withAuthorization(condition)(SingleShift);
