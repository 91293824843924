import React from 'react';

import { withAuthorization } from '../Session';
import Push from "../features/push";
import './home.css'
import PaymentReview from "../features/payment_review";

const HomePage = () => (
  <div className="home">
    <Push />
    <br/>
    <br/>
    <hr />
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
