import React from "react";
import api from "../../api";
import Moment from "react-moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "../Home/home.css";
import graphql from "../../graphql";
import { reviewQuery } from "../../actions/review_actions";
import moment from "moment";
import WorkerDetail from "../Shifts/Workers/WorkerDetail";
import { act } from "@testing-library/react";

class PaymentReview extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      shifts: [],
      approved: {},
    };
  }

  componentDidMount() {
    this.fetchShifts();
  }

  fetchShifts() {
    graphql(reviewQuery()).then((response) => {
      // filter for shifts that have left_early
      const sortedList = response.data.data.admin.leftEarlyWork.sort((a, b) =>
        a.completedAt > b.completedAt ? 1 : -1
      );
      this.setState({
        shifts: sortedList,
      });
    });
  }

  toggleCheckbox(key) {
    const { approved } = this.state;
    let newApproved = { ...approved };
    if (key in approved) {
      delete newApproved[key];
    } else {
      newApproved[key] = true;
    }
    this.setState({
      approved: newApproved,
    });
    console.log(key, newApproved);
  }

  onSubmit = (event) => {
    const { approved } = this.state;

    event.preventDefault();

    if (Object.keys(approved).length === 0) {
      return;
    }

    api
      .post("/admin/shifts/approve", {
        shift_ids: Object.keys(approved),
      })
      .then((response) => {
        this.setState({
          approved: {},
        });
        this.fetchShifts();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onActionSuccess = (action) => {
    if (action === "mark_approved") {
      this.fetchShifts();
    }
  };

  removeShiftFromReviewList = (i) => {
    const newShifts = this.state.shifts;
    newShifts.splice(i, 1);
    this.setState({
      shifts: newShifts,
    });
  };

  render() {
    const { shifts, approved } = this.state;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        headerStyle: { width: "4em" },
      },
      {
        dataField: "worker",
        text: "Worker",
        sort: true,
        formatter: (cell, row) => cell.name + " " + cell.id,
      },
      {
        dataField: "shift",
        text: "Shift Info",
        formatter: (cell) => {
          return (
            <>
              <p>
                {cell.id} at {cell.company.name}
              </p>
              <p>
                Position: ({cell.position.id}) {cell.position.name}
              </p>
              <p>
                Location: ({cell.location.id}) {cell.location.name}{" "}
              </p>
              <p>
                Times: {moment(cell.startsAt).format("YYYY-MM-DD h:mm A")} -{" "}
                {moment(cell.endsAt).format("YYYY-MM-DD h:mm A")}
              </p>
              <p>LunchLength: {cell.lunchLength}</p>
              <p>Supervisor(s)</p>
              {cell.supervisors?.map((supervisor) => (
                <p>
                  {supervisor.name}. Phone: {supervisor.phoneNumber || "N/A"}
                </p>
              ))}
            </>
          );
        },
      },
      {
        dataField: "earnings",
        text: "Pay",
        headerStyle: { width: "6em" },
        formatter: (cell) => "$" + cell,
      },
      {
        dataField: "startedAt",
        text: "Started",
        sort: true,
        formatter: (cell, row) => (
          <Moment format="YYYY-MM-DD h:mm A">{cell}</Moment>
        ),
      },
      {
        dataField: "completedAt",
        text: "Completed",
        sort: true,
        formatter: (cell, row) => (
          <Moment format="YYYY-MM-DD h:mm A">{cell}</Moment>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: (cell, row) => {
          if (cell === "employer_review") {
            return <h5>Waiting for employer review</h5>;
          }
          return <h5>{cell}</h5>;
        },
      },
      {
        dataField: "reasonEarlyClockout",
        text: "Early Clockout reason",
        sort: true,
        formatter: (cell, row) => <p>{cell}</p>,
      },
      {
        dataField: "actions",
        isDummyField: true,
        text: "Actions",
        headerStyle: { width: "30em" },
        formatExtraData: approved,
        formatter: (cell, row, i, approved) => {
          console.log("we are also in formatter");
          return (
            <WorkerDetail
              shift={row.shift}
              workId={row.id}
              workerId={row.worker.id}
              fetchWorkers={null}
              fetchWorkStats={null}
              showMarkApprovedAction={true}
              onActionSuccess={(action) => this.onActionSuccess(action)}
              removeShiftFromReviewList={() =>
                this.removeShiftFromReviewList(i)
              }
            />
          );
        },
      },
    ];

    return (
      <div>
        <BootstrapTable
          striped
          bordered
          hover
          keyField="id"
          data={shifts}
          columns={columns}
        />
      </div>
    );
  }
}

export default PaymentReview;
