import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import LandingPage from "../Landing";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import Users from "../Users/Users";
import Payments from "../features/payments";
import UpcomingShifts from "../Shifts/upcoming_shifts";
import OngoingShifts from "../Shifts/ongoing_shifts";
import PastShifts from "../Shifts/past_shifts";
import SingleShift from "../Shifts/single_shift";
import ScreenView from "../features/screen";
import Listings from "../features/listing";
import Logs from "../features/logs";
import Company from "../features/Company/company";
import BusinessLeads from "../features/business_leads";
import Positions from "../features/positions";
import QuestDefinitions from "../features/quest_definitions";
import Checkr from "../Checkr/Checkr";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import "bootstrap/dist/css/bootstrap.min.css";

// Do not remove Messaging import. Though not directly referenced,
// many styles here are indirectly used by Messaging2.
import Messaging from "../messaging/messaging";
import Messaging2 from "../messaging2/messaging";
import { QueryClient, QueryClientProvider } from "react-query";
import Review from "../Review/Review";
import WorkerBusinessReferral from "../features/WorkerBusinessReferral";
import TutorialsContainer from "../Tutorials/TutorialsContainer";
import TipsContainer from "../Tips/TipsContainer";
import PositionEditorContainer from "../PositionEditor/PositionEditorContainer";
import Commands from "../features/Commands/Commands";
import Spreadsheets from "../features/Spreadsheets";
import SidebarNav from "../Navigation/SidebarNav";
import ShiftAnalytics from "../features/ShiftAnalytics";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <div style={{ display: 'flex', height: '100vh' }}>
        <SidebarNav style={{ overflowY: 'auto', height: '100%' }} />
        <main style={{ overflowY: 'auto', height: '100%' }} className="main-container">

          <Route exact path={ROUTES.LANDING} component={Users} />
          <Route exact path={ROUTES.SIGNIN} component={LandingPage} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route exact path={ROUTES.USERS} component={Users} />
          <Route exact path={ROUTES.UPCOMING_SHIFTS} component={UpcomingShifts} />
          <Route exact path={ROUTES.SINGLE_SHIFT} component={SingleShift} />
          <Route exact path={ROUTES.ONGOING_SHIFTS} component={OngoingShifts} />
          <Route exact path={ROUTES.PAST_SHIFTS} component={PastShifts} />
          <Route exact path={ROUTES.PAYMENTS} component={Payments} />
          <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route exact path={ROUTES.ADMIN} component={AdminPage} />
          <Route exact path={ROUTES.SCREEN} component={ScreenView} />
          <Route exact path={ROUTES.LISTINGS} component={Listings} />
          <Route exact path={ROUTES.LOGS} component={Logs} />
          <Route exact path={ROUTES.COMPANY} component={Company} />
          <Route exact path={ROUTES.BIZLEADS} component={BusinessLeads} />
          <Route
            exact
            path={ROUTES.WORKERBIZREFERRALS}
            component={WorkerBusinessReferral}
          />
          <Route exact path={ROUTES.REVIEW} component={Review} />
          <Route exact path={ROUTES.MESSAGES2} component={Messaging2} />
          <Route exact path={ROUTES.POSITIONS} component={Positions} />
          <Route exact path={ROUTES.CHECKR} component={Checkr} />
          <Route
            exact
            path={ROUTES.QUEST_DEFINITIONS}
            component={QuestDefinitions}
          />
          <Route exact path={ROUTES.TUTORIALS} component={TutorialsContainer} />
          <Route exact path={ROUTES.TIPS} component={TipsContainer} />
          <Route exact path={ROUTES.COMMANDS} component={Commands} />
          <Route exact path={ROUTES.POSITION_EDITOR} component={PositionEditorContainer} />
          <Route exact path={ROUTES.SPREADSHEETS} component={Spreadsheets} />
          <Route exact path={ROUTES.SHIFT_ANALYTICS} component={ShiftAnalytics} />
        </main>
      </div>
    </Router>
  </QueryClientProvider>
);

export default withAuthentication(App);
