import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../Shared/loading';
import styled from "styled-components";
import { getCallList, sendMessage } from '../../actions/messaging_actions';
import { showErrorToast } from '../utils/util';

interface Props {
    showModal: boolean,
    hideModal: () => void,
    submitMessageCallback: () => void,
}

const ComposeMessageModal = ({showModal, hideModal, submitMessageCallback}:Props) => {

    const [messageToSend, setMessageToSend] = useState(null);
    const [composeShiftId, setComposeShiftId] = useState(null);
    const [callList, setCallList] = useState(null);
    const [loadingCallList, setLoadingCallList] = useState(null);
    const [userIds, setUserIds] = useState(null);
    const [workwhileExperienced, setNewWorker] = useState(false);
    const [companyExperience, setCompanyExperience] = useState(false);
    const [orientationApproved, setOrientationApproved] = useState(null);
    const [listingNotViewed, setListingNotViewed] = useState(false);
    const [distance, setDistance] = useState(null);
    const [checkrCleared, setCheckrCleared] = useState(false);
    const [excludeMessaged, setExcludeMessaged] = useState(false);
    const [onlyListingViewed, setOnlyListingViewed] = useState(false);
    const [onWaitlist, setOnWaitlist] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onLoadCallList = (event) => {
        if (!composeShiftId) {
          alert("need a shift id")
          return
        }
    
        // get call list
        setLoadingCallList(true);
        getCallList(composeShiftId).then(res => {
            console.log('res: ', res)
            setCallList(res.data)
            setLoadingCallList(false)
        }).catch((err) => {
            setLoadingCallList(false);
            showErrorToast(err.message);
        });
    
        return
    };

    useEffect(() => {
        if (callList) {
          setUserIds(filterCallList())
        }
    }, [callList, distance, orientationApproved, companyExperience, workwhileExperienced, 
        listingNotViewed, checkrCleared, excludeMessaged, onlyListingViewed, onWaitlist]);

    const filterCallList = () => {
        return callList.filter(item => {
            if (companyExperience && item.shifts_at_company == 0)
            return false
    
            if (workwhileExperienced && item.tier_experienced == false)
            return false
            
            if (listingNotViewed && item.listing_viewed == 'Y') 
            return false
    
            if (excludeMessaged && item.messaged == 'Y')
            return false
    
            if (checkrCleared && !item.checkr_status)
            return false
    
            if (onlyListingViewed && item.listing_viewed == 'N') 
            return false

            if (onWaitlist && item.on_waitlist == 'N') 
            return false
            
            if (orientationApproved && item.orientation_status != 'approved') 
            return false
    
            // if they're farter away than what we want, pass
            if (distance && item.distance > distance)
            return false
            else
            return true
        }).map(item => item.id)
    }

    const onSubmit = (event) => {
        event.preventDefault();
        // console.log("we in here?????? ")
        if (!messageToSend) {
          showErrorToast("Add a message");
          return;
        }

        setIsSubmitting(true);
    
        let userIdsArray = typeof userIds == 'string' ? userIds.split(',') : userIds
        sendMessage(userIdsArray, messageToSend, composeShiftId).then(() => {
            console.log("successfully sent");
            if (submitMessageCallback) {
                submitMessageCallback();
            }
            setIsSubmitting(false);
        }).catch((err) => {
            showErrorToast(err.message);
            setIsSubmitting(false);
        })
    };

    return (
        <Modal style={{marginTop: 50}} show={showModal} onHide={hideModal} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Send message for a shift</Modal.Title>
            </Modal.Header>
            <div style={{overflow: 'scroll'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{margin:'5px 0 0 0'}}>
                    <label>Shift ID&nbsp;</label>
                    <input
                        className="input"
                        name="shiftId"
                        value={composeShiftId}
                        onChange={(e) => setComposeShiftId(e.target.value)}
                        type="text"
                        placeholder="Shift Id"
                    />
                    <button type="submit" className="ml-2" style={{width:'150px', margin:'0 0 10px 0'}} onClick={onLoadCallList} disabled={loadingCallList}>
                        Load call list
                    </button>
                    </div>
                    <label>
                    <input
                        type="checkbox"
                        checked={workwhileExperienced}
                        onChange={() => setNewWorker(!workwhileExperienced)}
                    />&nbsp;
                    {'>='} 1 non-orientation shift&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        checked={companyExperience}
                        onChange={() => setCompanyExperience(!companyExperience)}
                    />&nbsp;
                    Company experience&nbsp;&nbsp;
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        checked={listingNotViewed}
                        onChange={() => setListingNotViewed(!listingNotViewed)}
                    />&nbsp;
                        Listing not viewed&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        checked={checkrCleared}
                        onChange={() => setCheckrCleared(!checkrCleared)}
                    />&nbsp;
                    Checkr cleared&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        checked={excludeMessaged}
                        onChange={() => setExcludeMessaged(!excludeMessaged)}
                    />&nbsp;
                    Exclude messaged&nbsp;&nbsp;
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        checked={orientationApproved}
                        onChange={() => setOrientationApproved(!orientationApproved)}
                    />&nbsp;
                    Orientation approved&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        checked={onlyListingViewed}
                        onChange={() => setOnlyListingViewed(!onlyListingViewed)}
                    />&nbsp;
                    Listing Viewed&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        checked={onWaitlist}
                        onChange={() => setOnWaitlist(!onWaitlist)}
                    />&nbsp;
                    On Waitlist&nbsp;&nbsp;
                    </label>
                    <label>
                    Distance&nbsp;
                    <input type="number" step="0.01" value={distance} onChange={(e) => setDistance(e.target.value)}/>
                    </label>
                    <label>User IDs</label>
                    <input
                        className="input"
                        name="userIds"
                        value={userIds}
                        onChange={(e) => setUserIds(e.target.value)}
                        type="text"
                        placeholder="User Ids Comma Separated"
                    />
                </div>
                {loadingCallList && <div><Loading /><p>Loading call list. Hang on it takes a few mins.</p></div>}
                {callList && <CallListText>{userIds ? userIds.length : 0} workers on the current Call List</CallListText>}
                <label>Message</label>
                <textarea
                    rows={5}
                    style={{maxWidth: 500, width: '100%'}}
                    name="messageToSend"
                    value={messageToSend}
                    onChange={(e) => setMessageToSend(e.target.value)}
                    placeholder="Use $first_name to add name as variable"
                />
                <button type="submit" className="ml-2" onClick={onSubmit} disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Send message"}
                </button>
            </div>
        </Modal>
    );
};

const CallListText = styled.p`
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
`;

export default ComposeMessageModal;
