import React from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSection from "../Shared/CollapsibleSection";
import UserJobRequestsList from "./UserJobRequestsList";

interface Props {
  user: User;
}

const UserJobRequests = ({ user }: Props) => {
  return (
    <CollapsibleSection title="Job Requests">
      <UserJobRequestsList user={user} />
    </CollapsibleSection>
  );
};

export default UserJobRequests;
