import React from 'react';
import api from '../../api'
import '../Home/home.css';

const INITIAL_STATE = {
    company_name: '',
    company_logo_url: '',
    position_name: '',
    pay: '',
    expires_at: '',
    needs_car: false,
    needs_license: false,
    needs_full_time: false,
    experience_requirement: 'none',
    street: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    about: '',
    error: '',
};

class ListingAdCreate extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        const { company_name, company_logo_url, position_name, pay, expires_at, needs_car, needs_full_time, experience_requirement, street, unit, city, state, zip, about } = this.state;

        if (company_name === '' || company_logo_url === '' || position_name === '' || pay === '' || expires_at === '' || zip === ''){
            alert('Please complete all required fields')
            return;
        }

        api.post('/admin/listing_ad/create', {
            company_name, company_logo_url, position_name, pay, expires_at, needs_car, needs_full_time, experience_requirement, street, unit, city, state, zip, about,
        }).then((response) => {
            console.log(response);
            alert("created listing ad!")
            this.setState({ ...INITIAL_STATE })
        }).catch((error) =>    {
            alert(`Error: ${error}`)
            console.log(error);
        });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { company_name, company_logo_url, position_name, pay, expires_at, needs_car, needs_license, needs_full_time, experience_requirement, street, unit, city, state, zip, about, error } = this.state;

        return (
            <div>
                <h1>Create Listing Ad</h1>
                <form onSubmit={this.onSubmit} className="form">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label>Company Name*</label>
                            <input
                              className="input"
                              name="company_name"
                              value={company_name}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. Wine.com"
                            />
                            <label>Company Logo URL*</label>
                            <input
                              className="input"
                              name="company_logo_url"
                              value={company_logo_url}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. http://www.blahblahimg.com/blahsfl"
                            />
                            <label>Position Name*</label>
                            <input
                              className="input"
                              name="position_name"
                              value={position_name}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. Warehouse Associate"
                            />
                            <label>Pay*</label>
                            <input
                              className="input"
                              name="pay"
                              value={pay}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. up to $500 per week"
                            />
                            <label>Expires At (YYYY-MM-DD)*</label>
                            <input
                              className="input"
                              name="expires_at"
                              value={expires_at}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. 2020-12-23"
                            />
                            <label>Needs car</label>
                            <input
                              className="input"
                              type="checkbox"
                              checked={needs_car}
                              onChange={() => this.toggleCheckbox(needs_car)}
                            />
                            <label>Needs license</label>
                            <input
                              className="input"
                              type="checkbox"
                              checked={needs_license}
                              onChange={() => this.toggleCheckbox(needs_license)}
                            />
                            <label>Needs full time</label>
                            <input
                              className="input"
                              type="checkbox"
                              checked={needs_full_time}
                              onChange={() => this.toggleCheckbox(needs_full_time)}
                            />
                            <label>Experience requirement</label>
                            <select name="experience_requirement" value={experience_requirement} onChange={this.onChange}>
                                <option value="">Pick one...</option>
                                <option value="delivery_driver">delivery_driver</option>
                                <option value="warehouse">warehouse</option>
                                <option value="event_staff">event_staff</option>
                                <option value="retail_associate">retail_associate</option>
                                <option value="food">food</option>
                            </select>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label>Street</label>
                            <input
                              className="input"
                              name="street"
                              value={street}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. 169 11th St"
                            />
                            <label>Unit</label>
                            <input
                              className="input"
                              name="unit"
                              value={unit}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. #1"
                            />
                            <label>City</label>
                            <input
                              className="input"
                              name="city"
                              value={city}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. San Francisco"
                            />
                            <label>State</label>
                            <input
                              className="input"
                              name="state"
                              value={state}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. CA"
                            />
                            <label>Zip*</label>
                            <input
                              className="input"
                              name="zip"
                              value={zip}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. 94103"
                            />
                            <label>Job Description</label>
                            <textarea
                              className="input"
                              name="about"
                              value={about}
                              onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <button type="submit" className="submit-button" style={{minWidth:'150px'}}>
                        Send
                    </button>
                    {error && <p>{error.message}</p>}
                </form>
            </div>
        );
    }
}

export default ListingAdCreate;
