import React, { Component } from "react";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { defs } from "./defs";
import { device } from "./device";
import {
  formatPositionOptionsForSelect,
  formatCompanyOptionsForSelect,
  formatLocationOptionsForSelect,
  formatWorkerOptionsForSelect,
  formatWorkerIdOptionsForSelect,
  formatShiftOptionsForSelect,
  formatListingOptionsForSelect,
  formatMarketOptionsForSelect,
  formatStatusOptionsForSelect,
} from "../utils/util";

const animatedComponents = makeAnimated();

class ShiftsFilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOptions: null,
      locationOptions: null,
      positionOptions: null,
      workerOptions: null,
      workerIdOptions: null,
      shiftOptions: null,
      listingOptions: null,
      marketOptions: null,
      statusOptions: null,
    };
  }

  componentDidMount() {
    this.setFilterOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shifts !== this.props.shifts) {
      this.setFilterOptions();
    }
  }

  setFilterOptions() {
    const { shifts } = this.props;
    // TODO(Alex): collect all this info in 1 iteration instead of 7
    this.setState({
      companyOptions: formatCompanyOptionsForSelect(shifts),
      locationOptions: formatLocationOptionsForSelect(shifts),
      positionOptions: formatPositionOptionsForSelect(shifts),
      workerOptions: formatWorkerOptionsForSelect(shifts),
      workerIdOptions: formatWorkerIdOptionsForSelect(shifts),
      shiftOptions: formatShiftOptionsForSelect(shifts),
      listingOptions: formatListingOptionsForSelect(shifts),
      marketOptions: formatMarketOptionsForSelect(shifts),
      statusOptions: formatStatusOptionsForSelect(),
    });
  }

  render() {
    const {
      handleFilterChange,
      handleDateChange,
      showBorderTop,
      showBorderBottom,
      showCompanyFilter,
      showLocationFilter,
      showPositionFilter,
      showWorkerFilter,
      showWorkerIdFilter,
      showShiftFilter,
      showListingFilter,
      showCancelledShiftsFilter,
      showDateFilter,
      fromDate,
      toDate,
      showCancelledShifts,
      showUnfilledShifts,
      showUnfilledShiftsFilter,
      showStatusFilter,
    } = this.props;

    const {
      companyOptions,
      locationOptions,
      positionOptions,
      workerOptions,
      workerIdOptions,
      shiftOptions,
      listingOptions,
      marketOptions,
      statusOptions,
    } = this.state;

    return (
      <FilterBarWrapper
        showBorderTop={showBorderTop}
        showBorderBottom={showBorderBottom}
      >
        <Instruction>Filter by:</Instruction>
        <SelectGroup>
          {showCompanyFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={companyOptions}
              components={animatedComponents}
              styles={customStyles}
              name="company"
              placeholder="Company"
              onChange={handleFilterChange}
            />
          )}
          {showCompanyFilter && showLocationFilter && (
            <Instruction>&</Instruction>
          )}
          {showLocationFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={locationOptions}
              components={animatedComponents}
              styles={customStyles}
              name="location"
              placeholder="Location"
              onChange={handleFilterChange}
            />
          )}
          {showLocationFilter && showPositionFilter && (
            <Instruction>&</Instruction>
          )}
          {showPositionFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={positionOptions}
              components={animatedComponents}
              styles={customStyles}
              name="position"
              placeholder="Position"
              onChange={handleFilterChange}
            />
          )}
          {showPositionFilter && showWorkerFilter && (
            <Instruction>&</Instruction>
          )}
          {showWorkerFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={workerOptions}
              components={animatedComponents}
              styles={customStyles}
              name="worker"
              placeholder="Worker Name"
              onChange={handleFilterChange}
            />
          )}
          {showWorkerFilter && showWorkerIdFilter && (
            <Instruction>&</Instruction>
          )}
          {showWorkerIdFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={workerIdOptions}
              components={animatedComponents}
              styles={customStyles}
              name="workerId"
              placeholder="Worker ID"
              onChange={handleFilterChange}
            />
          )}
          {showWorkerIdFilter && showShiftFilter && (
            <Instruction>&</Instruction>
          )}
          {showShiftFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={shiftOptions}
              components={animatedComponents}
              styles={customStyles}
              name="shiftId"
              placeholder="Shift ID"
              onChange={handleFilterChange}
            />
          )}
          {showShiftFilter && showListingFilter && <Instruction>&</Instruction>}
          {showListingFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={listingOptions}
              components={animatedComponents}
              styles={customStyles}
              name="listingId"
              placeholder="Listing ID"
              onChange={handleFilterChange}
            />
          )}
          {showListingFilter && showDateFilter && <Instruction>&</Instruction>}
          {showDateFilter && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  color: "rgba(0,0,0,0.5)",
                  fontSize: defs.fontS,
                }}
              >
                From
              </span>
              <input
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={(e) => handleDateChange("fromDate", e.target.value)}
              />
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  color: "rgba(0,0,0,0.5)",
                  fontSize: defs.fontS,
                }}
              >
                to
              </span>
              <input
                type="date"
                name="toDate"
                value={toDate}
                onChange={(e) => handleDateChange("toDate", e.target.value)}
              />
            </>
          )}
          {<Instruction>&</Instruction>}
          {<Select
            isMulti
            isSearchable
            isClearable
            options={marketOptions}
            components={animatedComponents}
            styles={customStyles}
            name="market"
            placeholder="Market"
            onChange={handleFilterChange}
          />}
          {showStatusFilter && <Instruction>&</Instruction>}
          {showStatusFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={statusOptions}
              components={animatedComponents}
              styles={customStyles}
              name="status"
              placeholder="Shift includes a worker with status"
              onChange={handleFilterChange}
            />
          )}
          {showCancelledShiftsFilter && <Instruction>&</Instruction>}
          {showCancelledShiftsFilter && (
            <input
              type="checkbox"
              checked={showCancelledShifts}
              onChange={() =>
                handleFilterChange(!showCancelledShifts, {
                  name: "showCancelledShifts",
                })
              }
            />
          )}
          {showCancelledShiftsFilter && (
            <label style={{ marginBottom: 0, marginLeft: 10, marginRight: 10 }}>
              Show cancelled shifts only
            </label>
          )}
          {showUnfilledShiftsFilter && <Instruction>&</Instruction>}
          {showUnfilledShiftsFilter && (
            <input
              type="checkbox"
              checked={showUnfilledShifts}
              onChange={() =>
                handleFilterChange(!showUnfilledShifts, {
                  name: "showUnfilledShifts",
                })
              }
            />
          )}
          {showUnfilledShiftsFilter && (
            <label style={{ marginBottom: 0, marginLeft: 10 }}>
              Show unfilled shifts only
            </label>
          )}
        </SelectGroup>
      </FilterBarWrapper>
    );
  }
}

export default ShiftsFilterBar;

const FilterBarWrapper = styled.div`
  border-bottom: ${(props) => (props.showBorderBottom ? "1px solid" : null)};
  border-top: ${(props) => (props.showBorderTop ? "1px solid" : null)};
  border-color: ${defs.veryLightBlack};
  padding: ${defs.paddingS};
  padding-left: ${defs.paddingXL};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  @media ${device.smallerThanMobileL} {
    font-size: ${defs.fontS};
    height: 100%;
    padding-left: ${defs.paddingM};
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Instruction = styled.div`
  color: ${defs.mediumBlack};
  margin-right: ${defs.marginM};
  font-size: ${defs.fontS};
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
};
