import React from 'react';
import styled from "styled-components";
import { defs } from '../Shared/defs';
import { device } from '../Shared/device';

interface Props {
    onClick: () => void,
    active?: boolean,
    children: React.ReactNode,
    key?: string,
}


const ConversationItem = ({onClick, active, children, key}:Props) => {
    return (
        <Container onClick={onClick} active={active} key={key}>
            {children}
        </Container>
    );
}

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:  ${defs.marginS};
    background-color: ${props => props.active ? defs.wwAltTeal : defs.white};
    &:hover {
        background-color: ${props => props.active ? defs.wwAltTeal : defs.offWhite};
    }
    @media ${device.smallerThanMobileL}{
        flex-direction: column;
    }
`;

export default ConversationItem;