import React, { useState } from "react";
import { User } from "../Shared/common_defs";
import CollapsibleSection from "../Shared/CollapsibleSection";
import CollapsibleSectionTable from "../Shared/CollapsibleSectionTable";
import UserCheckrVerification from "./UserCheckrVerification";
import StatusSetter from "../Shared/StatusSetter";
import api from "../../api";

interface Props {
  user: User;
  refreshData: (e?: any) => void;
}

const UserIdentityVerfification = ({ user, refreshData }: Props) => {
  const [newPersonaStatus, setNewPersonaStatus] = useState(undefined);
  const [submittingPersonaStatus, setSubmittingPersonaStatus] = useState(false);

  const getIdentityVerificationLink = (user) => {
    if (user.identityVerificationId) {
      // Persona
      return `https://withpersona.com/dashboard/inquiries/${user.identityVerificationId}`;
    }
    return `https://dashboard.berbix.com/transactions?orgId=4813348945264640&customerUid=${user.firebaseId}`;
  };

  const data = React.useMemo(
    () => [
      {
        key: "Outfielder GCP Bucket",
        value: "Click here",
        link: `https://console.cloud.google.com/storage/browser/outfielders/${user.id}`,
      },
      {
        key: "persona_status",
        value: user.berbixStatus || "N/A",
        link: user.berbixStatus ? getIdentityVerificationLink(user) : null,
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: "key",
      },
      {
        accessor: "value",
      },
    ],
    []
  );

  const personaOptions = [
    { label: "Remove Status from DB", value: undefined },
    { label: "Set Status to Declined", value: "declined"},
    { label: "Set Status to Approved", value: "approved" },
  ];

  const handleChange = (e) => {
    setNewPersonaStatus(e.target.value)
  }

  const submitPersonaStatusChange = () => {
    setSubmittingPersonaStatus(true);
    const r = window.prompt(
      `You are about to set USER(${user.id}) persona_status to: ${newPersonaStatus}. To execute this action, please press Ok.`
    );
    if (r != null) {
      api
        .put(`/admin/user/${user.id}/persona`, {
          status: newPersonaStatus,
        })
        .then((response) => {
          alert(`This user's persona Status is now ${newPersonaStatus? newPersonaStatus : "cleared from the database"}`);
          setSubmittingPersonaStatus(false);
          refreshData();
        })
        .catch((error) => {
          console.log("ERROR OCCURED: ", error);
          setSubmittingPersonaStatus(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingPersonaStatus(false);
    }
    return;
  };

  return (
    <CollapsibleSection title="Identity + Background Check" defaultOpen>
      <CollapsibleSectionTable data={data} columns={columns} />
      
      <StatusSetter
        label="Set Persona Status"
        name="newPersonaStatus"
        value={newPersonaStatus}
        handleChange={(e) => handleChange(e)}
        isLoading={submittingPersonaStatus}
        submitStatusChange={submitPersonaStatusChange}
        options={personaOptions}
        buttonType="confirm"
      />
      <UserCheckrVerification
        user={user}
        refreshData={refreshData}
        defaultOpen={true}
      />

    </CollapsibleSection>
  );
};

export default UserIdentityVerfification;
