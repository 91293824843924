import React, { useEffect, useState } from "react";
import { addNewRequirementToPosition } from "../../actions/company_actions";
interface Props {
    positionId: number;
    successCallback: () => void;
  }
const CreateRequirementForPosition = ({positionId, successCallback}: Props) => {
    const [displayName, setDisplayName] = useState('')
    const [category, setCategory] = useState('')
    const [categoryOptions, setCategoryOptions] = useState([])
    const [description, setDescription] = useState('')
    const [isWorkerVisible, setIsWorkerVisible] = useState(true)
    const [isCompanySpecific, setIsCompanySpecific] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    useEffect(() => {
        // TODO: This should be an API call to for accuracy/maintainability.
        setCategoryOptions(['General Requirements', 'Attire & Grooming', 'Certificates & Experience'])
        setCategory(categoryOptions[0])  // Default to this.
    }, []);

    const validateAndCreateRequirement = () => {
        if (!displayName) {
            alert('❌ Please enter a display name')
            return
        }
        if (!category) {
            alert('❌ Please select a category')
            return
        }
        // Indicate that the request is loading.
        setIsLoading(true)

        const requirementData = {
            'name': displayName,
            'description': description,
            'category': category,
            'is_worker_visible': isWorkerVisible,
            'is_company_specific': isCompanySpecific,
            'position_id': positionId
        }

        const responseCallback = (response) => {
            setIsLoading(false)
            alert("Requirement #" + response.data + " created and added to position " + positionId +" ✅");        
            successCallback()
        }
        const errorCallback = (error) => {
            setIsLoading(false)
            alert("❌ Error creating requirement")
            console.log(error)
        }
        addNewRequirementToPosition(requirementData, responseCallback, errorCallback);

        // Clear the inputs.
        setDisplayName('')
        setDescription('')
        setIsWorkerVisible(true)
        setIsCompanySpecific(false)
        setCategory(categoryOptions[0])
    };

    function alertMessageForEngHelp(label) {
        alert(`❌ Cannot make a requirement ${label} until talking to eng team. Continue with non-${label} and then reach out to eng with the ID of the created requirement.`)
    }
    
    
    return <>
       <div
    style={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start"
    }}
>
    <div 
        style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }}
    >
        <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display Name"
        />
        <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description (optional)"
        />
        <div style={{display: "flex", alignItems: "left"}}>
            <span style={{marginRight: "10px"}}>Visible to worker? </span>
            <input
                className="input"
                type="checkbox"
                checked={isWorkerVisible}
                id="isWorkerVisible"
                onChange={(e) => setIsWorkerVisible(e.target.checked)}
                style={{width: "auto"}}
            />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
            <span style={{marginRight: "10px"}}>Verifiable?</span>
            <input
                className="input"
                type="checkbox"
                checked={false}
                id="verifiable"
                onChange={() => alertMessageForEngHelp('verifiable')}
                style={{width: "auto"}}

            />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span style={{marginRight: "10px"}}>Category:</span>
          <select 
            value={category} 
            onChange={(e) => setCategory(e.target.value)}
          >
            {categoryOptions.map((option, index) => 
                <option key={index} value={option}>{option}</option>
            )}
          </select>
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
            <span style={{marginRight: "10px"}}>Company Specific?</span>
            <input
                className="input"
                type="checkbox"
                checked={isCompanySpecific}
                id="companySpecific"
                onChange={(e) => setIsCompanySpecific(e.target.checked)}
                style={{width: "auto"}}
            />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
            <span style={{marginRight: "10px"}}>Must have?</span>
            <input
                className="input"
                type="checkbox"
                checked={false}
                id="companySpecific"
                onChange={() => alertMessageForEngHelp('must have')}
                style={{width: "auto"}}
            />
        </div>
        
    </div>
    <button onClick={(e) => validateAndCreateRequirement()} disabled={isLoading}>Submit</button>
</div>

    </>


};
export default CreateRequirementForPosition;
