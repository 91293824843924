import React, { useState } from "react";
import { Work, WorkShift } from "../../Shared/common_defs";
import { FILLED_STATUSES } from "../../utils/util";
import WorkerDetail from "./WorkerDetail";

interface Props {
  work: Work;
  shift: WorkShift;
  fetchWorkStats: (showLoading: boolean) => void;
  fetchWorkers: (showLoading: boolean) => void;
}

const WorkerBasicInfo = ({
  work,
  shift,
  fetchWorkStats,
  fetchWorkers,
}: Props) => {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div>
      <span
        style={
          FILLED_STATUSES.includes(work.status)
            ? { color: "#000000" }
            : { color: "rgba(0,0,0,0.5)" }
        }
      >
        [{work.status}]{work.isReplaceable ? "[REPLACEABLE]" : ""}
        {work.replacedAt ? "[REPLACED]" : ""} {work.worker.name}(
        {work.worker.id}){work.confirmedAt !== "N/A" ? "*" : ""}
        <a
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "rgba(0,0,0,0.5)",
          }}
          onClick={() => setShowDetail(!showDetail)}
        >
          {showDetail ? "Hide" : "Show"} Detail
        </a>
      </span>

      {showDetail && (
        <WorkerDetail
          workId={work.id}
          workerId={work.worker.id}
          shift={shift}
          fetchWorkStats={fetchWorkStats}
          fetchWorkers={fetchWorkers}
        />
      )}
    </div>
  );
};

export default WorkerBasicInfo;
