import React from 'react';
interface Props {
    currentlyBundled: boolean;
    positionId: number;
}

const BundlingEditor = ({currentlyBundled, positionId}: Props) => {
    return (
        <>
            To turn bundling <b>{currentlyBundled ? 'off' : 'on'}</b> use one of the folllowing commands: <br/>
            <code>@wobot company toggle-bundling {positionId} {currentlyBundled ? '--off' : '--on'}</code>
            <br/>
        </>
    )
}
export default BundlingEditor