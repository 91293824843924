import React, { useEffect, useState } from "react";
import { User } from "../Shared/common_defs";
import { workColumns, userWorkQuery } from "../Shared/work";
import BootstrapTable from "react-bootstrap-table-next";
import graphql from "../../graphql";
import Loading from "../Shared/loading";
import Select from "react-select";
import { defs } from "../Shared/defs";
import { formatStatusOptionsForSelect } from "../utils/util";

interface Props {
  user: User;
}

const groupingOptions = [
  { label: "All", value: null },
  { label: "upcoming", value: "upcoming" },
  { label: "ongoing", value: "ongoing" },
  { label: "past", value: "past" },
];

const UserWorkList = ({ user }: Props) => {
  const [submittingWorkRequest, setSubmittingWorkRequest] = useState(false);
  const [work, setWork] = useState([]);
  const [limit, setLimit] = useState(10);
  const [grouping, setGrouping] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    requestWork();
  }, []);

  const requestWork = (e?) => {
    if (e) {
      e.preventDefault();
    }
    setWork([]); // clear the current work list
    setSubmittingWorkRequest(true);
    graphql(userWorkQuery(user.id, limit, grouping, status))
      .then((response) => {
        const workResponse = response.data.data.admin.user.work;
        if (workResponse) {
          setWork(workResponse);
        }
        setSubmittingWorkRequest(false);
      })
      .catch((error) => {
        setSubmittingWorkRequest(false);
      });
  };

  const searchGroup = (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ marginRight: defs.marginM }}>
      <div> Entries: </div>
      <input
          className="input"
          name="limit"
          type="number"
          value={limit}
          onChange={(e) => setLimit(parseInt(e.target.value))}
          placeholder="Entries"
        />
      </div>
      <Select
        isSearchable
        isClearable
        options={groupingOptions}
        styles={customStyles}
        name="grouping"
        placeholder="Grouping"
        onChange={(option) => {
          if (option) setGrouping(option.value);
          else setGrouping(null);
        }}
      />
      <Select
        isSearchable
        isClearable
        options={formatStatusOptionsForSelect()}
        styles={customStyles}
        name="status"
        placeholder="Status"
        onChange={(option) => {
          if (option) setStatus(option.value);
          else setStatus(null);
        }}
      />
      {!submittingWorkRequest && (
            <button onClick={() => requestWork()}>Request Data</button>
          )}
    </div>
  );

  return (
    <div>
      {submittingWorkRequest && <Loading type="button" />}
      {searchGroup}
      {work && work.length > 0 ? (
        <>
          <BootstrapTable
            striped
            keyField="shift_id"
            data={work}
            columns={workColumns(requestWork)}
            rowStyle={{ fontSize: 10 }}
          />
        </>
      ) : (
        <p>User is not associated with any shifts</p>
      )}
    </div>
  );
};

export const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
};

export default UserWorkList;
