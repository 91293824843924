import React from "react";
import { useTable } from "react-table";
import { defs } from "../Shared/defs";

interface Props {
  data: Array<object>;
  columns: Array<object>;
}

const CollapsibleSectionTable = ({ data, columns }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <td
                {...column.getHeaderProps()}
                style={{ paddingRight: defs.marginM }}
              >
                <p style={{ color: defs.mediumBlack }}>
                  {column.render("Header")}
                </p>
              </td>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const value = cell.value;
                if (!cell.value) {
                  // skip null entries
                  return;
                }
                const accessor = cell.column.id;
                const link =
                  accessor === "value" ? cell.row.original.link : null;
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      paddingBottom: defs.marginXS,
                      paddingRight: defs.marginM,
                    }}
                  >
                    <a
                      href={link || null}
                      target="_blank"
                      style={
                        accessor === "key" ? { color: defs.mediumBlack } : null
                      }
                    >
                      {cell.render("Cell")}
                    </a>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CollapsibleSectionTable;
