export const defs = {
  /* Constant dimensions */
  mobileNavHeight: "63px",
  minMobileFormWidth: "200px",
  maxModalInputWidth: "600px",
  sidebarWidth: "228px",

  /* Margins */
  marginXXS: "5px",
  marginXS: "8px",
  marginS: "15px",
  marginM: "20px",
  marginL: "25px",
  marginXL: "55px",

  /* Padding */
  paddingXXS: "2px",
  paddingXS: "6px",
  paddingS: "8px",
  paddingSS: "11px",
  paddingM: "15px",
  paddingL: "25px",
  paddingXL: "50px",

  /* Colors */
  transparent: "rgba(255,255,255, 0)",
  white: "#ffffff",
  altWhite: "#fafafa",
  offWhite: "#F3F3F2",
  lightGray: "#E5E5E5",
  inactiveGray: "#999999",
  veryLightBlack: "rgba(0, 0, 0, 0.1)",
  lightBlack: "rgba(0, 0, 0, 0.3)",
  mediumBlack: "rgba(0, 0, 0, 0.5)",
  black: "#000000",
  wwDarkTeal: "#60C5B6",
  wwDarkBlue: "#11212D",
  wwAltTeal: "#27c2b1",

  /* Alert Colors */
  darkAlertRed: "#721c24",
  alertRed: "#f8d7da",
  lightAlertRed: "#f5c6cb",
  darkAlertAqua: "#0c5460",
  alertAqua: "#d1ecf1",
  lightAlertAqua: "#bee5eb",
  darkAlertYellow: '#856404',
  alertYellow: '#fff3cd',
  lightAlertYellow: '#ffeeba',
  darkAlertGreen: '#155724',
  alertGreen: '#d4edda',
  lightAlertGreen: '#c3e6cb',

  /* background blur */
  blurShade: "rgba(0, 0, 0, 0.4)",

  /* Border radius */
  borderRadiusS: "2px",
  borderRadiusM: "4px",
  borderRadiusL: "6px",
  borderRadiusXL: "10px",
  borderRadiusRounded: "54px",

  /* Font Family */
  ffMain: "'Work Sans', sans-serif",

  /* Font size */
  fontS: "12px",
  fontM: "14px",
  fontDefault: "16px",
  fontLL: "18px",
  fontXL: "22px",
  fontXXL: "32px",

  /* Line height */
  lineHeightS: "14px",
  lineHeightM: "16px",
  lineHeightL: "18px",
  lineHeightXL: "24px",

  /* Button dimensions */
  buttonS: {
    width: "86px",
    height: "34px",
  },
  buttonM: {
    width: "150px",
    height: "45px",
  },
  buttonL: {
    width: "200px",
    height: "55px",
  },

  /* Icon dimensions */
  iconS: {
    width: "22px",
    height: "22px",
    marginTop: "22px",
    marginLeft: "22px",
  },
  iconM: {
    width: "40px",
    height: "40px",
  },

  /* Logo dimensions */
  logoS: {
    marginTop: "6px",
    marginBottom: "2px",
    marginLeft: "14px",
    size: "30px",
  },
  logoM: {
    marginTop: "20px",
    marginBottom: "50px",
    size: "50px",
  },

};
