import React, { useState } from 'react';

interface Position {
    name: string,
    id: number,
}

interface Props {
    description: string,
    bonus_charge_to_company: boolean,
}

// XXX: Woods - fill out if needed
const QuestDefinitionDetail = ({description, bonus_charge_to_company}:Props) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <div>
            <button onClick={()=>setShowDetails(!showDetails)}>{showDetails ? 'Hide Details' : 'Show Details'}</button>
            {showDetails && (
                <div>
                    <b>Decription</b>
                    <p>{description}</p>
                    <b>Bonus Charge To Company</b>
                    <p>{bonus_charge_to_company}</p>
                </div>
            )}
        </div>
    );
}

export default QuestDefinitionDetail;