import React, { useState } from "react";
import "./Commands.css";
import api from "../../../api";

const Command = ({ command }) => {
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(null);
  const [output, setOutput] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = () => {
    console.log("Executing command:", command.name, "with inputs:", inputs);

    api
      .post("/admin/commands", { command: command.name, arguments: inputs })
      .then((response) => {
        alert("Success!");
        console.log(response);
        setOutput(response.data);
      })
      .catch((error) => {
        setOutput(null);
        setError(error.message);
      });
  };

  return (
    <div className="Command">
      <h3>{command.name}</h3>
      {command.help && <p>{command.help}</p>}
      <div className="Command-inputs">
        {command?.args?.map((arg) => (
          <div key={arg.name} className="Command-input">
            <label htmlFor={arg.name}>{arg.label}</label>
            {arg.type === "text" ? (
              <textarea
                name={arg.name}
                id={arg.name}
                required={arg.required}
                onChange={handleChange}
                rows={1}
              />
            ) : (
              <input
                type={arg.type}
                name={arg.name}
                id={arg.name}
                required={arg.required}
                onChange={handleChange}
              />
            )}
          </div>
        ))}
        {command?.options?.map((option) => (
          <div key={option.name} className="Command-input">
            <label htmlFor={option.name}>{option.label}</label>
            {option.type === "text" ? (
              <textarea
                name={option.name}
                id={option.name}
                required={option.required}
                onChange={handleChange}
                rows={1}
              />
            ) : (
              <input
                type={option.type}
                name={option.name}
                id={option.name}
                required={option.required}
                onChange={handleChange}
              />
            )}
          </div>
        ))}
      </div>
      <button onClick={handleSubmit}>Execute</button>
      {error && <p className="Command-error">{error}</p>}
      {output && <pre className="Command-output">{output}</pre>}
    </div>
  );
};

export default Command;
