import React, { useState } from "react";
import TakeRatesFormatter from "./TakeRateEditor";
import { onW2Change } from "../../../actions/company_actions";
import BootstrapTable from "react-bootstrap-table-next";
import Loading from "../../Shared/loading";

const CompanyOverview = ({company, refreshCallback}) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const companyFormatter = (cell, row) => {
        return <span>{cell} ({row.id})</span>
    }

    const takeRatesFormatter = (cell, row) => {
        return <TakeRatesFormatter cell={cell} row={row}/>
    };

    const w2Formatter = (cell, row) => {
        if (!row) {
            return <div>None</div>; 
        } 
        
        const responseCallback = (response) => {
            // Company has been updated, refetch info.
            refreshCallback()
            setIsLoading(false)
        }

        const errorCallback = (error) => {
            console.log(error)
            setIsLoading(false)
        }
        return (
            <input
                disabled={!row.isW2Eligible}
                type="checkbox" 
                checked={cell} 
                onChange={() => {
                    setIsLoading(true);
                    onW2Change(row.id, responseCallback, errorCallback)
                }}
            />
        )

    }

    const companyColumns = [
    {
        dataField: 'name',
        text: 'Company',
        formatter: companyFormatter,
        editable: false,
    }, 
    {
        dataField: 'isW2',
        text: 'W2',
        formatter: w2Formatter,
        editable: false,
    }, 
    {
        dataField: 'takePercentages',
        text: 'Take Rates',
        formatter: takeRatesFormatter,
        editable: false,
    },
]

    if (company === null) {
        return null;
    } else if (isLoading) {
        return <Loading/>
    } else {
    return <BootstrapTable
        keyField="id"
        data={[company]}
        columns={companyColumns}
        striped
        rowStyle={{ fontSize: 10 }} /> 
    }
}
export default CompanyOverview