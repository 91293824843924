import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import "../Home/home.css";
import { withAuthorization } from "../Session";
import graphql from "../../graphql";
import Loading from "../Shared/loading";
import UserRecord from "./UserRecord";
import { defs } from "../Shared/defs";

type SearchParams = {
  url_search_term: string;
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [submittingSearch, setSubmittingSearch] = useState(false);
  const [error, setError] = useState("");

  // hooks to get and set url params
  let { url_search_term }: SearchParams = useParams<SearchParams>();
  let history = useHistory();

  useEffect(() => {
    // componentDidMount
    if (url_search_term) {
      setSearchTerm(url_search_term);
      search(null, url_search_term);
    }
  }, []);

  useEffect(() => {
    // update url on each search
    if (searchTerm && searchTerm !== "") {
      history.push(`/users/${searchTerm}`);
    }
  }, [submittingSearch]);

  const searchQuery = (search_term: string) => {
    return `
      admin { 
          users (searchTerm:"${search_term}") {
            id, firebaseId, name, phoneNumber, email, status, shadowBlocked, shadowBlockedReason, orientationStatus, rating, createdAt, w2Status
            companyWorkerProfiles {
              companyId, workerId, favorite, blocked, hired, blockedReason
            },
            paymentAccounts {
              accountType
            },
            penalties {
              createdAt,
              userId,
              penaltyType,
              expiresAt,
              status,
              policy,
              offenses {
                shiftId,
                reason,
                healthCheckAnswers
                isCancelledForIllness
              }
            },
            referrals {
              id,
              status,
              referredName,
              referred {
                id,
                name,
              }
            },
            reference {
              id,
              status,
              referredName,
              referrer {
                id,
                name,
              }
            },
            userStats {shiftCount, penaltyCount},
            address {city, state, street, unit, zip, lat, long, timezone},
            berbixToken, berbixStatus, checkrId, checkrStatus, checkrMvrStatus, identityVerificationId
            onboardingStatus {
              checkrSubmitted, checkrMvrSubmitted
            },
            strikes { date, excuse, id, reason, isCancelledForIllness, shiftId },
            allStrikes { date, excuse, id, reason, isCancelledForIllness, shiftId, excluded },
            stripeAccountIdProd, hasSsn, tierExperienced,
            workProfile {
              hasLicense, hasCar, isElite, currentlyEmployed, numHoursDesired, wantsFullTime, telehealthBenefitStatus, isSept22Elite
            }
            credentials{
              id
              workerRequirement{
                name
                documentNeeded
              }
            }
          }
      }`;
  };

  // specifiedSearchTerm param needed to support url search
  const search = (e?: any, specifiedSearchTerm?: string) => {
    if (e) {
      e.preventDefault();
    }
    setSubmittingSearch(true);
    setError("");
    setUsers([]);
    graphql(searchQuery(specifiedSearchTerm || searchTerm))
      .then((response) => {
        if (response.data.data.admin.users) {
          setUsers(response.data.data.admin.users);
          setSubmittingSearch(false);
        } else {
          setError("No users match your search query");
          setSubmittingSearch(false);
        }
      })
      .catch((error) => {
        setError(error);
        setSubmittingSearch(false);
      });
  };

  return (
    <div style={{ backgroundColor: defs.altWhite }}>
      <div className="users-table m-5">
        <div className="mt-5">
          <form
            onSubmit={search}
            style={{
              width: "100%",
              position: "fixed",
              flexDirection: "row",
              display: "flex",
              flex: 1,
            }}
          >
            <input
              name="search_term"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="text"
              placeholder="Search by name, id, firebase_id, email or phone"
              style={{ height: 50, flex: 0.8 }}
            />
            {!submittingSearch && (
              <button type="submit" style={{ width: 100, height: 50 }}>
                Search
              </button>
            )}
            {submittingSearch && <Loading type="button" />}
          </form>
        </div>
        <div style={{ paddingTop: 100 }}>
          {error && <b style={{ color: "red" }}>{error}</b>}
          {users.map((user) => (
            <UserRecord search={search} user={user} />
          ))}
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Users);
