import React from "react";
import {Strike, User} from "../Shared/common_defs";
import {dateTimeSubmittedFormat, getUserTimezone} from "../utils/general_util";
import moment from "moment-timezone";

interface Props {
    user: User;
    strike: Strike;
}

export const UserPastStrike = ({ user, strike }: Props) => {

    const _timezone = getUserTimezone(user);
    console.log("strike is: ", strike)
    return (
        <div>
            <p>({strike.id}) <b>{strike.reason}</b> {strike.excuse} {strike.date ? moment.tz(strike.date, _timezone).format(dateTimeSubmittedFormat): null} Excluded: {String(strike.excluded)}</p>
        </div>
    )
}
