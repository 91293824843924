import React, { useEffect, useState } from 'react';
import AddShiftBonus from './AddShiftBonus';
import { WorkShift } from '../../Shared/common_defs';
import graphql from '../../../graphql';
import Loading from '../../Shared/loading';
import * as moment from "moment-timezone";
import { dateCommaTimeFormat, getShiftTimezone } from '../../utils/general_util';
import RefreshButton from '../../Shared/RefreshButton';

interface Props {
    shift: WorkShift,
}

const Pay = ({ shift }: Props) => {
    const [fetchingPayDetail, setFetchingPayDetail] = useState(false);
    const [payDetail, setPayDetail] = useState(null);
    const timezone = getShiftTimezone(shift);
    const [showActions, setShowActions] = useState(false);

    useEffect(() => {
        // componentDidMount - use the info we already asked for in shift query
        // we want to avoid multiple queries on mount
        setPayDetail(shift);
    }, [])

    const payQuery = (shiftId) => {
        return `
            admin { 
                shift (shiftId:${shiftId}) {
                    id, numWorkersFilled, pay, payRate, payLumpSum,
                    shiftBonuses {amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus},
                    location {address {timezone}},
                }
            }`
    }

    // fetchPay used in actions in child components that require a refresh of the top level pay data
    const fetchPay = () => {
        setFetchingPayDetail(true)
        graphql(payQuery(shift.id)).then(response => {
            const shiftData = response.data.data.admin.shift
            if (shiftData) {
                setPayDetail(shiftData);
            }
            setFetchingPayDetail(false);
        }).catch(() => {
            setFetchingPayDetail(false);
        })
    }

    if (fetchingPayDetail) {
        return <Loading type="button" />
    }

    if (!payDetail) {
        return <p>Could not find pay info for this shift.</p>
    }


    let displayedBonus = null;

    const formattedTime = (time: string) => {
        return moment.tz(time, timezone).format(dateCommaTimeFormat);
    }

    const shiftBonuses = payDetail.shiftBonuses;
    if (shiftBonuses && shiftBonuses.length > 0) {
        // Company-paid bonus information.
        console.log(shiftBonuses)
        const employerBonuses = shiftBonuses.filter(bonus => !!bonus.companyPaid);
        const latestEmployerBonus = employerBonuses.length > 0 ? employerBonuses[employerBonuses.length - 1] : null;
        const latestEmployerBonusAmount = latestEmployerBonus ? Number(latestEmployerBonus.amount) : 0;
        const timeEmployerBonusSet = latestEmployerBonusAmount ? formattedTime(latestEmployerBonus.createdAt) : null;
        
        // WW Bonus information. Some bonuses are available to all workers and some are subjective.
        const wwBonuses = shiftBonuses.filter(bonus => !bonus.companyPaid && !bonus.isDynamicBonus && !bonus.isNewWorkerBonus);
        const latestWWBonus = wwBonuses.length > 0 ? wwBonuses[wwBonuses.length - 1] : null;
        const latestWWBonusAmount = latestWWBonus ? Number(latestWWBonus.amount) : 0;
        const timeWWBonusSet = latestWWBonusAmount ? formattedTime(latestWWBonus.createdAt) : null;
        
        // Custom/subjective bonuses.
        let specialBonusInfo = '';
        for (let bonus of shiftBonuses) {
            if (bonus.isDynamicBonus || bonus.isNewWorkerBonus) {
                specialBonusInfo += `*Selective bonus: $${bonus.amount} (${bonus.isNewWorkerBonus ? 'new worker bonus' : 'dynamic bonus'}). Set at ${formattedTime(bonus.createdAt)}\n`;
            }
        }

        

        displayedBonus = <p>${latestWWBonusAmount + latestEmployerBonusAmount} bonus{specialBonusInfo.length > 0 ? `*` : ``} (${latestEmployerBonusAmount} company <i>{timeEmployerBonusSet ? `(${timeEmployerBonusSet})` : ''}</i> + ${latestWWBonusAmount} WW <i>{timeWWBonusSet ? `(${timeWWBonusSet})` : ''}</i>) 
            <br/>{specialBonusInfo ? <i>{specialBonusInfo}</i> : ''}
        </p>
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <div style={{ flex: 1 }}>
                    <p>${payDetail.payRate ? payDetail.payRate + '/hr' + ' total: $' + payDetail.pay : payDetail.payLumpSum + ' for the whole shift'}.</p>
                    {displayedBonus}
                </div>
                <RefreshButton refreshFn={() => fetchPay()} />
            </div>
            <button className='standard-button' onClick={() => setShowActions(!showActions)} type='button'>{showActions ? 'Hide' : 'Show'} Actions</button>

            {showActions && <AddShiftBonus shift={shift} fetchPay={fetchPay} />}
        </>
    );
}

export default Pay;
