import React, { useState } from 'react';
import api from '../../../api';
import { WorkShift } from '../../Shared/common_defs';
import Loading from '../../Shared/loading';
import { isShiftFull, isShiftInProgress } from '../../utils/general_util';

interface Props {
    shift: WorkShift,
    fetchPay: ()=>void,
}

const AddShiftBonus = ({shift, fetchPay}:Props) => {
    const [amount, setAmount] = useState('');
    const [chargeToCompany, setChargeToCompany] = useState(false);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const inProgress = isShiftInProgress(shift);

    const onSubmit = event => {
        event.preventDefault();
        
        if (Number(amount) <= 0){
          alert('Amount must be greater than 0')
          return;
        }
    
        setSubmitting(true);
    
        const r = window.confirm(`You are about to add a $${amount} Bonus to SHIFT ${shift.id}. Charge to company = ${chargeToCompany}. To execute this action, please press Ok.`);
        if (r == true) {
          api.post(`/admin/shift/bonus`, {
            shift_id: shift.id,
            "charge_to_company": chargeToCompany,
            amount,
          })
            .then((response) => {
              fetchPay();
              alert(`$${amount} Bonus ADDED to SHIFT ${shift.id}`)
              setAmount('');
              setChargeToCompany(false);
              setSubmitting(false);
            })
            .catch((error) =>    {
                setSubmitting(false);
            });
        } else {
          alert('Action Cancelled!')
          setSubmitting(false);
        }
      };

    if (isShiftFull(shift)) {
        return (
            <div className="alert alert-warning" style={{fontSize: 11}}>
                You cannot add a bonus to a shift which is full.
            </div>
        );
    }

    if (inProgress) {
        return (
            <div className="alert alert-warning" style={{fontSize: 11}}>
                You cannot add a bonus to a shift which has a start time in the past.
            </div>
        );
    }

    const isInvalid = !shift.id || amount === '' || inProgress;
    
    return (
        <div>
          <hr />
          <span>
            <b>Add Bonus to SHIFT </b>
            <a style={{color: 'blue', cursor:'pointer'}} onClick={()=>window.alert('If you add another bonus, the bonus offered will be updated and a notification will go out. E.g.  Lets say you added 10 dollar bonus at 4PM and 2 workers accept shift, they get a 10 dollar bonus each. Now you up it to 20 dollars at 6PM, anyone who accepts shift from 6pm onwards will get 20.')}>Info</a>
        </span>
          <form onSubmit={onSubmit}>
            <div style={{display:'flex', flexDirection:'column'}}>
              <label>Amount</label>
              <input
                disabled={inProgress}
                className="input"
                name="amount"
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}
                type="number"
                placeholder="Amount"
              />
            </div>
            <div style={{display:'flex', flexDirection:'column'}}>
              <label>Charge to company</label>
              <input
                className="input"
                type="checkbox"
                checked={chargeToCompany}
                onChange={()=>setChargeToCompany(!chargeToCompany)}
              />
            </div>
            {!submitting && <button disabled={isInvalid} type="submit" className="confirm-button">ADD</button>}
            {submitting && <Loading type='button' />}
            {error && <p>{error}</p>}
          </form>
          <hr />
        </div>
      );
}

export default AddShiftBonus;
