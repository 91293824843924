import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";

import { AuthUserContext } from "../Session";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import "./nav.css";
import { MdSort, MdPerson, MdHouse, MdMessage, MdPayments, MdPhone, MdLaptop, MdFolderOpen, MdChecklist, MdList } from "react-icons/md";

const logo = require('../../assests/ww_logo.png')

const theme = {

    sidebar: {
        backgroundColor: '#0b2948',
        color: '#607489',
    },
    menu: {
        menuItemText: '#27C8B6',
        icon: '#27C8B6',
        hover: {
            backgroundColor: '#c5e4ff',
            color: '#44596e',
        },
        disabled: {
            color: '#9fb6cf',
        },
    },
};

const menuItemStyles = {
    root: {
        fontSize: '13px',
        fontWeight: 400,
    },
    icon: {
        color: theme.menu.icon,
    },
    SubMenuExpandIcon: {
        color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
        backgroundColor: theme.sidebar.backgroundColor,
    }),
    button: {
        color: theme.menu.menuItemText,
        '&:hover': {
            backgroundColor: 'rgba(197, 228, 255, 1)',
            color: theme.menu.hover.color,
        },
    },
    label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
    }),
};

const SidebarNav = () => {

    const [collapsed, setCollapsed] = React.useState(true);


    return (
        <Sidebar rootStyles={{
            color: theme.sidebar.color,
            overflowY: 'scroll',
            height: '100%',
        }}
            width="300px"
            backgroundColor={theme.sidebar.backgroundColor}
            collapsed={collapsed}
            onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}>
            <AuthUserContext.Consumer>
                {(authUser) => (authUser ? <SidebarNavAuth /> : <NavigationNonAuth />)}
            </AuthUserContext.Consumer>
        </Sidebar>
    );
}

const SidebarNavAuth = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
            <StyledSidebarHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={logo} style={{ width: '30px', height: '30px' }} />
                    <h2 style={{ marginLeft: '10px' }}>WorkWhile</h2>
                </div>
            </StyledSidebarHeader>

            <Menu menuItemStyles={menuItemStyles}>
                <MenuItem component={<Link to={ROUTES.USERS} />} icon={<MdPerson />} >
                    Users
                </MenuItem>
                <SubMenu label="Shifts" icon={<MdSort />} rootStyles={{ color: theme.menu.menuItemText }}>
                    <MenuItem component={<Link to={ROUTES.UPCOMING_SHIFTS} className="nav-link" />}>
                        Upcoming Shifts
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.ONGOING_SHIFTS} className="nav-link" />}>
                        Ongoing Shifts
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.PAST_SHIFTS} className="nav-link" />}>
                        Past Shifts
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.SINGLE_SHIFT} className="nav-link" />}>
                        Single Shift
                    </MenuItem>
                </SubMenu>

                <MenuItem component={<Link to={ROUTES.COMPANY} className="nav-link" />} icon={<MdHouse />}>
                    Company
                </MenuItem>
                <MenuItem component={<Link to={ROUTES.POSITIONS} className="nav-link" />} icon={<MdPhone />}>
                    Positions
                </MenuItem>
                <MenuItem component={<Link to={ROUTES.MESSAGES2} className="nav-link" />} icon={<MdMessage />}>
                    Messages
                </MenuItem>
                <MenuItem component={<Link to={ROUTES.PAYMENTS} className="nav-link" />} icon={<MdPayments />}>
                    Receipts
                </MenuItem>

                <MenuItem component={<Link to={ROUTES.REVIEW} className="nav-link" />} icon={<MdChecklist />}>
                    Review
                </MenuItem>

                <MenuItem component={<Link to={ROUTES.COMMANDS} className="nav-link" />} icon={<MdLaptop />}>
                    Commands
                </MenuItem>


                <MenuItem component={<Link to={ROUTES.POSITION_EDITOR} className="nav-link" />} icon={<MdFolderOpen />}>
                    Position Editor
                </MenuItem>


                <SubMenu label="More Options" icon={<MdList />}>
                    <MenuItem component={<Link to={ROUTES.TUTORIALS} className="nav-link" />}>
                        Tutorials
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.BIZLEADS} className="nav-link" />}>
                        Scraped Leads
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.WORKERBIZREFERRALS} className="nav-link" />}>
                        Worker-{">"}Biz Referrals
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.LOGS} className="nav-link" />}>
                        Logs
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.QUEST_DEFINITIONS} className="nav-link" />}>
                        Quest Defintions
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.CHECKR} className="nav-link" />}>
                        Checkr
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.TIPS} className="nav-link" />}>
                        Tips
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.SPREADSHEETS} className="nav-link" />}>
                        Spreadsheets
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.LISTINGS} className="nav-link" />}>
                        Listings
                    </MenuItem>
                    <MenuItem component={<Link to={ROUTES.SHIFT_ANALYTICS} className="nav-link" />}>
                        Shift Analytics
                    </MenuItem>
                </SubMenu>

                <SignOutButton className="submit-button-smaller" />

            </Menu>
        </div>
    );
}

const NavigationNonAuth = () => <div />;

export default SidebarNav;


const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;
