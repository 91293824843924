import React, { useEffect, useState } from "react";
import api from "../../../api";
import graphql from "../../../graphql";
import Loading from "../../Shared/loading";
import StatusSetter from "../../Shared/StatusSetter";
import { FILLED_STATUSES, shouldShowOvernightBanner } from "../../utils/util";
import * as moment from "moment-timezone";
import { WorkShift } from "../../Shared/common_defs";
import {
  dateCommaTimeFormat,
  getShiftTimezone,
} from "../../utils/general_util";

interface Props {
  shift: WorkShift;
  workId: number;
  workerId: string;
  fetchWorkStats?: (showLoading: boolean) => void;
  fetchWorkers?: (showLoading: boolean) => void;
  onActionSuccess?: (action: string) => void;
  removeShiftFromReviewList?: () => void;
  showMarkApprovedAction?: boolean;
  hideWorkerInfo?: boolean;
}

const statusOptions = [
  { label: "Pick one", value: null },
  { label: "scheduled", value: "scheduled" },
  { label: "started", value: "started" },
  { label: "completed", value: "completed" },
  { label: "rejected", value: "rejected" },
  { label: "removed", value: "removed" },
  { label: "bailed", value: "bailed" },
  { label: "admin_review", value: "admin_review" },
  { label: "employer_review", value: "employer_review" },
];

const WorkerDetail = ({
  workerId,
  workId,
  shift,
  fetchWorkStats,
  fetchWorkers,
  onActionSuccess,
  showMarkApprovedAction,
  removeShiftFromReviewList,
  hideWorkerInfo,
}: Props) => {
  // use user id passed from prop above to grab user detail info

  const timezone = getShiftTimezone(shift);

  const [fetchingWorkDetail, setFetchingWorkDetail] = useState(false);
  const [workDetail, setWorkDetail] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [submittingStatus, setSubmittingStatus] = useState(false);
  const [clockIn, setClockIn] = useState("");
  const [clockOut, setClockOut] = useState("");
  const [submittingClockIn, setSubmittingClockIn] = useState(false);
  const [submittingClockOut, setSubmittingClockOut] = useState(false);
  const [submittingLeftEarly, setSubmittingLeftEarly] = useState(false);
  const [userWorkedOvernight, setUserWorkedOvernight] = useState(false);

  const workDetailQuery = (workId) => {
    return `
            admin { 
                work (workId:${workId}) {
                    id, status, oncall, leftEarly, reasonEarlyClockout, supervisorReasonEarlyClockOut, supervisorMarkedNoShow, isCancelledForIllness, noShowMarkedByShiftLeadId,
                    confirmedAt, travelMode,
                    startedAt, completedAt,
                    worker {phoneNumber, userStats {shiftCount, penaltyCount}}
                    trips { embedUrl }
                    isReplaceable
                    replacedWork {
                      id,
                      worker {
                        id
                        name
                      }
                    }
                    replacedAt
                }
            }`;
  };

  useEffect(() => {
    fetchWorkDetail();
  }, []);

  useEffect(() => {
    if (workDetail) {
      if (workDetail.startedAt) {
        const formattedClockIn = moment
          .tz(workDetail.startedAt, timezone)
          .format("YYYY-MM-DDTHH:mm");
        setClockIn(formattedClockIn);
      }
      if (workDetail.completedAt) {
        const formattedClockOut = moment
          .tz(workDetail.completedAt, timezone)
          .format("YYYY-MM-DDTHH:mm");
        setClockOut(formattedClockOut);
      }
    }
  }, [workDetail]);

  useEffect(() => {
    if (clockIn && clockOut && shouldShowOvernightBanner(clockIn, clockOut)) {
      setUserWorkedOvernight(true);
    } else if (
      (!clockIn || clockIn === "") &&
      shouldShowOvernightBanner(
        moment.tz(shift.startsAt, timezone).format("YYYY-MM-DDTHH:mm"),
        clockOut
      )
    ) {
      setUserWorkedOvernight(true);
    } else {
      setUserWorkedOvernight(false);
    }
  }, [clockIn, clockOut]);

  const fetchWorkDetail = () => {
    setFetchingWorkDetail(true);
    graphql(workDetailQuery(workId))
      .then((response) => {
        const workData = response.data.data.admin.work;
        if (workData && workData.length > 0) {
          setWorkDetail(workData[0]);
        }
        setFetchingWorkDetail(false);
      })
      .catch(() => {
        setFetchingWorkDetail(false);
      });
  };

  const submitStatusChange = () => {
    const currentStatus = workDetail.status;
    if (!newStatus || newStatus === "") {
      alert("You must select a status.");
      return;
    }
    if (newStatus === currentStatus) {
      alert(
        `USER(${workerId}) is already in ${currentStatus.toUpperCase()} status.`
      );
      return;
    }
    setSubmittingStatus(true);
    const r = window.confirm(
      `You are about to change USER(${workerId})'s status to ${newStatus.toUpperCase()}. To execute this action, please press Ok.`
    );
    if (r === true) {
      api
        .post(`/admin/shift/${workerId}/${shift.id}/status/${newStatus}`)
        .then((response) => {
          fetchWorkDetail();
          if (fetchWorkStats) fetchWorkStats(false);
          if (fetchWorkers) fetchWorkers(false);
          alert(
            `Changed status to:${newStatus} for user(${workerId})/shift(${shift.id}`
          );
          setNewStatus(null);
          setSubmittingStatus(false);
          if (onActionSuccess) {
            onActionSuccess("set_status");
          }
        })
        .catch((error) => {
          setSubmittingStatus(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingStatus(false);
    }
  };

  const submitClockIn = () => {
    if (!clockIn || clockIn === "") {
      alert("You must enter a clock in time.");
      return;
    }
    setSubmittingClockIn(true);
    const formattedTime = moment.tz(clockIn, timezone);
    const clockedInAtISO = formattedTime.toISOString();

    const r = window.confirm(
      `You are about to change clock in time to:${clockIn} for user(${workerId})/shift(${shift.id}). To execute this action, please press Ok.`
    );
    if (r === true) {
      api
        .post(
          `/admin/shift/${workerId}/${shift.id}/clockedIn/${clockedInAtISO}`
        )
        .then((response) => {
          alert(
            `Changed clock in time to:${clockIn} for user(${workerId})/shift(${shift.id}`
          );
          setClockIn("");
          setSubmittingClockIn(false);
          fetchWorkDetail();
          if (onActionSuccess) {
            onActionSuccess("set_clock_in");
          }
        })
        .catch((error) => {
          setSubmittingClockIn(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingClockIn(false);
    }
  };

  const submitClockOut = () => {
    if (!clockOut || clockOut === "") {
      alert("You must enter a clock out time.");
      return;
    }
    setSubmittingClockOut(true);
    const formattedTime = moment.tz(clockOut, timezone);
    const clockedOutAtISO = formattedTime.toISOString();

    const r = window.confirm(
      `You are about to change clock out time to:${clockOut} for user(${workerId})/shift(${shift.id}). To execute this action, please press Ok.`
    );
    if (r === true) {
      api
        .post(
          `/admin/shift/${workerId}/${shift.id}/clockedOut/${clockedOutAtISO}`
        )
        .then((response) => {
          alert(
            `Changed clock out time to:${clockOut} for user(${workerId})/shift(${shift.id}`
          );
          setClockOut("");
          setSubmittingClockOut(false);
          fetchWorkDetail();
          if (onActionSuccess) {
            onActionSuccess("set_clock_out");
          }
        })
        .catch((error) => {
          setSubmittingClockOut(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingClockOut(false);
    }
  };

  const submitLeftEarly = (left_early) => {
    if (left_early === null || left_early === undefined) {
      alert("left_early cannot be null");
      return;
    }
    setSubmittingLeftEarly(true);

    const r = window.confirm(
      `You are about to set USER(${workerId}) left early to ${left_early} for shift(${shift.id}). To execute this action, please press Ok.`
    );

    if (r === true) {
      api
        .post(`/admin/shift/${workerId}/${shift.id}/leftEarly`, {
          left_early,
        })
        .then((response) => {
          alert(
            `Changed left_early to:${left_early} for user(${workerId})/shift(${shift.id}`
          );
          setSubmittingLeftEarly(false);
          fetchWorkDetail();
          if (onActionSuccess) {
            onActionSuccess("set_left_early");
          }
        })
        .catch((error) => {
          setSubmittingLeftEarly(false);
        });
    } else {
      alert("Action Cancelled!");
      setSubmittingLeftEarly(false);
    }
  };

  const doneAdminReviewing = () => {
    api.post(`/admin/work/${workId}/admin_review_approve`).then(() => {
      if (removeShiftFromReviewList) {
        removeShiftFromReviewList();
      }
      alert(
        `Successfully done reviewing for assoc(${workId})/user(${workerId})/shift(${shift.id}`
      );
      if (onActionSuccess) {
        onActionSuccess("done_admin_reviewing");
      }
    });
  };

  const markAsApproved = () => {
    api
      .post("/admin/shifts/approve", {
        shift_ids: [workId],
      })
      .then((response) => {
        if (removeShiftFromReviewList) {
          removeShiftFromReviewList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const NoShowInfo = ({ workDetail }) => {
    if (workDetail.noShowMarkedByShiftLeadId) {
      return (
        <>
          <p>Shift Lead Marked No Show: True</p>
          <p>Shift Lead ID: {workDetail.noShowMarkedByShiftLeadId}</p>
        </>
      );
    }

    return (
      <p style={{ color: workDetail.supervisorMarkedNoShow ? "red" : "black" }}>
        Supervisor Marked No Show:{" "}
        {workDetail.supervisorMarkedNoShow ? "True" : "False"}
      </p>
    );
  };

  if (fetchingWorkDetail) {
    return <Loading type="button" />;
  }

  if (!workDetail) {
    return <p>Could not find work detail for this worker.</p>;
  }

  return (
    <div>
      {!hideWorkerInfo && (
        <p style={{ color: "rgba(0,0,0,0.5)", marginBottom: 0 }}>
          (workId: {workDetail.id}, phone: {workDetail.worker.phoneNumber},
          confirmedAt: {workDetail.confirmedAt}, replacedAt:
          {workDetail.replacedAt}, replacedBy:{" "}
          {workDetail.replacedWork?.worker?.name}, travelMode:{" "}
          {workDetail.travelMode}, totalShifts:{" "}
          {workDetail.worker?.userStats?.shiftCount
            ? workDetail.worker.userStats.shiftCount
            : 0}
          , penalties:{" "}
          {workDetail.worker?.userStats?.penaltyCount
            ? workDetail.worker.userStats.penaltyCount
            : 0}
          ,
          <span>
            {workDetail.startedAt ? (
              <span style={{ fontWeight: "bold" }}>
                startedAt:{" "}
                {moment
                  .tz(workDetail.startedAt, timezone)
                  .format(dateCommaTimeFormat)}
                ,
              </span>
            ) : (
              <span>startedAt: N/A, </span>
            )}
            {workDetail.completedAt ? (
              <span style={{ fontWeight: "bold" }}>
                completedAt:{" "}
                {moment
                  .tz(workDetail.completedAt, timezone)
                  .format(dateCommaTimeFormat)}
              </span>
            ) : (
              <span>completedAt: N/A</span>
            )}
          </span>
          {workDetail.trips &&
            workDetail.trips.map((trip, idx) => (
              <>
                ,{" "}
                <a href={trip.embedUrl} target="_blank">
                  location-{idx}
                </a>
              </>
            ))}
          )
        </p>
      )}
      <StatusSetter
        label="Set Status"
        name="newStatus"
        value={newStatus}
        handleChange={(e) => setNewStatus(e.target.value)}
        isLoading={submittingStatus}
        submitStatusChange={submitStatusChange}
        options={statusOptions}
        buttonType="confirm"
      />
      {!FILLED_STATUSES.includes(workDetail.status) && (
        <div className="alert alert-info" style={{ padding: 2, fontSize: 9 }}>
          Status needs to be SET to a FILLED status (i.e. scheduled, started,
          completed, approved, employer_review, employer_approved, paid) before
          clock in/out times can be set.
        </div>
      )}
      <>
        {FILLED_STATUSES.includes(workDetail.status) && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                Started work at (
                {moment.tz(shift.startsAt, timezone).format("zz")}):
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="datetime-local"
                  name="clockIn"
                  value={clockIn}
                  onChange={(e) => setClockIn(e.target.value)}
                ></input>
                {!submittingClockIn && (
                  <button className="confirm-button" onClick={submitClockIn}>
                    Set Start
                  </button>
                )}
                {submittingClockIn && <Loading type="button" />}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                Finished work at (
                {moment.tz(shift.endsAt, timezone).format("zz")}):
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="datetime-local"
                  name="clockOut"
                  value={clockOut}
                  onChange={(e) => setClockOut(e.target.value)}
                ></input>
                {!submittingClockOut && (
                  <button className="confirm-button" onClick={submitClockOut}>
                    Set End
                  </button>
                )}
                {submittingClockOut && <Loading type="button" />}
              </div>
              {userWorkedOvernight && (
                <div style={{ color: "red" }}>
                  User({workerId}) worked overnight
                </div>
              )}
            </div>
          </>
        )}
      </>
      <>
        {FILLED_STATUSES.includes(workDetail.status) && (
          <>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 5 }}
            >
              <p style={{ marginRight: 5 }}>Left Early:</p>
              {!submittingLeftEarly && (
                <>
                  <button
                    className="confirm-button"
                    disabled={workDetail.leftEarly}
                    style={{
                      backgroundColor: workDetail.leftEarly ? "#27c8b6" : "",
                      marginRight: 5,
                    }}
                    onClick={() => submitLeftEarly(true)}
                  >
                    {workDetail.leftEarly && "✓ "}True
                  </button>
                  <button
                    className="confirm-button"
                    disabled={!workDetail.leftEarly}
                    style={{
                      backgroundColor: workDetail.leftEarly ? "" : "#27c8b6",
                    }}
                    onClick={() => submitLeftEarly(false)}
                  >
                    {!workDetail.leftEarly && "✓ "}False
                  </button>
                </>
              )}

              {submittingLeftEarly && <Loading type="button" />}
            </div>
            {workDetail.leftEarly && (
              <>
                <p>
                  Worker Reason Early Clockout:{" "}
                  {workDetail.reasonEarlyClockout || "N/A"}
                </p>
                <p>
                  Supervisor Reason Early Clock Out:{" "}
                  {workDetail.supervisorReasonEarlyClockOut || "N/A"}
                </p>
              </>
            )}
          </>
        )}
      </>
      <br />
      <br />
      <NoShowInfo workDetail={workDetail} />
      {workDetail.status === "admin_review" && (
        <button onClick={() => doneAdminReviewing()}>Done reviewing</button>
      )}
      <br />
      {showMarkApprovedAction && (
        <button onClick={() => markAsApproved()}>Mark Approved</button>
      )}
    </div>
  );
};

export default WorkerDetail;
