import React from 'react';

import { withAuthorization } from '../Session';
import TutorialBuilder from './TutorialBuilder';
import TutorialDisplay from './TutorialDisplay';
import TutorialFileUpload from './TutorialFileUpload';

const TutorialsContainer = () => (
    <div>
        <div>
            <h1>Build a tutorial</h1>
            <TutorialBuilder />
            <br />
            <br />
            <hr />
            <h1>Display tutorial from JSON</h1>
            <TutorialDisplay />
            <br />
            <br />
            <hr />
            <TutorialFileUpload />
        </div>
    </div>
);

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(TutorialsContainer);
