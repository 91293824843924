import React, { useEffect, useState } from "react";
import "../Home/home.css";
import { withAuthorization } from "../Session";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import graphql from "../../graphql";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { defs } from "../Shared/defs";
import Loading from "../Shared/loading";

const animatedComponents = makeAnimated();

const INITAL_MARKET = { value: "sfo", label: "SF Bay Area" };

const WorkerBusinessReferral = () => {
  const [leads, setLeads] = useState([]);
  const [selectedOption, setSelectedOption] = useState(INITAL_MARKET);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLeads(selectedOption ? selectedOption.value : null);
  }, []);

  const handleFilterChange = (selectedOption, actionMeta) => {
    setSelectedOption(selectedOption);
    fetchLeads(selectedOption ? selectedOption.value : null);
  };

  const fetchLeads = (market) => {
    setIsLoading(true);
    graphql(`
          admin { 
            businessLeads (
              market: "${market}",
              leadType: "worker",
            ) {
              createdAt, jobName, businessName, location, pay, link, market
              contactName, contactPhoneNumber, contactJobTitle, competitorName, workerId
            }
          }`)
      .then((response) => {
        console.log("response: ", response);
        setLeads(response.data.data.admin.businessLeads);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-5">
      <h1>Worker -{`>`} Biz Referrals</h1>
      <Select
        isSearchable
        options={[
          { value: "sfo", label: "SF Bay Area" },
          { value: "lax", label: "LA and OC" },
          { value: "dfw", label: "Dallas" },
          { value: "sea", label: "Seattle" },
          { value: "atl", label: "Atlanta" },
          { value: "nyc", label: "NY/NJ" },
          { value: "mia", label: "Miami" },
          { value: "iah", label: "Houston" },
          { value: "phx", label: "Phoenix" },
          { value: "atx", label: "Austin" },
          { value: "den", label: "Denver" },
          { value: "atx", label: "Austin" },
          { value: "lan", label: "Lancaster York Harrisburg" },
        ]}
        components={animatedComponents}
        styles={customStyles}
        name="market"
        placeholder="Market"
        value={selectedOption}
        onChange={handleFilterChange}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="users-table mt-5">
          <BootstrapTable
            striped
            hover
            keyField="name"
            data={leads}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

const columns = [
  {
    dataField: "createdAt",
    text: "Created At",
  },
  {
    dataField: "businessName",
    text: "Business Name",
  },
  {
    dataField: "contactName",
    text: "Contact Info",
    formatter: (cell, row) => {
      return (
        <div>
          <p>Contact Name: {row.contactName || "N/A"}</p>
          <p>Contact Phone Number: {row.contactPhoneNumber || "N/A"}</p>
          <p>Contact Job Title: {row.contactJobTitle || "N/A"}</p>
        </div>
      );
    },
  },
  {
    dataField: "market",
    text: "Market",
  },
  {
    dataField: "workerId",
    text: "Other",
    formatter: (cell, row) => {
      return (
        <div>
          <p>workerId: {cell}</p>
          <p>competitor name: {row.competitorName || "N/A"}</p>
        </div>
      );
    },
  },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    maxWidth: 300,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
  }),
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorkerBusinessReferral);
