import React from 'react';
import api from '../../api'
import '../Home/home.css';


const INITIAL_STATE = {
    user_ids: '',
    message: '',
    title: '',
    error: '',
};

class Push extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        const { user_ids, message, title } = this.state;

        if (!user_ids || !message || user_ids === '' || message === ''){
            alert('Please complete all required fields')
            return;
        }

        const r = window.confirm(`You are about to send a push with title: "${title}" and message: "${message}" to USER(s) ${user_ids}. To execute this action, please press Ok.`);
        if (r == true) {
            let user_ids_array = user_ids.split(',');
            api.post('/admin/push', {
                user_ids: user_ids_array,
                message,
                title
            }).then((response) => {
                console.log(response);
                alert("Sent!")
                this.setState({ ...INITIAL_STATE })
            }).catch((error) =>    {
                alert("Something went wrong")
                console.log(error);
            });
        } else {
            alert('Action Cancelled!')
            this.setState({submitting: false});
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {user_ids, message, title, error} = this.state;

        return (
            <div>
                <h1>Send a Push</h1>
                <form onSubmit={this.onSubmit} className="form">
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <label>User ID(s)</label>
                        <input
                            className="input"
                            name="user_ids"
                            value={user_ids}
                            onChange={this.onChange}
                            type="text"
                            placeholder="User IDs (csv, no spaces)"
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <label>Message</label>
                        <input
                            className="input"
                            name="message"
                            value={message}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Message"
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <label>Title (optional, but recommended)</label>
                        <input
                            className="input"
                            name="title"
                            value={title}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Title"
                        />
                    </div>
                    <button type="submit" className="confirm-button" style={{minWidth:'150px'}}>
                        Send
                    </button>

                    {error && <p>{error.message}</p>}
                </form>
            </div>
        );
    }
}

export default Push;
