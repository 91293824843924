import React from 'react';
import './shared.css';

function Loading(props) {
  const { type, c } = props;
  let cssClass = 'loader';
  if (type === "button") {
    cssClass = "button-loader";
  }
  return (
    <div className="container custom-container" style={{ position: 'relative' }}>
      <div className={cssClass} />
    </div>
  );
}

export default Loading;
