import React from 'react';

import { withFirebase } from '../Firebase';
import { MdOutlineLogout } from "react-icons/md";

const SignOutButton = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut} style={{ margin: '20px' }} className='border-button'>
    <MdOutlineLogout />
  </button>
);

export default withFirebase(SignOutButton);
