import React from 'react';
import { defs } from './defs';
import Loading from './loading';

interface Props {
    label: string,
    name: string,
    value: string,
    options: Array<SelectOption>,
    handleChange: (e:any) => void,
    isLoading: boolean,
    submitStatusChange: () => void,
    buttonText?: string,
    buttonType?: string,
}

interface SelectOption {
    label: string,
    value: string,
}

const StatusSetter = ({label, name, value, options, handleChange, isLoading, submitStatusChange, buttonText, buttonType}:Props) => {
    return (
        <div>
            <span style={{color: defs.mediumBlack}}>{label}</span>
            <div style={{display:'flex', flexDirection:'row', paddingBottom:'5px', marginBottom: '5px'}}>
              <select name={name} required value={value} onChange={(e)=>handleChange(e)}>
                  {options.map(option => <option value={option.value}>{option.label}</option>)}
              </select>
              {!isLoading && <button className={buttonType === 'confirm' ? "confirm-button" : null} onClick={()=>submitStatusChange()}>{buttonText || 'Set Status'}</button>}
              {isLoading && <Loading type='button' />}
            </div>
        </div>
    )
}

export default StatusSetter;