import React, { useEffect, useState } from 'react';
import '../Home/home.css';
import { withAuthorization } from "../Session";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import graphql from "../../graphql";
import LazyLoadingMsg from "../Shared/LazyLoadingMsg";
import { FILTERS, filterShifts, filterShiftsLocally, getRowStyles } from "../utils/util";
import FilterBar from "../Shared/ShiftsFilterBar";
import { companiesForFiltersQuery, getShiftColumns, shiftsQuery } from './shifts';
import moment from 'moment';
import ShiftsFilterBar2 from '../Shared/ShiftsFilterBar2';
import Loading from '../Shared/loading';
import { useHistory, useParams, useLocation } from "react-router";

const UpcomingShifts: React.FC = () => {
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search)

    const [filters, setFilters] = useState({
        companyIds: searchParams.get('companyIds') ? searchParams.get('companyIds').split(',').map(Number) : [],
        shiftId: searchParams.get('shiftId') ? Number(searchParams.get('shiftId')) : null,
        fromDate: searchParams.get('fromDate') ? moment(searchParams.get('fromDate')).format('YYYY-MM-DD') : null,
        toDate: searchParams.get('toDate') ? moment(searchParams.get('toDate')).format('YYYY-MM-DD') : null,
        market: searchParams.get('market'),
        showUnfilledShiftsOnly: false,
        showCancelledShiftsOnly: false,
    });

    const [fetching, setFetching] = useState(false);
    const [fetchingFilters, setFetchingFilters] = useState(false);
    const [shifts, setShifts] = useState([]);
    const [columns, setColumns] = useState(getShiftColumns('upcoming', null));
    const [needToFetchOnFirstLoad, setNeedToFetchOnFirstLoad] = useState(Array.from(searchParams).length > 0);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        setFetchingFilters(true);
        graphql(companiesForFiltersQuery("upcoming")).then(response => {
            const companies = response.data.data.admin.companiesForFilters;
            setCompanyOptions(companies);
            setFetchingFilters(false);
        });
    }, []);

    useEffect(() => {
        const params = new URLSearchParams();
        if (filters.companyIds.length > 0) {
            params.set('companyIds', filters.companyIds.join(','));
        }
        if (filters.shiftId) {
            params.set('shiftId', String(filters.shiftId));
        }
        if (filters.market) {
            params.set('market', String(filters.market));
        }
        if (filters.fromDate) {
            params.set('fromDate', filters.fromDate);
        }
        if (filters.toDate) {
            params.set('toDate', filters.toDate);
        }
        history.push({ search: params.toString() });
        if (needToFetchOnFirstLoad) {
            fetchShifts();
            setNeedToFetchOnFirstLoad(false);
        }
    }, [filters]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFilters(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFilters(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };


    const fetchShifts = () => {
        setFetching(true);
        const fromDateToUse = !filters.fromDate ? moment().local().format('YYYY-MM-DD') : filters.fromDate;
        const toDateToUse = !filters.toDate ? moment().local().add(2, 'days').format('YYYY-MM-DD') : filters.toDate;
        graphql(shiftsQuery({type: "upcoming", startDate: fromDateToUse, endDate: toDateToUse, companyIds: filters.companyIds, shiftId: filters.shiftId, market: filters.market})).then(response => {
            setShifts(response.data.data.admin.shifts);
            setFetching(false);
        })
    }

    return (
        <form className="users-table mt-2" onSubmit={(e) => {
            e.preventDefault();
            fetchShifts();
        }}>
            <h3>Upcoming shifts ({'>'}30mins away)</h3>
            {fetchingFilters && <Loading />}
            {!fetchingFilters && <ShiftsFilterBar2 {...filters} companyOptions={companyOptions} handleInputChange={handleInputChange} handleCheckboxChange={handleCheckboxChange} />}
            <p>Note: By default the dates filter for the next 2 days</p>
            <button type="submit" className='submit-button-smaller'>
                Load shifts
            </button>
            {fetching && <Loading />}
            {shifts && shifts.length > 0 && !fetching && <BootstrapTable striped hover
                keyField='id' data={filterShiftsLocally(shifts, filters.showCancelledShiftsOnly, filters.showUnfilledShiftsOnly)} columns={columns} rowStyle={getRowStyles} />}
        </form>
    );

}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(UpcomingShifts);
