import React from 'react';
import '../Home/home.css';
import api from '../../api';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'


const INITIAL_STATE = {
  description: null,
  itemType: null,
  unitAmount: null,
  quantity: null,
};

class PaymentChargeItem extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { description, itemType, unitAmount, quantity, userId } = this.state;
    const {chargeId} = this.props;

    if (!description || !itemType || !unitAmount || !quantity) {
      return;
    }

    let data = {
      description: description,
      item_type: itemType,
      quantity: quantity,
      unit_amount: unitAmount,
    }
    if (userId) {
      data.user_id = userId;
    }
    api.post(`/admin/charges/${chargeId}/items`, data)
      .then((response) => {
        console.log(response);
        alert("item added")
        this.setState({
          description: '',
          itemType: null,
          unitAmount: '',
          quantity: '',
          userId: ''
        });
      })
      .catch((err) =>    {
        console.log("ERROR OCCURED: ", err);
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelect = (e) =>{
    this.setState({itemType: e})
  }

  render() {
    const {description, itemType, unitAmount, quantity, userId, error} = this.state;

    console.log("values : ", description, quantity)
    const isInvalid = description === '' || quantity === '';
    return (
      <div>
        <h5>Add charge item</h5>
        <form onSubmit={this.onSubmit} className="d-flex flex-row">
          <input
            className="input-small"
            name="description"
            value={description}
            onChange={this.onChange}
            type="text"
            placeholder="Description"
          />
          <DropdownButton
            title={itemType ? itemType : "Item type"}
            id="dropdown-menu-align-right"
            onSelect={this.handleSelect}
          >
            <Dropdown.Item eventKey="shift">shift</Dropdown.Item>
            <Dropdown.Item eventKey="reimbursement">reimbursement</Dropdown.Item>
            <Dropdown.Item eventKey="bonus">bonus</Dropdown.Item>
            <Dropdown.Item eventKey="temp_to_hire">temp_to_hire</Dropdown.Item>
            <Dropdown.Item eventKey="discount">discount</Dropdown.Item>
            <Dropdown.Item eventKey="misc">misc</Dropdown.Item>
          </DropdownButton>
          <input
            className="input-small"
            name="quantity"
            value={quantity}
            onChange={this.onChange}
            type="text"
            placeholder="Quantity"
          />
          <input
            className="input-small"
            name="unitAmount"
            value={unitAmount}
            onChange={this.onChange}
            type="text"
            placeholder="Unit amount"
          />
          <input
            className="input-small"
            name="userId"
            value={userId}
            onChange={this.onChange}
            type="text"
            placeholder="User id (optional)"
          />
          <button disabled={isInvalid} type="submit" className="ml-2">
            Add
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

export default PaymentChargeItem;
