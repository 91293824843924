import React, { Component } from "react";
import styled from "styled-components";
import Select from "react-select";
import { withAuthorization } from "../Session";
import { defs } from "./defs";
import { device } from "./device";
import makeAnimated from "react-select/animated";

interface Props {
  selects: Array<Select>;
  instruction: string;
}

const Filter = ({ selects, instruction }: Props) => {
  return (
    <FilterBarWrapper showBorderTop={true} showBorderBottom={true}>
      <Instruction>{ instruction ? instruction : 'Filter by:'}</Instruction>
      <SelectGroup>{selects}</SelectGroup>
    </FilterBarWrapper>
  );
};
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Filter);

const FilterBarWrapper = styled.div`
  border-bottom: ${(props) => (props.showBorderBottom ? "1px solid" : null)};
  border-top: ${(props) => (props.showBorderTop ? "1px solid" : null)};
  border-color: ${defs.veryLightBlack};
  padding: ${defs.paddingS};
  padding-left: ${defs.paddingXL};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  @media ${device.smallerThanMobileL} {
    font-size: ${defs.fontS};
    height: 100%;
    padding-left: ${defs.paddingM};
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Instruction = styled.div`
  color: ${defs.mediumBlack};
  margin-right: ${defs.marginM};
  font-size: ${defs.fontS};
`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
};

export const animatedComponents = makeAnimated();
