import React from 'react';
import '../Home/home.css';
import {withAuthorization} from "../Session";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import graphql from "../../graphql";
import { getShiftColumns, shiftsQuery } from './shifts';
import LazyLoadingMsg from '../Shared/LazyLoadingMsg';
import {filterShifts, FILTERS, getRowStyles} from "../utils/util";
import FilterBar from "../Shared/ShiftsFilterBar";
import moment from 'moment';

const INITIAL_STATE = {
    shifts: [],
    fetching: false,
    fromDate: moment().local().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: moment().local().format('YYYY-MM-DD'),
};

class PastShifts extends React.Component {

    constructor(props) {
        super(props);
        this.fetchShifts = this.fetchShifts.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.state = { ...INITIAL_STATE, filters:FILTERS };
        this.columns = getShiftColumns('past', this.fetchShifts);
    }

    componentDidMount() {
        this.fetchShifts()
    }

    fetchShifts() {
        const {fromDate, toDate} = this.state;
        this.setState({fetching: true});
        // to and from dates are backwards because we're querying for shifts in the past
        graphql(shiftsQuery({type: 'past', startDate: toDate, endDate: fromDate})).then(response => {
            this.setState({
                shifts: response.data.data.admin.shifts,
                fetching: false
            })
        })
    }

    render() {
        const {fetching, shifts, filters, fromDate, toDate} = this.state;

        const filteredShifts = filterShifts(shifts, filters);

        return (
            <div className="users-table mt-5">
                <LazyLoadingMsg in={fetching}>
                    We're loading your latest information...
                </LazyLoadingMsg>
                <h3>Past shifts</h3>
                <FilterBar
                    showBorderTop
                    showBorderBottom
                    showCompanyFilter
                    showLocationFilter
                    showPositionFilter
                    showWorkerFilter
                    showWorkerIdFilter
                    showShiftFilter
                    showListingFilter
                    showDateFilter
                    showStatusFilter
                    shifts={shifts}
                    handleFilterChange={this.handleFilterChange}
                    handleDateChange={this.handleDateChange}
                    fromDate={fromDate}
                    toDate={toDate}
                    showCancelledShiftsFilter={true}
                    showCancelledShifts={filters.showCancelledShifts}
                />
                <BootstrapTable striped hover
                                keyField='id'
                                data={ filteredShifts }
                                columns={this.columns}
                                rowStyle={getRowStyles} />
            </div>
        );
    }

    handleFilterChange(selectedOption, actionMeta) {
        const { filters } = this.state;
        filters[actionMeta.name] = selectedOption;
        this.setState({ filters });
    }

    handleDateChange(name, value){
        this.setState({
            [name]:value,
        }, () => this.fetchShifts());
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PastShifts);
