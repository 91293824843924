import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {fetchUpcomingShiftsForPosition} from "../../actions/company_actions";


interface Props {
    positionId: number;
    companyId: number;
}

const UpcomingShiftsOverview = ({positionId, companyId}:Props) => {
    const [shiftData, setShiftData] = React.useState(null)

    useEffect(() => {
        fetchShifts()
    }, []);

    function fetchShifts() {
        if (!positionId || !companyId) {
            return
        }
        if (companyId === undefined) {
            return
        }
        const responseCallback = (response) => {
            const shifts = response.data.data.admin.companies[0].shifts2.items
            setShiftData(shifts)
        }
        const errorCallback = (error) => {
            console.log(error)
        }
        fetchUpcomingShiftsForPosition(positionId, companyId, responseCallback, errorCallback)
    }

    function linkForShift(shiftId, startsAt, endsAt) {
        // Buffer start and end days by 1 to account for timezones in the search.
        let fromDate = new Date(startsAt);
        fromDate.setDate(fromDate.getDate() - 1);
        let toDate = new Date(endsAt);
        toDate.setDate(toDate.getDate() + 1);

        // Convert back to string
        let fromDateString = fromDate.toISOString().split('T')[0];
        let toDateString = toDate.toISOString().split('T')[0];

        return `/upcoming_shifts?shiftId=${shiftId}&fromDate=${fromDateString}&toDate=${toDateString}`
    }

    const leadShiftsFormatter = (cell, row) => {
      return (
        <>
          {cell
            ? cell.map((shift, index) => (
                <div key={index}>
                  <a
                    href={linkForShift(shift.id, shift.startsAt, shift.endsAt)}
                    target="_blank"
                  >
                    {shift.id}
                  </a>{" "}
                  <br />
                  Position ID: {shift.position.id}
                  <br />
                  Workers Needed: {shift.workersNeeded}
                </div>
              ))
            : "None"}
        </>
      );
    };


    const columns = [
      {
        dataField: "id",
        text: "Shift ID",
        formatter: (cell, row) => {
          return (
            <a
              href={linkForShift(cell, row.startsAt, row.endsAt)}
              target="_blank"
            >
              {cell}
            </a>
          );
        },
      },
      {
        dataField: "startsAt",
        text: "Starts At",
      },
      {
        dataField: "endsAt",
        text: "Ends At",
      },
      {
        dataField: "pay",
        text: "Pay",
        formatter: (cell, row) => {
          return "$" + cell;
        },
      },
      {
        dataField: "workersNeeded",
        text: "Workers Needed",
      },
      {
        dataField: "leadShifts",
        text: "Lead Shifts",
        formatter: leadShiftsFormatter,
      },
    ];
    return (<>
        {shiftData === null ? null :
            <BootstrapTable striped hover
            keyField='id' data={ shiftData } columns={columns} />}
        </>)
    }
export default UpcomingShiftsOverview
