import { useEffect } from 'react'

export interface MarkerProps {
  position: { lat: number; lng: number }
  title?: string
}

interface Props extends MarkerProps {
  map: google.maps.Map
}

export function Marker(props: Props) {
  const { position, title, map } = props

  useEffect(() => {
    const marker = new google.maps.Marker({
      position,
      title,
      map,
    })

    return () => {
      marker.setMap(null)
    }
  }, [])

  return null
}
