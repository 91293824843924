import React, { useEffect, useState } from "react";
import { User } from "../Shared/common_defs";
import graphql from "../../graphql";
import Loading from "../Shared/loading";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { dateCommaTimeFormat } from "../utils/general_util";

interface Props {
  user: User;
}

const userJobRequestsQuery = (user_id) => {
  return `
      admin { 
          user (userId:${user_id}) {
            listings2(listingsFilter:"requested") {
                cursor,
                items {
                    id,
                    company { id, name },
                    position { id, name }, 
                    location { 
                      id, address{ city, state, street, zip, lat, long, timezone } 
                    }
                    shifts {
                      id, startsAt, endsAt, lunchLength, payRate, payLumpSum
                      shiftBonuses { amount }
                    }
                }
            }
          }
      }`;
};

export const jobRequestColumns = [
  {
    dataField: "id",
    text: "Listing",
    sort: true,
    headerStyle: { width: "4em", fontSize: 12 },
    formatter: (cell, row) => {
      return (
        <>
          <p>
            <b>listing_id</b>:{cell}
          </p>
          <p>
            {row.company?.name}({row.company?.id}) ||
            {row.position?.name}({row.position?.id}) ||
            {row.location && row.location.address
              ? row.location.address.street +
                " " +
                row.location.address.city +
                " " +
                row.location.address.state +
                " " +
                row.location.address.zip +
                `(${row.location?.id})`
              : "No Address"}{" "}
          </p>
        </>
      );
    },
  },
  {
    dataField: "shifts",
    text: "Shifts",
    sort: true,
    headerStyle: { width: "6em", fontSize: 12 },
    formatter: (cell, row) => {
      const { location } = row;
      const timezone =
        location && location.address
          ? location.address.timezone
          : moment.tz.guess(true);
      const getShiftDetails = (shift: any) => {
        const startsAt = moment
          .tz(shift.startsAt, timezone)
          .format(dateCommaTimeFormat);
        const endsAt = moment
          .tz(shift.endsAt, timezone)
          .format(dateCommaTimeFormat);

        return (
          <>
            <p>
              <b>shift_id</b>:{shift.id}
            </p>
            <p>
              startsAt: {startsAt} || endsAt: {endsAt} || lunchLength:{" "}
              {shift.lunchLength}
            </p>
            <p>
              pay:{" "}
              {shift.payRate ? `$${shift.payRate}/hr` : `$${shift.payLumpSum}`}
              {shift.shiftBonuses &&
                shift.shiftBonuses.length > 0 &&
                ` || Bonus: $${shift.shiftBonuses[0].amount}`}
            </p>
          </>
        );
      };
      return (
        <>
          {row.shifts &&
            row.shifts.length > 0 &&
            row.shifts.map((shift) => getShiftDetails(shift))}
        </>
      );
    },
  },
];

const UserJobRequestsList = ({ user }: Props) => {
  const [submittingRequest, setSubmittingRequest] = useState(false);
  const [jobRequests, setJobRequests] = useState([]);

  useEffect(() => {
    requestJobRequests();
  }, []);

  const requestJobRequests = (e?) => {
    if (e) {
      e.preventDefault();
    }
    setJobRequests([]); // clear the current job requests list
    setSubmittingRequest(true);
    graphql(userJobRequestsQuery(user.id))
      .then((response) => {
        const workResponse = response.data.data.admin.user.listings2.items;
        if (workResponse) {
          setJobRequests(workResponse);
        }
        setSubmittingRequest(false);
      })
      .catch((error) => {
        setSubmittingRequest(false);
      });
  };

  if (submittingRequest) {
    return <Loading type="button" />;
  }

  return (
    <div>
      <p>
        <i>
          NOTE: User can be requested for a job via Request Specific Worker OR
          because they're on a List that the shift is restricted to
        </i>
      </p>
      <p>
        <i>NOTE: only shows up to 50 Job Requests for this user</i>
      </p>
      <p>
        <i>
          To add a worker to a shift, please use the "shift assign" command on
          the <a href="/commands">commands page</a>
        </i>
      </p>
      <div>
        {jobRequests && jobRequests.length > 0 ? (
          <>
            <BootstrapTable
              striped
              keyField="id"
              data={jobRequests}
              columns={jobRequestColumns}
              rowStyle={{ fontSize: 10 }}
            />
          </>
        ) : (
          <p>User has not been requested for any shifts</p>
        )}
      </div>
    </div>
  );
};

export default UserJobRequestsList;
