import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import graphql from "../../graphql";
import { withAuthorization } from "../Session";
import Loading from "../Shared/loading";
import UserCheckrVerification from "../Users/UserCheckrVerification";
import Filter, { animatedComponents, customStyles } from "../Shared/Filter";
import Select from "react-select";

const Checkr = () => {
  const [fetchingConsiderCandidates, setFetchingConsiderCandidates] =
    useState(false);
  const [candidates, setCandidates] = useState([]);
  const [marketFilter, setMarketFilter] = useState("");
  const [marketOptions, setMarketOptions] = useState([]);

  useEffect(() => {
    // Component did mount.
    fetchConsiderCandidates();
  }, []);

  useEffect(() => {
    // When filter changes, fetch again.
    fetchConsiderCandidates();
  }, [marketFilter]);

  const considerQuery = () => {
    return `
      admin {
        considerCandidates {
          id,
          name,
          status,
          checkrStatus,
          onboardingStatus {
            checkrSubmitted,
          },
          checkrMvrStatus,
          checkrId,
          market,

        }
      }`;
  };

  const filterCandidatesByMarket = (candidates) => {
    if (marketFilter.length === 0) {
      setCandidates(candidates);
      return;
    }
    let filteredList = [];
    candidates.map((c) => {
      if (c.market === marketFilter) {
        filteredList.push(c);
      }
    });
    setCandidates(filteredList);
  };

  const fetchConsiderCandidates = () => {
    setFetchingConsiderCandidates(true);
    graphql(considerQuery())
      .then((response) => {
        const candidates = response.data.data.admin.considerCandidates;
        if (candidates) {
          if (marketOptions.length === 0) {
            // Market Options have yet to be constructed.
            constructMarketOptions(candidates);
          }
          // Filter by market, if any.
          filterCandidatesByMarket(candidates);
          setFetchingConsiderCandidates(false);
        }
      })
      .catch(() => {
        setFetchingConsiderCandidates(false);
      });
  };

  const constructMarketOptions = (candidates) => {
    let marketOptionsArray = [];
    let marketSet = new Set<string>();
    for (const c of candidates) {
      let market = c.market;
      if (!marketSet.has(market)) {
        marketSet.add(market);
        marketOptionsArray.push({ label: c.market, value: c.market });
      }
    }
    setMarketOptions(marketOptionsArray);
  };

  if (fetchingConsiderCandidates) {
    return <Loading type="button" />;
  }

  const columns = [
    {
      dataField: "market",
      text: "Market",
    },
    {
      isDummyField: true,
      dataField: "action",
      text: "Basic Info",
      formatter: (cell, row, i, approved) => {
        return (
          <>
            {row.name}({row.id})
          </>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "onboardingStatus.checkrSubmitted",
      text: "Checkr Timestamp",
    },
    {
      isDummyField: true,
      dataField: "action",
      text: "Checkr Options",
      formatter: (cell, row, i, approved) => {
        return (
          <>
            <UserCheckrVerification
              user={row}
              refreshData={() => {
                // Force fetch.
                fetchConsiderCandidates();
              }}
              defaultOpen={false}
            ></UserCheckrVerification>
          </>
        );
      },
    },
  ];

  const onFilterChange = (selectedOption) => {
    setMarketFilter(selectedOption[0].value);
  };

  const marketSelect = (
    <Select
      isMulti
      isSearchable
      isClearable
      options={marketOptions}
      components={animatedComponents}
      styles={customStyles}
      name="market"
      placeholder="Market"
      onChange={onFilterChange}
    />
  );

  return (
    <div className="mt-5">
      <h1>Users Under Consideration</h1>
      <Filter selects={[marketSelect]}></Filter>
      <div className="users-table mt-5">
        <BootstrapTable
          striped
          hover
          keyField="name"
          data={candidates}
          columns={columns}
        />
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Checkr);
