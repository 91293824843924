import React, { useEffect, useState } from "react";
import moment from "moment";
import { updateTakePercentage } from "../../../actions/company_actions";
interface Props {
    cell;
    row;
}

const TakeRatesFormatter = ({cell, row} : Props) => {
    const [editedRates, setEditedRates] = React.useState(null);
    const [hasChanges, setHasChanges] = React.useState(false);
    const initialRatesRef = React.useRef(cell[0]);

    if (!cell || cell.length === 0) {
        alert('No Take Rate - this shouldnt be possible.');
        return <div>None</div>;
    }
    
    const handleRateChange = (field, value) => {
        const updatedRates = {
            ...editedRates,
            [field]: value
        };
        
        setEditedRates(updatedRates);
        // Check if the updated rates match the initial rates
        const hasAnyChanges = Object.keys(updatedRates).some(key => parseInt(updatedRates[key]) !== initialRatesRef.current[key]);
        setHasChanges(hasAnyChanges);
    };

    const handleSubmit = () => {
        const responseCallback = (response) => {
            alert('Success');
        }
        const errorCallback = (error) => {
            alert('Error');
            console.log(error)
        }

        updateTakePercentage(
            row.id,
            editedRates,
            responseCallback,
            errorCallback)
    };

    return (
        <div>
            {cell.sort((a, b) => moment(b.createdAt).diff(a.createdAt))
                .map((takeRate, index) => {
                    return (
                        <div key={index} style={{ marginBottom: '5px' }}>
                            {row.isW2 ? (
                                <>
                                    W2 Regular: <input type="text" defaultValue={takeRate.w2Regular} onChange={(e) => handleRateChange('w2Regular', e.target.value)} />%<br />
                                    W2 Overtime: <input type="text" defaultValue={takeRate.w2Overtime} onChange={(e) => handleRateChange('w2Overtime', e.target.value)} />%<br />
                                    W2 Double: <input type="text" defaultValue={takeRate.w2Double} onChange={(e) => handleRateChange('w2Double', e.target.value)} />%<br />
                                </>
                            ) : (
                                <>
                                    1099 Rate: <input type="text" defaultValue={takeRate.rate1099} onChange={(e) => handleRateChange('rate1099', e.target.value)} />%<br />
                                    Bonus: <input type="text" defaultValue={takeRate.bonus} onChange={(e) => handleRateChange('bonus', e.target.value)} />%<br />
                                    Tip: <input type="text" defaultValue={takeRate.tip} onChange={(e) => handleRateChange('tip', e.target.value)} />%<br />
                                </>
                            )}
                        </div>
                    );
                })
            }
            {hasChanges && <button onClick={handleSubmit}>Submit Changes</button>}
        </div>
    );
};

export default TakeRatesFormatter;