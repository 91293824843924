import React, { Component } from 'react';
import styled from 'styled-components';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { defs } from './defs';
import { device } from "./device";
import {parseStringifiedObjects} from '../utils/util';

const animatedComponents = makeAnimated();

class QuestDefinitionsFilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOptions: null,
      marketOptions: null,
    }
  }

  componentDidMount() {
    this.setFilterOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quest_definitions !== this.props.quest_definitions) {
      this.setFilterOptions();
    }
  }

  setFilterOptions() {
    const { quest_definitions } = this.props;
    this.setState({
      companyOptions: this.formatCompanyOptionsForQuestDefinitionsFilter(quest_definitions),
      marketOptions: this.formatMarketOptionsForQuestDefinitionsFilter(quest_definitions),
    })
  }

  render() {
    const {
      handleFilterChange,
      showBorderTop,
      showBorderBottom,
      showCompanyFilter,
      showMarketFilter,
    } = this.props;

    const {
      companyOptions,
      marketOptions,
    } = this.state;

    return (
      <FilterBarWrapper showBorderTop={showBorderTop} showBorderBottom={showBorderBottom}>
        <Instruction>Filter by:</Instruction>
        <SelectGroup>
          {showCompanyFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={companyOptions}
              components={animatedComponents}
              styles={customStyles}
              name="company"
              placeholder="Company"
              onChange={handleFilterChange}
            />
          )}
          {showCompanyFilter && showMarketFilter && <Instruction>&</Instruction>}
          {showMarketFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={marketOptions}
              components={animatedComponents}
              styles={customStyles}
              name="market"
              placeholder="Market"
              onChange={handleFilterChange}
            />
          )}
        </SelectGroup>
      </FilterBarWrapper>
    );
  }

  formatCompanyOptionsForQuestDefinitionsFilter = (quest_definitions) => {
    if (!quest_definitions) return null;
    let companies = new Set();
    for (const quest_definition of quest_definitions){
      if (quest_definition.company) {
        const formattedCompany =  { value: parseInt(quest_definition.company.id), label: quest_definition.company.name };
        if (companies.has(formattedCompany)) console.log('already in set!')
        companies.add(JSON.stringify(formattedCompany));
      }
    }
    const formattedSet = parseStringifiedObjects(companies)
    return Array.from(formattedSet);
  };

  formatMarketOptionsForQuestDefinitionsFilter = (quest_definitions) => {
    if (!quest_definitions) return null;
    let markets = new Set();
    for (const quest_definition of quest_definitions){
      if (quest_definition.market) {
        const formattedMarket =  { value: parseInt(quest_definition.market.id), label: quest_definition.market.name };
        if (markets.has(formattedMarket)) console.log('already in set!')
        markets.add(JSON.stringify(formattedMarket));
      }
    }
    const formattedSet = parseStringifiedObjects(markets)
    return Array.from(formattedSet);
  };

}

export default QuestDefinitionsFilterBar;

const FilterBarWrapper = styled.div`
  border-bottom: ${(props) => (props.showBorderBottom ? '1px solid' : null)};
  border-top: ${(props) => (props.showBorderTop ? '1px solid' : null)};
  border-color: ${defs.veryLightBlack};
  padding: ${defs.paddingS};
  padding-left: ${defs.paddingXL};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  @media ${device.smallerThanMobileL}{
    font-size: ${defs.fontS};
    height: 100%;
    padding-left: ${defs.paddingM}
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Instruction = styled.div`
  color: ${defs.mediumBlack};
  margin-right: ${defs.marginM};
  font-size: ${defs.fontS};
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
};
