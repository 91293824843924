import React from 'react';
import api from '../../api'
import '../Home/home.css';

const INITIAL_STATE = {
    listing_ad_id: '',
    status: '',
    error: '',
};

class ListingAdSetStatus extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        const { listing_ad_id, status,  } = this.state;

        if (listing_ad_id === '' || status === ''){
            alert('Please complete all required fields')
            return;
        }

        api.post('/admin/listing_ad/set_status', {
            listing_ad_id, status
        }).then((response) => {
            console.log(response);
            alert(`listing ad ${listing_ad_id} status has been set to ${status}`)
            this.setState({ ...INITIAL_STATE })
        }).catch((error) =>    {
            alert(`Error: ${error}`)
            console.log(error);
        });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { listing_ad_id, status, error } = this.state;

        return (
            <div>
                <h1>Listing Ad Set Status</h1>
                <form onSubmit={this.onSubmit} className="form">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label>Listing Ad Id*</label>
                            <input
                              className="input"
                              name="listing_ad_id"
                              value={listing_ad_id}
                              onChange={this.onChange}
                              type="text"
                              placeholder="e.g. 1234"
                            />
                            <label>Status*</label>
                            <select name="status" value={status} onChange={this.onChange}>
                                <option value="">Pick one...</option>
                                <option value="draft">draft</option>
                                <option value="active">active</option>
                                <option value="expired">expired</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <br />
                    <button type="submit" className="submit-button" style={{minWidth:'150px'}}>
                        Send
                    </button>
                    {error && <p>{error.message}</p>}
                </form>
            </div>
        );
    }
}

export default ListingAdSetStatus;
