import React, { useEffect, useState } from "react";
import graphql from "../../graphql";
import Moment from "react-moment";
import BootstrapTable from "react-bootstrap-table-next";
import api from "../../api";
import WorkerDetail from "../Shifts/Workers/WorkerDetail";
import { withAuthorization } from "../Session";
import PaymentReview from "../features/payment_review";
import moment from "moment";
import LazyLoadingMsg from "../Shared/LazyLoadingMsg";

const Review = () => {
  const [approved, setApproved] = useState({});
  const [fetchingData, setFetchingData] = useState(false);
  const [adminReviewWorkList, setAdminReviewWorkList] = useState(null);

  const worksToApproveQuery = () => {
    return `
            admin { 
                work (status: "admin_review") {
                    id, status, leftEarly, reasonEarlyClockout, supervisorReasonEarlyClockOut, supervisorMarkedNoShow, isCancelledForIllness
                    confirmedAt, travelMode,
                    supervisorMarkedNoShow,
                    noShowMarkedByShiftLeadId,
                    startedAt, completedAt,
                    worker {name, id, phoneNumber}
                    adminReviewDetail
                    associatedOffense{
                      userExplanation
                    }
                    shift {
                        id, startsAt, endsAt, lunchLength, supervisors { id, name, phoneNumber, userId, status},
                        company {name, id},
                        position {name, id},
                        location {name, id, address {city, state, street, zip, lat, long, timezone}}
                    }
                    adminReview {
                      workerClaimsTheyWorked, workerSubmittedDocsForBail
                    }
                }
            }`;
  };

  useEffect(() => {
    // componentDidMount - use the info we already asked for in shift query
    // we want to avoid multiple queries on mount
    fetchWork();
  }, []);

  const fetchWork = () => {
    setFetchingData(true);
    graphql(worksToApproveQuery())
      .then((response) => {
        setAdminReviewWorkList(
          response.data.data.admin.work.sort((a, b) =>
            a.completedAt > b.completedAt ? 1 : -1
          )
        );
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });
  };

  const removeShiftFromReviewList = (i) => {
    const newShifts = adminReviewWorkList;
    newShifts.splice(i, 1);
    setAdminReviewWorkList([...newShifts]);
  };

  const getActionsView = (work, i) => {
    let rejectButton = null;
    if (
      work.adminReview &&
      (work.adminReview.workerClaimsTheyWorked ||
        work.adminReview.workerSubmittedDocsForBail)
    ) {
      rejectButton = (
        <button onClick={() => rejectAdminReview(work)}>
          Reject review & Block worker
        </button>
      );
    }
    return (
      <>
        <WorkerDetail
          shift={work.shift}
          workId={work.id}
          workerId={work.worker.id}
          fetchWorkers={null}
          fetchWorkStats={null}
          onActionSuccess={(action) => onActionSuccess(action)}
          removeShiftFromReviewList={() => removeShiftFromReviewList(i)}
        />
        {rejectButton}
      </>
    );
  };

  const onActionSuccess = (action) => {
    if (action === "done_admin_reviewing") {
      // action after done reviewing an Admin Review case
      fetchWork();
    } else if (action === "set_left_early") {
      //action after setting left early
    }
  };

  const rejectAdminReview = (work) => {
    api.post(`/admin/work/${work.id}/admin_review_reject`).then(() => {
      alert(
        `Rejected review for assoc(${work.id})/user(${work.workerId}). Worker will be blocked and all shifts will be removed.`
      );
      fetchWork();
    });
  };

  const getColumns = () => [
    {
      dataField: "id",
      text: "ID",
      headerStyle: { width: "4em" },
    },
    {
      dataField: "worker",
      text: "Worker",
      formatter: (cell) => {
        return cell.name + " " + cell.id;
      },
    },
    {
      dataField: "shift",
      text: "Shift Info",
      formatter: (cell) => {
        return (
          <>
            <p>
              {cell.id} at {cell.company.name}
            </p>
            <p>
              Position: ({cell.position.id}) {cell.position.name}
            </p>
            <p>
              Location: ({cell.location.id}) {cell.location.name}{" "}
            </p>
            <p>
              Times: {moment(cell.startsAt).format("YYYY-MM-DD h:mm A")} -{" "}
              {moment(cell.endsAt).format("YYYY-MM-DD h:mm A")}
            </p>
            <p>LunchLength: {cell.lunchLength}</p>
            <p>Supervisor(s)</p>
            {cell.supervisors?.map((supervisor) => (
              <p>
                {supervisor.name}. Phone: {supervisor.phoneNumber || "N/A"}
              </p>
            ))}
          </>
        );
      },
    },
    {
      dataField: "startedAt",
      text: "Started",
      sort: true,
      formatter: (cell, row) => (
        <Moment format="YYYY-MM-DD h:mm A">{cell}</Moment>
      ),
    },
    {
      dataField: "completedAt",
      text: "Completed",
      sort: true,
      formatter: (cell, row) => (
        <Moment format="YYYY-MM-DD h:mm A">{cell}</Moment>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => {
        return <h5>{cell}</h5>;
      },
    },
    {
      dataField: "adminReviewDetail",
      text: "Detail",
      headerStyle: { width: "15em" },
      formatter: (cell, row) => {
        return (
          <>
            <p>{cell}</p>
            {row.associatedOffense && row.associatedOffense.userExplanation && (
              <p>Explanation: {row.associatedOffense.userExplanation}</p>
            )}

            {row.adminReview && row.adminReview.workerSubmittedDocsForBail && (
              <a
                href={`https://console.cloud.google.com/storage/browser/outfielders/${row.worker.id};tab=objects`}
                target="_blank"
              >
                See Docs
              </a>
            )}
          </>
        );
      },
    },
    {
      dataField: "reasonEarlyClockout",
      text: "Early Clockout reason",
      sort: true,
      formatter: (cell, row) => <p>{cell}</p>,
    },
    {
      dataField: "actions",
      isDummyField: true,
      text: "Actions",
      headerStyle: { width: "30em" },
      formatExtraData: approved,
      formatter: (cell, row, i, approved) => {
        return getActionsView(row, i);
      },
    },
  ];

  return (
    <div className="home">
      {fetchingData ? (
        <LazyLoadingMsg in={fetchingData}>
          We're loading your latest information...
        </LazyLoadingMsg>
      ) : (
        <></>
      )}
      <h1>Shifts with left early</h1>
      <PaymentReview />
      <br />
      <br />
      <br />
      {adminReviewWorkList && <h1>Shifts in admin review</h1>}
      {adminReviewWorkList && (
        <BootstrapTable
          striped
          bordered
          hover
          keyField="id"
          data={adminReviewWorkList}
          columns={getColumns()}
        />
      )}
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Review);
