import React, { Component } from 'react';
import styled from 'styled-components';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { defs } from './defs';
import { device } from "./device";
import {parseStringifiedObjects} from '../utils/util';

const animatedComponents = makeAnimated();

class PositionsFilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOptions: null,
      positionOptions: null,
      positionIdOptions: null,
    }
  }

  componentDidMount() {
    this.setFilterOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.positions !== this.props.positions) {
      this.setFilterOptions();
    }
  }

  setFilterOptions() {
    const { positions } = this.props;
    this.setState({
      companyOptions: this.formatCompanyOptionsForPositionsFilter(positions),
      positionOptions: this.formatPositionOptionsForPositionsFilter(positions),
      positionIdOptions: this.formatPositionIdOptionsForPositionsFilter(positions),
    })
  }

  render() {
    const {
      handleFilterChange,
      showBorderTop,
      showBorderBottom,
      showCompanyFilter,
      showPositionFilter,
      showPositionIdFilter,
    } = this.props;

    const {
      companyOptions,
      positionOptions,
      positionIdOptions,
    } = this.state;

    return (
      <FilterBarWrapper showBorderTop={showBorderTop} showBorderBottom={showBorderBottom}>
        <Instruction>Filter by:</Instruction>
        <SelectGroup>
          {showCompanyFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={companyOptions}
              components={animatedComponents}
              styles={customStyles}
              name="company"
              placeholder="Company"
              onChange={handleFilterChange}
            />
          )}
          {showCompanyFilter && showPositionFilter && <Instruction>&</Instruction>}
          {showPositionFilter && (
          <Select
            isMulti
            isSearchable
            isClearable
            options={positionOptions}
            components={animatedComponents}
            styles={customStyles}
            name="position"
            placeholder="Position Name"
            onChange={handleFilterChange}
          />
          )}
          {showPositionFilter && showPositionIdFilter && <Instruction>&</Instruction>}
          {showPositionIdFilter && (
            <Select
              isMulti
              isSearchable
              isClearable
              options={positionIdOptions}
              components={animatedComponents}
              styles={customStyles}
              name="positionId"
              placeholder="Position ID"
              onChange={handleFilterChange}
            />
          )}
        </SelectGroup>
      </FilterBarWrapper>
    );
  }

  formatCompanyOptionsForPositionsFilter = (positions) => {
    if (!positions) return null;
    let companies = new Set();
    for (const position of positions){
      if (position.company) {
        const formattedCompany =  { value: parseInt(position.company.id), label: position.company.name };
        if (companies.has(formattedCompany)) console.log('already in set!')
        companies.add(JSON.stringify(formattedCompany));
      }
    }
    const formattedSet = parseStringifiedObjects(companies)
    return Array.from(formattedSet);
  };

  formatPositionOptionsForPositionsFilter = (positions) => {
    if (!positions) return null;
    let positionNames = new Set();
    for (const position of positions){
      const formattedPositionName =  { value: position.name, label: position.name };
      if (positionNames.has(formattedPositionName)) console.log('already in set!')
      positionNames.add(JSON.stringify(formattedPositionName));
    }
    const formattedSet = parseStringifiedObjects(positionNames)
    return Array.from(formattedSet);
  };

  formatPositionIdOptionsForPositionsFilter = (positions) => {
    if (!positions) return null;
    let positionIds = new Set();
    for (const position of positions){
      const formattedPositionId =  { value: parseInt(position.id), label: position.id };
      if (positionIds.has(formattedPositionId)) console.log('already in set!')
      positionIds.add(JSON.stringify(formattedPositionId));
    }
    const formattedSet = parseStringifiedObjects(positionIds)
    return Array.from(formattedSet);
  };
}

export default PositionsFilterBar;

const FilterBarWrapper = styled.div`
  border-bottom: ${(props) => (props.showBorderBottom ? '1px solid' : null)};
  border-top: ${(props) => (props.showBorderTop ? '1px solid' : null)};
  border-color: ${defs.veryLightBlack};
  padding: ${defs.paddingS};
  padding-left: ${defs.paddingXL};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  @media ${device.smallerThanMobileL}{
    font-size: ${defs.fontS};
    height: 100%;
    padding-left: ${defs.paddingM}
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Instruction = styled.div`
  color: ${defs.mediumBlack};
  margin-right: ${defs.marginM};
  font-size: ${defs.fontS};
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
};
