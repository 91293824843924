import React from 'react';

import { withAuthorization } from '../Session';
import '../Home/home.css'
import AddListingBonus from "../features/add_listing_bonus";
import ListingsPending from "./listing_pending";
import ListingAdCreate from "./listing_ad_create";
import ListingAdSetStatus from "./listing_ad_set_status";
import ListingAds from "./listing_ads";

const ListingPage = () => (
  <div className="home">
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', maxWidth:'600px'}}>
      <ListingAdCreate />
    </div>
    <br/>
    <br/>
    <hr />
    <div className="flex-container-mobileL" style={{maxHeight: '500px'}}>
      <ListingAdSetStatus />
      <ListingAds />
    </div>
    <br/>
    <br/>
    <hr />
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', maxWidth:'500px'}}>
      <AddListingBonus />
    </div>
    <br/>
    <br/>
    <hr />
    <br/>
    <br/>
    <ListingsPending />
    <br/>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ListingPage);
