import React, { useState, useEffect } from "react";
import Filter, { animatedComponents, customStyles } from "../Shared/Filter";
import "../Home/home.css";
import { withAuthorization } from "../Session";
import LazyLoadingMsg from "../Shared/LazyLoadingMsg";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import graphql from "../../graphql";
import Select from "react-select";
import { MdSort } from "react-icons/md";
import Loading from "../Shared/loading";

const BusinessLeads = () => {
  const [loading, setLoading] = useState(false);
  const [market, setMarket] = useState("san_francisco");
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    fetchLeads();
  }, [market]);

  const fetchLeads = () => {
    setLoading(true);
    graphql(`
        admin { 
          businessLeads (
            market: "${market}", 
          ) {
            id, jobName, businessName, location, pay, link
          }
        }`).then((response) => {
      setLoading(false);
      const data = response.data.data.admin.businessLeads;
      setLeads(data);
    });
  };

  const marketSelect = (curr_market) => {
    return (
      <Select
        isSearchable
        options={[
          { value: "atlanta", label: "Atlanta" },
          { value: "balitmore", label: "Baltimore" },
          { value: "chicago", label: "Chicago" },
          { value: "dallas", label: "Dallas" },
          { value: "denver", label: "Denver" },
          { value: "houston", label: "Houston" },
          { value: "los_angeles", label: "Los Angeles" },
          { value: "miami", label: "Miami" },
          { value: "new_york", label: "New York" },
          { value: "newark", label: "Newark" },
          { value: "san_francisco", label: "SF Bay Area" },
          { value: "philadelphia", label: "Philadelphia" },
          { value: "phoenix", label: "Phoenix" },
          { value: "san_diego", label: "San Diego" },
          { value: "seattle", label: "Seattle" },
          { value: "washington_dc", label: "Washington DC" },
          { value: "york", label: "York/Lancaster" },
        ]}
        components={animatedComponents}
        styles={customStyles}
        name="market"
        placeholder={curr_market}
        onChange={(option) => {
          setMarket(option.value);
        }}
      />
    );
  };

  const getSortCaret = () => {
    return (
      <span style={{ paddingLeft: 10 }}>
        <MdSort />
      </span>
    );
  };

  const columns = [
    {
      dataField: "businessName",
      text: "Company Name",
      sort: true,
      sortCaret: (order, column) => {
        return getSortCaret();
      },
    },
    {
      dataField: "jobName",
      text: "Job Name",
      sort: true,
      sortCaret: (order, column) => {
        return getSortCaret();
      },
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
      sortCaret: (order, column) => {
        return getSortCaret();
      },
    },
    {
      dataField: "pay",
      text: "Pay",
      sort: true,
      sortCaret: (order, column) => {
        return getSortCaret();
      },
    },

    {
      dataField: "link",
      text: "Link",
      headerStyle: { width: "5em" },
      formatter: (cell, row) => {
        return (
          <a href={cell} target="_blank">
            Link
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Business Leads</h1>
      {loading ? (
        <Loading />
      ) : (
        <></>
      )}
      <Filter selects={[marketSelect(market)]}></Filter>
      {!loading && <div className="users-table mt-5">
        <BootstrapTable
          striped
          hover
          keyField="name"
          data={leads}
          columns={columns}
        />
      </div>}
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(BusinessLeads);
